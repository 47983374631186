import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import { FullScreenLoadingOverlay } from '../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const Messenger = lazy(() => import('./messenger'));

const MessengerAsync = ({ isLoggedIn }) => {
    if (!isLoggedIn) {
        return <FullScreenLoadingOverlay text="Loading Sonic" />;
    }

    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <Messenger />
        </Suspense>
    );
};

export default connect(({ auth: { user } }) => ({ isLoggedIn: !!user }))(MessengerAsync);

/* eslint-disable */

const firebaseBrowserConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
};

export const Sentry = (process.env.WEB) ? require('@sentry/react') : require('@sentry/react-native');

// export const getDeviceID = (process.env.WEB) ? require('react-native-device-info').getUniqueId() :

export default class logger {
    static error(e, ...data) {
        Sentry.captureException(e, ...data);
    }
}

export const firebase = (process.env.WEB) ? require('firebase/app').default : require('react-native-firebase').default;

const AsyncStorage = !process.env.WEB ? require('@react-native-community/async-storage').default : null;

export const storageLayer = (process.env.WEB) ? require('localforage') : {
    ...AsyncStorage,
    getItem: async (key) => {
        const data = await AsyncStorage.getItem(key);
        try {
            const { data: content } = JSON.parse(data) || {};
            return content;
        } catch (e) {
            console.log(e);
        }
    },
    setItem: async (key, value) => AsyncStorage.setItem(key, JSON.stringify({ data: value })),
};
export const mixpanel = (process.env.WEB) ? require('mixpanel-browser') : {
    track: () => ({}),
};

export const database = ((() => {
    if (process.env.WEB) {
        firebase.initializeApp(firebaseBrowserConfig);
        require("firebase/database");
        require("firebase/messaging");
        require("firebase/auth");
        return firebase.database();
    }

    return firebase.database();
})());

export const electron = () => process.env.ELECTRON ? require('./util/electron-importer').default() : {};

export const firebaseCurrentUser = () => (process.env.WEB) ? firebase.auth().currentUser : firebase.auth().currentUser._user;

// layer ontop of react-navigation
export const NavigationService = {
    _navigatorRef: null,
    setNavigator: function(_navigatorRef) {
        this._navigatorRef = _navigatorRef;
    },
    navigate: function(routeName, params) {
        const { NavigationActions } = require('react-navigation');
        this._navigatorRef.dispatch(
            NavigationActions.navigate({
                routeName,
                params,
            })
        );
    },
    goBack: function() {
        const { NavigationActions } = require('react-navigation');

        this._navigatorRef.dispatch(NavigationActions.back());
    },
};


export class router {
    static next(routeName, params = {}, push = false) {
        if (process.env.WEB) {
            const browserHistory = require('./app.history').default;

            const qs = window.location.search;

            /**
             * We want to always strip magic token details when they aren't on the /magic route
             */
            const cleanedQuery = (routeName.includes('/magic')) ? qs :
                qs.replace(/&?(email|uid|token)(=[^&]*)?|^(email|uid|token)(=[^&]*)?&?/g, '');

            return browserHistory.push(`${routeName}${cleanedQuery}`);
        }

        return NavigationService.navigate(routeName, params);
    }

    static prev() {
        if (process.env.WEB) {
            const browserHistory = require('./app.history').default;

            return browserHistory.goBack();
        }

        return NavigationService.goBack();
    }
}

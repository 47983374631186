import React, { Fragment } from 'react';
import Router from './router/router';
// import { Sentry } from './app.cross-platform'; now loaded elsewhere
import './sass/app.scss';
import { serverInfo } from './app.constants';
import { DragHeader } from './electron/drag-header/drag-header';

console.log('App.js');

serverInfo.getRoot();

export const App = () => (
    <Fragment>
        { process.env.ELECTRON && (<DragHeader />) }
        <Router />
    </Fragment>
);

import * as methods from './app.constants';

export * from './auth/auth.actions';
export * from './router/router.actions';
export * from './messenger/messenger.actions';
export * from './modals/modal.actions';

export const initSharedDataAndroid = newState => ({ type: methods.INIT_SHARED_DATA_ANDROID, newState });
export const newSharedDataAndroid = newState => ({ type: methods.NEW_SHARED_DATA_ANDROID, newState });
export const displaySharedDataAndroid = () => ({ type: methods.DISPLAY_SHARED_DATA_ANDROID });
export const clearSharedDataAndroid = () => ({ type: methods.CLEAR_SHARED_DATA_ANDROID });
export const setShowShareModal = newState => ({ type: methods.SHOW_SHARE_MODAL_ANDROID, newState });
export const setShowShareLoading = newState => ({ type: methods.SET_SHOW_SHARE_LOADING_ANDROID, newState });

import { storage, localStorage } from '../app.services';


export const updateAuthCache = ({ user }) => {
    if (user) {
        storage.set('user', user).catch(error => console.log('Failed to set cache', error));
        return 'Storage cache request sent';
    }
    return 'User provided is null';
};

export const loadAuthCache = async () => {
    // noinspection ES6ModulesDependencies
    const [user] = await Promise.all([
        storage.get('user'),
    ]);

    return { user };
};

export const flushCache = async () => {
    if (process.env.WEB) {
        localStorage.clear();
    }
    storage.removeAll();
};


export const markOnboardingComplete = async () => {
    const { user } = await loadAuthCache();
    user.meta = {
        ...user.meta,
        web: {
            onboarded: true,
        },
    };
    storage.set('user', user).catch(error => console.log('Failed to set cache', error));
};

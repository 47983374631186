import _ from 'underscore';
import cuid from 'cuid';
import moment from 'moment';
import * as methods from '../app.constants';
import { updateConversation, getConversationIdFromUrl, constructNewConversation } from './messenger.helpers';
import { router } from '../app.cross-platform';
import { updateCache } from './messenger.cache';

const { assign } = Object;

export const initialState = {
    /**
     * Messenger States
     */
    isNewUser: false,
    isMessengerInitializing: true,
    isFileUploading: false,
    /**
     * Conversations
     */
    currentConversation: {},
    currentConversationLoading: false,
    conversations: new Map(),

    /**
     * Events
     */
    events: new Map(),

    /**
     * User suggestions for react-native suggestions list
     * WARNING: Only used for react native
     */
    suggestions: [],
    nextUserToAdd: '',

    /**
     * User Reducer Content
     */
    contactList: [],

    /**
     * Right Sidebar
     */
    rightSidebarState: null,
    rightSidebarData: {},

    /**
     * Statuses
     */
    errorMessage: '',
    conversationsLoading: false,
    allConversationsLoaded: false,
    sendingMessage: false,

    /**
     * Lightbox
     */
    lightBoxState: null,

    /**
     * Search
     */
    searchResults: undefined,
    isSearching: false,
    searchError: '',
    isSearchModalOpen: false,
    searchType: 'global',
    newGroupConversationTitle: '',
    decryptedMessages: {},
};

export function messengerReducer(state = initialState, action) {
    switch (action.type) {
    case methods.CRITICAL_ERROR: {
        return {
            ...state,
            criticalError: action.newState,
        };
    }

    case methods.LOAD_FROM_CACHE: {
        if (!state.isMessengerInitializing) {
            return state;
        }

        const { currentConversation, conversations, contactList } = action.newState.messenger;

        if (!conversations) {
            return state;
        }

        return {
            ...state,
            currentConversation,
            conversations,
            contactList: contactList || [],
            isMessengerInitializing: false,
        };
    }

    case methods.SYNC_USER_AND_CONVERSATIONS: {
        const nextConversations = Array.from(state.conversations.entries()).map((conversation) => {
            const [id, conversationData] = conversation;
            const newConversationsInfo = action.newState.conversations;

            if (newConversationsInfo && newConversationsInfo[id]) {
                return [id, {
                    ...conversationData,
                    lastUpdatedAt: newConversationsInfo[id].lastUpdatedAt,
                }];
            }

            return conversation;
        });

        return {
            ...state,
            conversations: new Map(nextConversations),
        };
    }

    case methods.UPDATE_CONVERSATIONS: {
        let paramId;
        if (process.env.WEB) {
            const urlParamID = getConversationIdFromUrl();
            paramId = (urlParamID !== 'magic' && urlParamID !== 'default') ? urlParamID : undefined;
        }
        const currentConversationId = paramId || state.currentConversation.id;
        const conversations = new Map([...state.conversations, ...action.newState.conversations]);
        const currentConversation = conversations.get(currentConversationId) || conversations.values().next().value;
        if (process.env.WEB && !window.location.href.includes(currentConversation.id)) {
            router.next(`/u/${currentConversation.id}`);
        }
        updateCache({ conversations, currentConversation });

        return {
            ...state,
            conversations,
            allConversationsLoaded: conversations.size >= action.newState.allConversationsLength,
            conversationsLoading: false,
            isMessengerInitializing: false,
            currentConversation,
        };
    }

    case methods.DELETE_CONVERSATION: {
        const currentConversationId = state.currentConversation.id;
        const conversations = new Map(state.conversations);
        conversations.delete(action.newState.id);
        let currentConversation = conversations.get(currentConversationId) || conversations.values().next().value || {};

        if (process.env.WEB && currentConversation.id && !window.location.href.includes(currentConversation.id)) {
            router.next(`/u/${currentConversation.id}`);
        } else if (process.env.WEB && !currentConversation.id) {
            router.next('/u/default');
        } else if (!process.env.WEB) { // android
            router.next('Messenger');
            currentConversation = {};
        }
        updateCache({ conversations, currentConversation });
        return {
            ...state,
            conversations,
            allConversationsLoaded: conversations.size >= action.newState.allConversationsLength,
            conversationsLoading: false,
            isMessengerInitializing: false,
            currentConversation,
        };
    }

    case methods.APPEND_DECRYPTED_MESSAGE_OBJECT: {
        return { ...state, decryptedMessages: { ...state.decryptedMessages, [action.newState.id]: action.newState.content } };
    }

    case methods.UPDATE_EVENTS: {
        const events = new Map([...state.events, ...action.newState.events]);
        return {
            ...state,
            events,
        };
    }

    case methods.REMOVE_EVENT: {
        const events = new Map([...state.events]);
        events.delete(action.newState.id);
        return {
            ...state,
            events,
        };
    }

    /**
     * User suggestions for react-native suggestions list
     * WARNING: Only used for react native
     */
    case methods.UPDATE_SUGGESTIONS:
        return {
            ...state,
            suggestions: action.newState,
        };

    case methods.RESET_SUGGESTIONS:
        return {
            ...state,
            suggestions: [],
        };

    case methods.LOAD_CURRENT_CONVERSATION: {
        /**
         * If inherit is `true`, allow the current conversation loaded to inherit the state of the existing current
         * conversation, else use a prefetched conversation
         */
        const { inherit, removeConversationId, ...preFetchedConversation } = action.newState;

        const nextCurrentConversation = {
            paginationCount: 0,
            ...(inherit) ? state.currentConversation : preFetchedConversation,
            newConversation: false,
            id: preFetchedConversation.id,
            messagesLoading: true,
        };

        const nextConversations = new Map([...state.conversations, [preFetchedConversation.id, nextCurrentConversation]]);
        nextConversations.delete(removeConversationId);

        return {
            ...state,
            conversations: nextConversations,
            currentConversation: nextCurrentConversation,
        };
    }

    case methods.SET_LIGHTBOX_STATE: {
        if (!action.newState) {
            return {
                ...state,
                lightBoxState: null,
            };
        }

        const { content, isContent } = action.newState;

        if (isContent) {
            const photoIndex = _.findIndex(state.currentConversation.messages
                .sort(({ timestamp: a = Math.min() }, { timestamp: b = Math.min() }) => a - b)
                .filter(({ type }) => type === 'photo'), ({ content: message }) => message === content);

            return {
                ...state,
                lightBoxState: (photoIndex >= 0) ? photoIndex : null,
            };
        }

        return {
            ...state,
            lightBoxState: content,
        };
    }

    case methods.ALL_MESSAGES_LOADED:
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    allMessagesLoaded: true,
                    messagesLoading: false,
                };
            }),
        };
    case methods.DISABLE_LOADING:
        return {
            ...state,
            isMessengerInitializing: false,
        };
    case methods.ALL_CONVERSATIONS_LOADED:
        return {
            ...state,
            conversationsLoading: false,
            allConversationsLoaded: true,
        };

    case methods.UPDATE_CONTACT_LIST:
        return {
            ...state,
            contactList: action.newState,
        };

    case methods.SET_GROUP_INTRO_FLAG: {
        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                intro: action.newState,
            },
        };
    }

    case methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION: {
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                const nextParticipantList = [
                    ...conversation.participants,
                    {
                        ...action.newState.participant,
                        validatingUser: true,
                    },
                ];

                return {
                    ...conversation,
                    type: nextParticipantList.length > 1 ? 'group' : conversation.type,
                    participants: nextParticipantList,
                };
            }),
        };
    }

    case methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS: {
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    participants: conversation.participants.map((currentParticipant) => {
                        if (currentParticipant.id === action.newState.participant.tempId) {
                            return action.newState.participant;
                        }

                        return currentParticipant;
                    }),
                };
            }),
        };
    }

    case methods.REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION: {
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    participants: conversation.participants.filter(({ id }) => id !== action.newState.participant.id),
                };
            }),
        };
    }

    case methods.SEND_MESSAGE: {
        return {
            ...state,
            sendingMessage: true,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    lastUpdatedAt: action.newState.message.timestamp,
                    tags: nextState.tags || conversation.tags,
                    messages: [
                        ...state.currentConversation.messages,
                        {
                            temp: true,
                            ...action.newState.message,
                        },
                    ],
                };
            }),
        };
    }

    case methods.SEND_MESSAGE_SUCCESS:
        return assign({}, state, {
            sendingMessage: false,
        });

    case methods.SET_CURRENT_CONVERSATION: {
        // eslint-disable-next-line
        const { id, ...rest } = action.newState;

        const convoFromList = state.conversations.get(id);
        if (!convoFromList) {
            console.error('Conversation not available at', id);
            return state;
        }
        if (convoFromList && rest.newConversation) {
            rest.backlogParticipants = convoFromList.participants;
        }

        // We slice the messages so that it doesn't render too much at the very beginning. Even if we have the data locally showing it right away is a mistake because it renders too many messages slowing down the web page scrolling.
        const newConvo = {
            ...convoFromList, id, ...rest, messages: (convoFromList.messages ?? []).slice(convoFromList.messages.length - 22),
        };
        // newConvo.messages.splice(0, newConvo.messages.length - 22);

        return {
            ...state,
            currentConversation: {
                allMessagesLoaded: false,
                conversationIsLoading: action.newState.conversationIsLoading,
                paginationCount: 0,
                id: action.newState.id,
                ...newConvo,
            },
            isMessengerInitializing: false,
        };
    }

    case methods.SET_REPLY_MESSAGE:
        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                replyTo: action.newState,
            },
        };

    case methods.REMOVE_REPLY_MESSAGE:
        return {
            ...state,
            currentConversation: {
                ...state.currentConversation,
                replyTo: null,
            },
        };

    case methods.SET_USER_IS_NEW:
        return assign({}, state, {
            isMessengerInitializing: false,
            allConversationsLoaded: true,
            isNewUser: true,
        });

    case methods.CREATE_EMPTY_CONVERSATION: {
        const { currentConversation: { newConversation, id: currentConversationId }, newGroupConversationTitle } = state;
        const existingEmptyConversation = [...state.conversations.values()]
            .find(conversation => conversation.newConversation && conversation.participants.length === 0);


        const newConversationTransferData = newConversation && (existingEmptyConversation && existingEmptyConversation.id === currentConversationId)
            ? { id: state.currentConversation.id, ...action.newState }
            : { id: existingEmptyConversation ? existingEmptyConversation.id : null, ...action.newState };

        const id = newConversationTransferData.id || cuid();
        const { type = 'single', eventId, name: conversationName } = action.newState;

        const emptyConversationOverrides = Object.fromEntries(Object.entries({
            id,
            type,
            eventId,
            name: newConversationTransferData.name,

            participants: (participants =>
                (type === 'single' ? participants.slice(0, 2) : participants)
            )(newConversationTransferData.participants || []),
        }).filter(([a, b]) => b === false || b === '' || b === 0 || b));

        const emptyConversation = constructNewConversation(emptyConversationOverrides);

        const nextConversations = new Map([...state.conversations]);

        nextConversations.set(id, emptyConversation);

        return {
            ...state,
            conversations: nextConversations,
            currentConversation: emptyConversation,
            newGroupConversationTitle: (type === 'group' && conversationName) || newGroupConversationTitle,
        };
    }

    case methods.ANDROID_CREATE_EMPTY_CONVERSATION: {
        const { currentConversation: { newConversation, participants } } = state;
        const existingEmptyConversationList = [...state.conversations.values()]
            .filter(({ newConversation, participants: p }) => newConversation && !p.length);

        const newConversationTransferData = newConversation && !participants.length ?
            { id: state.currentConversation.id, ...action.newState } : (action.newState || {});

        const id = newConversationTransferData.id || cuid();
        const { type = 'single', eventId } = action.newState;

        const emptyConversation = {
            type,
            participants: ((participants) => {
                if (type === 'single') {
                    return participants.slice(0, 2);
                }

                return participants;
            })(newConversationTransferData.participants || []),
            messages: [],
            name: newConversationTransferData.name || '',
            id,
            eventId,
            lastUpdatedAt: moment().valueOf(),
            newConversation: true,
            paginationCount: 0,
        };

        console.log(emptyConversation);
        console.log(`list: ${existingEmptyConversationList}`);
        const nextConversations = new Map([...state.conversations]);
        nextConversations.set(id, emptyConversation);

        return {
            ...state,
            conversations: nextConversations,
            currentConversation: emptyConversation,
        };
    }

    case methods.CREATE_CONVERSATION:
        return assign({}, state, {
            ...updateConversation(state, { id: state.currentConversation.id }, (nextState, conversation) => {
                return {
                    ...conversation,
                    creating: true,
                    name: state.newGroupConversationTitle,
                    newConversation: false,
                };
            }),
        });

    case methods.CREATE_CONVERSATION_SUCCESS:
        return assign({}, { ...state, newGroupConversationTitle: '' }, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...state.currentConversation,
                    ...nextState.conversation[1],
                    messages: conversation.messages || {},
                    creating: false,
                };
            }),
        });

    case methods.CREATE_CONVERSATION_FAIL: {
        const nextConversations = new Map([...state.conversations]);
        nextConversations.delete(state.currentConversation.id);

        if (!process.env.WEB) {
            router.next('Messenger');
        }

        return assign({}, { ...state, newGroupConversationTitle: '' }, {
            currentConversation: {},
            conversations: nextConversations,
            sendingMessage: false,
        });
    }

    case methods.PAGINATE_MESSAGES:
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    messagesLoading: true,
                    highlightedMessageId: nextState ? nextState.highlightMessage : null,
                };
            }),
        };

    case methods.PAGINATE_MESSAGES_FAIL:
        return {
            ...state,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    messagesLoading: false,
                };
            }),
        };

    case methods.PAGINATE_MESSAGES_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    paginationCount: state.currentConversation.paginationCount + 1,
                    messagesLoading: false,
                    messages: _.uniq([...nextState.messages, ...conversation.messages], false, ({ id }) => id)
                        .sort(({ timestamp: a = Math.min() }, { timestamp: b = Math.min() }) => a - b),
                };
            }),
        });

    case methods.PAGINATE_CONVERSATIONS:
        return {
            ...state,
            conversationsLoading: true,
        };

    case methods.PAGINATE_CONVERSATIONS_SUCCESS:
        return {
            ...state,
            conversationsLoading: false,
        };

    case methods.UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                let newParticipants = [];

                if (nextState.type === 'add') {
                    newParticipants = _.uniq([
                        ...conversation.participants,
                        ...nextState.participants,
                    ], false, v => v.id || v.name || v.email || v.phone);
                } else {
                    newParticipants = nextState.participants;
                }

                return {
                    ...conversation,
                    participants: newParticipants,
                };
            }),
        });

    case methods.REMOVE_PARTICIPANT_FROM_GROUP_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    // eslint-disable-next-line
                    participants: conversation.participants?.filter(({ id }) => id !== action.newState.participantId),
                };
            }),
        });
    case methods.UPLOAD_CURRENT_CONVERSATION_PHOTO:
        return {
            ...state,
            isFileUploading: true,
        };
    case methods.UPDATE_CURRENT_CONVERSATION_PHOTO:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    groupImageURL: nextState.groupImageURL,
                };
            }),
        });

    case methods.UPDATE_CURRENT_CONVERSATION_PHOTO_SUCCESS:
        return assign({}, state, {
            isFileUploading: false,
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    groupImageURL: nextState.groupImageURL,
                };
            }),
        });

    case methods.UPDATE_CURRENT_CONVERSATION_TITLE_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    name: nextState.title,
                };
            }),
        });

    case methods.UPDATE_PROFILE_PICTURE:
        return {
            ...state,
            isFileUploading: false,
        };

    case methods.LEAVE_EMPTY_CONVERSATION:
    case methods.LEAVE_CONVERSATION: {
        const nextConversations = new Map([...state.conversations]);
        const { id: currentConversationId, persistCurrentConversation } = action.newState;

        nextConversations.delete(currentConversationId);

        return {
            ...state,
            newGroupConversationTitle: '',
            conversations: nextConversations,
            currentConversation: (() => {
                if (
                    persistCurrentConversation || (
                        action.type !== methods.LEAVE_CONVERSATION
                        && state.currentConversation
                        && !state.currentConversation.newConversation
                    )
                ) {
                    return state.currentConversation;
                }

                if (!process.env.WEB) {
                    router.next('Messenger');
                    return {};
                }

                if (nextConversations.size === 0) {
                    return {};
                }

                return nextConversations.values().next().value || {};
            })(),
        };
    }

    case methods.SET_OVERRIDDEN_CONVERSATION:
        return {
            ...state,
            overriddenConversation: action.newState.overriddenConversation,
        };

    case methods.LOAD_OVERRIDDEN_CONVERSATION: {
        const nextConversations = new Map(state.conversations);
        const {
            overriddenConversation,
            ...newState
        } = state;
        if (overriddenConversation) {
            nextConversations.set(overriddenConversation.id, overriddenConversation);
        }
        return {
            ...newState,
            conversations: nextConversations,
        };
    }

    case methods.MUTE_CONVERSATION_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    mute: true,
                };
            }),
        });

    case methods.UNMUTE_CONVERSATION_SUCCESS:
        return assign({}, state, {
            ...updateConversation(state, action.newState, (nextState, conversation) => {
                return {
                    ...conversation,
                    mute: false,
                };
            }),
        });

    case methods.UPDATE_NEXT_USER_TO_ADD_VALUE:
        return assign({}, state, {
            nextUserToAdd: action.newState,
        });

    case methods.SET_CURRENT_CONVERSATION_AS_READ_SUCCESS:
        return {
            ...state,
            ...updateConversation(state, action.newState, ({ messages }, conversation) => {
                const messageMap = _.object(messages.map(({ id }) => id), messages);
                return {
                    ...conversation,
                    messages: conversation.messages.map((content) => {
                        if (messageMap[content.id]) {
                            return messageMap[content.id];
                        }

                        return content;
                    }),
                };
            }),
        };

    case methods.SET_ERROR_MESSAGE:
        return assign({}, state, {
            errorMessage: action.newState,
        });

    /**
     * Sidebar
     */
    case methods.ENABLE_RIGHT_SIDEBAR:
        return assign({}, state, {
            rightSidebarState: action.style,
            rightSidebarData: action.data,
        });

    case methods.DISABLE_RIGHT_SIDEBAR:
        return assign({}, state, {
            rightSidebarState: null,
            rightSidebarData: {},
        });

    /**
     * Search
     */
    case methods.OPEN_SEARCH_MODAL:
        return {
            ...state,
            isSearchModalOpen: true,
            searchType: action.mode,
        };

    case methods.SET_SEARCH_MODE:
        return {
            ...state,
            searchType: action.mode,
        };

    case methods.CLOSE_SEARCH_MODAL:
        return {
            ...state,
            isSearchModalOpen: false,
        };

    case methods.SEARCH_CONVERSATIONS:
        return {
            ...state,
            isSearching: true,
            searchError: '',
            searchType: action.newState.searchType || state.searchType,
        };

    case methods.SEARCH_CONVERSATIONS_SUCCESS:
        return {
            ...state,
            isSearching: false,
            searchResults: action.newState,
        };

    case methods.SEARCH_CONVERSATIONS_FAIL:
        return {
            ...state,
            isSearching: false,
            searchError: action.newState,
        };

    case methods.CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            searchResults: undefined,
        };
    case methods.SET_NEW_GROUP_CONVERSATION_TITLE:
        return {
            ...state,
            newGroupConversationTitle: action.newState,
        };
    case methods.LOGOUT_USER_SUCCESS:
        return initialState;

    case methods.SET_CURRENT_CONVERSATION_LOADING:
        return {
            ...state,
            currentConversationLoading: action.payload,
        };

    default:
        return state;
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { CTA } from '../cta/cta';
import { ContentBlob } from '../content-blob/content-blob';
import { SignIn } from '../../auth/sign-in/sign-in.async';
import { getCurrentURLQueryString } from '../../app.helpers';
import * as styles from './home.scss';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../../app.constants';
import * as actions from '../../app.actions';

export class HomeComponent extends Component {
    handleGoogleLogin = (googleResponse) => {
        if (!(googleResponse && googleResponse.code)) console.error(new Error('Missing google code!'));
        this.handleLogin('google', { code: googleResponse.code });
    }

    handleLogin(type, data = {}) {
        this.props.dispatch(actions.LoginUserAction({ type, data }));
    }

    render() {
        const {
            auth: { isSendingEmail, isEmailSent, emailSentStatus },
            isMobile,
        } = this.props;

        const hideSignInInput = !isSendingEmail && !isEmailSent;
        const isDev = (process.env.ENV === 'DEV' && !!getCurrentURLQueryString().showprod) || process.env.FORCE_DEV;
        const isWaitlisted = emailSentStatus && emailSentStatus.status === 'waitlisted';

        return (
            <div className={styles.container}>
                <div className={`slide ${isMobile ? styles.landing_mobile : styles.landing}`}>
                    <div className="centerObject">
                        <div className={isMobile ? styles.cta_mobile : styles.cta}>
                            <CTA />
                            {isMobile ? (
                                <React.Fragment>
                                    {(/iPhone/ig.test(navigator.userAgent) || /iPad/ig.test(navigator.userAgent)) && (
                                        <a href={APP_STORE_LINK}>
                                            <img
                                                src={`${process.env.BASENAME}/static/download-on-apple-store.svg`}
                                                alt="Download on app store badge"
                                            />
                                        </a>
                                    )}
                                    {/Android/ig.test(navigator.userAgent) && (
                                        <a href={PLAY_STORE_LINK}>
                                            <img
                                                width="130"
                                                height="50"
                                                src={`${process.env.BASENAME}/static/google-play-badge.png`}
                                                alt="Download on google play badge"
                                            />
                                        </a>
                                    )}
                                </React.Fragment>
                            ) : (
                                <SignIn minimal />
                            )}
                            <div className={`useTextGrey ${styles.cta_subcontent}`}>
                                { isDev && (
                                    <GoogleLogin
                                        clientId={process.env.GOOGLE_LOGIN_CLIENT_ID}
                                        accessType="offline"
                                        prompt="consent"
                                        responseType="code"
                                        scope="openid profile email"
                                        className={`${styles.connect_to_google} button white`}
                                        onSuccess={this.handleGoogleLogin}
                                        onFailure={() => {}}
                                        render={renderProps => (
                                            <div
                                                onClick={renderProps.onClick}
                                                onKeyDown={() => {}}
                                                role="button"
                                                className={`${hideSignInInput ? styles.cta_google_signin : 'hidden'}`}
                                            >
                                                Click here to sign in with Google.
                                            </div>
                                        )}
                                    />
                                )}
                                <span
                                    className={`${isSendingEmail ? `${styles.cta_subcontent_sending} makeAppear` : 'hidden'}`}
                                >
                                    { !isWaitlisted && 'We are sending you an email that contains a link for you to sign in.' }
                                </span>
                                <span
                                    className={`${isEmailSent ? `${styles.cta_subcontent_sent} makeAppear` : 'hidden'}`}
                                >
                                    { !isWaitlisted ? 'Check your inbox for the sign-in email! If needed, check your spam folder.' : 'You\'ll hear from us soon!' }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`slide ${styles.btf} hidden`}>
                    <div className="centerObject">
                        <div>
                            <h2 className={`${styles.btf_title} usePrimaryColorText`}>Your first anti-social media messenger</h2>
                            <p>Sonic is an audio first messenger:<br />
                                - Listen to audio messages at 1.5x, 2x, & 3x speed to save time<br />
                                - Have audio messages and voice notes transcribed so you can listen or read them to save time & read while in meetings<br />
                                - Search through audio messages<br />
                                - Use your voice and tone to convey your meaning so it is not lost in translation
                            </p>
                        </div>
                        <div className={styles.blobs}>
                            <ContentBlob
                                icon="usePrimaryColorText far fa-envelope-open"
                                title="Message anyone with an email"
                            >
                                Sonic messenger is integrated with email, allowing you to message anyone on your contacts list and have
                                them reply to you back on email or in app
                            </ContentBlob>
                            <ContentBlob
                                icon="usePrimaryColorText far fa-eye"
                                title="Get the know on your peers"
                            >
                                We connect with LinkedIn and various data sources to give you a brief of everyone you talk to so you’re
                                prepared for anything.
                            </ContentBlob>
                            <ContentBlob
                                icon="usePrimaryColorText far fa-lock"
                                title="Encrypted End (to End)"
                            >
                                Sonic App’s first priority is your security, all of our desktop and phone apps are secured end to end
                                and our web app is secured with AES-256 encryption.
                            </ContentBlob>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


// Which props do we want to inject, given the global state?
function select(state) {
    return {
        auth: state.auth,
    };
}

// Wrap the component to inject dispatch and state into it
export const Home = connect(select)(HomeComponent);

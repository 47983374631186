import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CRITICAL_ERROR } from '../messenger/messenger.constants';
import * as styles from './error-handler.scss';
import { Sentry } from '../app.cross-platform';

class ErrorHandlerComponent extends Component {
    state = {
        loading: false,
    }

    // static getDerivedStateFromError = (error) => {}

    componentDidCatch(err) {
        this.props.dispatch({ type: CRITICAL_ERROR, newState: err });
        Sentry.captureException(err);
    }

    clearPageCache = async () => {
        this.setState({ loading: true });
        localStorage.clear();
        // this.props.dispatch({ type: methods.LOGOUT_USER });
        document.cookie = '';
        const dbs = await window.indexedDB.databases();
        await dbs.forEach(db => window.indexedDB.deleteDatabase(db.name));
        window.location.reload();
    }

    render() {
        const { criticalError } = this.props;

        if (criticalError) {
            return (
                <div
                    style={{ backgroundImage: `url(${`${process.env.BASENAME || ''}/static/error-page-background.png`})`, backgroundSize: 'cover' }}
                    className={styles.container}
                >
                    <div className={styles.content}>
                        <img
                            src={`${`${process.env.BASENAME || ''}/static/error-page-illustration.svg`}`}
                            alt="error-logo"
                        />
                        <h2>Oops! Looks like there&apos;s an error...</h2>
                        <div className={styles.buttons_container}>
                            <button
                                onClick={this.clearPageCache}
                                className={styles.back_to_home}
                            >
                                Back to home
                            </button>
                            <a
                                href="mailto:team@sonicapp.com"
                                className={styles.contact_us}
                            >
                                Contact us
                            </a>
                        </div>
                        {this.state.loading && <div className={styles.spinner} />}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default connect(({ messenger: { criticalError } }) => ({ criticalError }))(ErrorHandlerComponent);

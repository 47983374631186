import { storage } from '../app.services';

const CACHE_LIMIT = (process.env.WEB) ? 12 : 20; // TODO: Limiting the number of conversations in the cache to only 12 is a temporary work around to improve performance. Really, we should be FINE leaving a few hundred MB worth in cache (indexedDB has a 500 MB limit but we also need space for other caches like Firebase's use of indexedDB). Unfortunately, the web app currently renders all conversations from the cache, so if the cache is gigantic it kills the performance because it renders everything. We need to redo how conversation pagination works: The way it SHOULD work is that we render only up to 10 conversations originally regardless of how much is in the cache and then intelligently load new conversations during scroll directly from the cache if they are available there (inside of from firebase). For now, since it doesn't work this way, it's better off to limit what's in the cache in order to limit the amount of rendering that is done upon the initial load as temporary fix to poor loading performance.

export const loadCache = async () => {
    const [conversations = null, currentConversation = {}, contactList = []] = await Promise.all([
        storage.get('conversations'),
        storage.get('currentConversation'),
        storage.get('contactList'),
    ]);

    let parsedConversations = null;

    if (conversations && !(conversations instanceof Map) && (conversations instanceof Array)) {
        const sortedConversations = conversations.sort((x, y) => {
            const { lastUpdatedAt: a = 0 } = x[1];
            const { lastUpdatedAt: b = 0 } = y[1];
            return b - a;
        });
        parsedConversations = new Map(sortedConversations.slice(0, CACHE_LIMIT));
    }

    return { conversations: parsedConversations, currentConversation, contactList };
};

export const updateCache = async ({ conversations, currentConversation, contactList }) => {
    if (conversations) {
        // Sorting may not actually be necessary here
        const conversationsArray = Array.from(conversations)
            .sort((x, y) => {
                const { lastUpdatedAt: a = 0 } = x[1];
                const { lastUpdatedAt: b = 0 } = y[1];
                return b - a;
            });
        // const { conversations: cachedConversations } = await loadCache();
        // if (cachedConversations && cachedConversations.size) {
        //     // if there are any existing conversations in cache this will drop that numbers of conversations before updating cache.
        //     const n = cachedConversations.size - conversationsArray;
        //     storage.set('conversations', conversationsArray.slice(n));
        // } else {

        storage.set('conversations', conversationsArray.slice(0, CACHE_LIMIT));
    }

    if (currentConversation) {
        storage.set('currentConversation', currentConversation);
    }

    if (contactList) {
        storage.set('contactList', currentConversation);
    }
};


import * as methods from '../app.constants';

export const UpdateSuggestionsAction = newState => ({
    type: methods.UPDATE_SUGGESTIONS,
    newState,
});

export const DisableLoadingAction = newState => ({
    type: methods.DISABLE_LOADING,
});
export const ResetSuggestionsAction = newState => ({
    type: methods.RESET_SUGGESTIONS,
});

export function StartConversationsListenerSuccessAction(newState) {
    return { type: methods.START_CONVERSATIONS_LISTENER_SUCCESS, newState };
}

export function StartConversationsListenerAction(newState) {
    return { type: methods.START_CONVERSATIONS_LISTENER, newState };
}

export function SetCurrentConversationAction(newState) {
    return { type: methods.SET_CURRENT_CONVERSATION, newState };
}

export function SetOverriddenConversationAction(newState) {
    return { type: methods.SET_OVERRIDDEN_CONVERSATION, newState };
}

export function LoadOverriddenConversationAction(newState) {
    return { type: methods.LOAD_OVERRIDDEN_CONVERSATION, newState };
}

export function PaginateConversationsAction(newState) {
    return { type: methods.PAGINATE_CONVERSATIONS, newState };
}

export function RemoveEventAction(newState) {
    return { type: methods.REMOVE_EVENT, newState };
}

export function PaginateEventsAction(newState) {
    return { type: methods.PAGINATE_EVENTS, newState };
}

export function StartEmptyConversationAction(newState) {
    return { type: methods.START_EMPTY_CONVERSATION, newState };
}

export function CreateEmptyConversationAction(newState) {
    const type = process.env.WEB ? methods.CREATE_EMPTY_CONVERSATION
        : methods.ANDROID_CREATE_EMPTY_CONVERSATION;

    return { type, newState };
}

export function UpdateContactListAction(newState) {
    return { type: methods.UPDATE_CONTACT_LIST, newState };
}

export function UpdateCurrentConversationTitleAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_TITLE, newState };
}

export function UpdateCurrentConversationTitleSuccessAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_TITLE_SUCCESS, newState };
}

export function UpdateCurrentConversationPhotoAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_PHOTO, newState };
}

export function UpdateCurrentConversationPhotoSuccessAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_PHOTO_SUCCESS, newState };
}

export function MuteConversationAction() {
    return { type: methods.MUTE_CONVERSATION };
}

export function UnmuteConversationAction() {
    return { type: methods.UNMUTE_CONVERSATION };
}

export function MuteConversationSuccessAction(newState) {
    return { type: methods.MUTE_CONVERSATION_SUCCESS, newState };
}

export function UnmuteConversationSuccessAction(newState) {
    return { type: methods.UNMUTE_CONVERSATION_SUCCESS, newState };
}

export function OpenUserProfileAction(newState) {
    return { type: methods.OPEN_USER_PROFILE, newState };
}

export function ManageParticipantsAction() {
    return { type: methods.MANAGE_PARTICIPANTS };
}

export function EnableSidebarAction(style, data = {}) {
    return { type: methods.ENABLE_RIGHT_SIDEBAR, style, data };
}

export function DisableSidebarAction() {
    return { type: methods.DISABLE_RIGHT_SIDEBAR };
}

export function StartSingleConversationWithIdAction(newState) {
    return { type: methods.START_SINGLE_CONVERSATION_WITH_ID, newState };
}

export function SetCurrentConversationAsReadAction(newState) {
    return { type: methods.SET_CURRENT_CONVERSATION_AS_READ, newState };
}

export function SetCurrentConversationAsReadSuccessAction(newState) {
    return { type: methods.SET_CURRENT_CONVERSATION_AS_READ_SUCCESS, newState };
}

export function SendMessageAction(newState) {
    return { type: methods.SEND_MESSAGE, newState };
}

export function UpdateCurrentConversationTagsAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_TAGS, newState };
}

export function UpdateCurrentConversationTagsSuccessAction() {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_TAGS_SUCCESS };
}

export function SendMessageSuccessAction(newState) {
    return { type: methods.SEND_MESSAGE_SUCCESS, newState };
}

export function SetErrorMessageAction(newState) {
    return { type: methods.SET_ERROR_MESSAGE, newState };
}

export function RequestNotificationPermissionAction() {
    return { type: methods.REQUEST_NOTIFICATION_PERMISSION };
}

export function UpdateStorageSuccessAction() {
    return { type: methods.UPDATE_STORAGE_SUCCESS };
}
export function SilentErrorAction(newState = {}) {
    return { type: methods.SILENT_ERROR, newState };
}

export function PaginateMessagesAction(newState) {
    return { type: methods.PAGINATE_MESSAGES, newState };
}

export function PaginateMessagesSuccessAction(newState) {
    return { type: methods.PAGINATE_MESSAGES_SUCCESS, newState };
}

export function PaginateMessagesFailAction(newState) {
    return { type: methods.PAGINATE_MESSAGES_FAIL, newState };
}

export function CreateConversationAction(newState) {
    return { type: methods.CREATE_CONVERSATION, newState };
}

export function CreateConversationSuccessAction(newState) {
    return { type: methods.CREATE_CONVERSATION_SUCCESS, newState };
}

export function CreateConversationFailAction() {
    return { type: methods.CREATE_CONVERSATION_FAIL };
}

export function SetLightBoxStateAction(newState) {
    return { type: methods.SET_LIGHTBOX_STATE, newState };
}

export function AllMessagesLoadedAction(newState) {
    return { type: methods.ALL_MESSAGES_LOADED, newState };
}

export function StartNewUserAction() {
    return { type: methods.SET_USER_IS_NEW };
}

export function SetGroupIntroFlagAction(newState) {
    return { type: methods.SET_GROUP_INTRO_FLAG, newState };
}

export function AddParticipantToCurrentConversationAction(newState) {
    return { type: methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION, newState };
}

export function AddParticipantToCurrentConversationSuccessAction(newState) {
    return { type: methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS, newState };
}

export function RemoveParticipantFromCurrentConversationAction(newState) {
    return { type: methods.REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION, newState };
}

export function UpdateCurrentConversationParticipantsAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_PARTICIPANTS, newState };
}

export function UpdateCurrentConversationParticipantsSuccessAction(newState) {
    return { type: methods.UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS, newState };
}

export function UpdateConversationStorageAction() {
    return { type: methods.UPDATE_CONVERSATION_STORAGE };
}

export function AddTagToMessageAction(newState) {
    return { type: methods.ADD_TAG_TO_MESSAGE, newState };
}

export function AddTagToMessageSuccessAction() {
    return { type: methods.ADD_TAG_TO_MESSAGE_SUCCESS };
}

export function AddTagToMessageUISuccessAction(newState) {
    return { type: methods.ADD_TAG_TO_MESSAGE_UI_SUCCESS, newState };
}

export function AppendDecryptedMessageObjectAction(newState) {
    return { type: methods.APPEND_DECRYPTED_MESSAGE_OBJECT, newState };
}

export function LoadCurrentConversationAction(newState) {
    return { type: methods.LOAD_CURRENT_CONVERSATION, newState };
}

export function StopComposingConversation(newState) {
    return { type: methods.STOP_COMPOSING_CONVERSATION, newState };
}

export const SetCurrentConversationLoading = (payload = false) => ({
    type: methods.SET_CURRENT_CONVERSATION_LOADING,
    payload,
});

// TODO: Remove this once it is no longer needed. It is currently used to send dummy actions since
//       epics require an action to be returned.
export function DummyAction() {
    return { type: 'DUMMY_TODO_REMOVE' };
}


export const UpdateConversationsAction = newState => ({ type: methods.UPDATE_CONVERSATIONS, newState });

export const DeleteConverationAction = newState => ({ type: methods.DELETE_CONVERSATION, newState });

export const UpdateEventsAction = newState => ({ type: methods.UPDATE_EVENTS, newState });

export const OpenAddTagToCurrentMessageModalAction = newState => ({ type: methods.OPEN_ADD_TAG_TO_CURRENT_MESSAGE_MODAL, newState });

export const CloseAddTagToCurrentMessageModalAction = () => ({ type: methods.CLOSE_ADD_TAG_TO_CURRENT_MESSAGE_MODAL });

export const StartSingleConversationWithContactDataAction = newState => ({ type: methods.START_SINGLE_CONVERSATION_WITH_CONTACT_DATA, newState });

export const StartConversationWithEventDataAction = newState => ({ type: methods.START_CONVERSATION_WITH_EVENT_DATA, newState });


/*
 * Search
 */

export const SearchConversationsAction = newState => ({ type: methods.SEARCH_CONVERSATIONS, newState });

export const SearchConversationsSuccessAction = newState => ({ type: methods.SEARCH_CONVERSATIONS_SUCCESS, newState });

export const SearchConversationsFailAction = newState => ({ type: methods.SEARCH_CONVERSATIONS_FAIL, newState });

export const ClearSearchResultsAction = () => ({ type: methods.CLEAR_SEARCH_RESULTS });

export const OpenSearchModalAction = (mode = 'global') => ({ type: methods.OPEN_SEARCH_MODAL, mode });

export const CloseSearchModalAction = () => ({ type: methods.CLOSE_SEARCH_MODAL });

export const SetSearchModeAction = (mode = 'global') => ({ type: methods.SET_SEARCH_MODE, mode });

/**
 * Sending Messages
 */

export const ParseMessageAction = newState => ({
    type: methods.PARSE_MESSAGE,
    newState,
});

export const AddReactionAction = newState => ({ type: methods.ADD_REACTION, newState });

export const AddReactionSuccessAction = newState => ({ type: methods.ADD_REACTION_SUCCESS, newState });

export const RemoveReactionAction = newState => ({ type: methods.REMOVE_REACTION, newState });

export const RemoveReactionSuccessAction = newState => ({ type: methods.REMOVE_REACTION_SUCCESS, newState });

export const setNewGroupConversationTitle = newState => ({
    type: methods.SET_NEW_GROUP_CONVERSATION_TITLE, newState,
});

export const uploadCurrentConversationPhoto = () => ({
    type: methods.UPLOAD_CURRENT_CONVERSATION_PHOTO,
});

export const SetReplyMessage = newState => ({
    type: methods.SET_REPLY_MESSAGE,
    newState,
});

export const RemoveReplyMessage = () => ({
    type: methods.REMOVE_REPLY_MESSAGE,
});

import * as methods from '../app.constants';

export function InitializeAppAction() {
    return { type: methods.INITIALIZE_APP };
}

export function UpdateRouterLocationAction(newState) {
    return { type: methods.UPDATE_ROUTER_LOCATION, newState };
}

export function UpdateRouterLocationSuccessAction() {
    return { type: methods.UPDATE_ROUTER_LOCATION_SUCCESS };
}

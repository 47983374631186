import React from 'react';
import * as styles from './full-screen-loading-overlay.scss';

export const FullScreenLoadingOverlay = ({ text }) => (
    <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.spinner} />
            <div>{text}</div>
        </div>
    </div>
);

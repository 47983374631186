import { Observable } from 'rxjs';
import { storageLayer } from './app.cross-platform';

export class localStorage {
    static set(key, item) {
        return window.localStorage.setItem(key, item);
    }

    static remove(key) {
        return window.localStorage.removeItem(key);
    }

    static clear() {
        return window.localStorage.clear();
    }

    static get(key) {
        return window.localStorage.getItem(key);
    }
}

// async heavy storage
export class storage {
    static async set(key, value) {
        return storageLayer.setItem(key, value);
    }

    static async get(key) {
        return storageLayer.getItem(key);
    }

    static async removeAll() {
        return storageLayer.clear();
    }

    static async remove(keys) {
        if (keys instanceof Array) {
            return Promise.all(keys.map(async key => storageLayer.removeItem(key)));
        }

        if (typeof keys === 'string') {
            return storageLayer.removeItem(keys);
        }

        return Promise.resolve();
    }

    static removeItemsFromObservable(keys) {
        return Observable.defer(async () => storage.remove(keys));
    }

    static removeAllFromObservable() {
        return Observable.defer(async () => storage.removeAll());
    }

    static setFromObservable(key, value) {
        return Observable.defer(async () => storage
            .set(key, value));
    }

    static getFromObservable(key, isObject) {
        return Observable.defer(async () => storage
            .get(key, isObject));
    }
}

export * from './messenger/messenger.services';

import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const SignInAsync = lazy(() => import('./sign-in.js').then(module => ({ default: module.SignIn })));

export const SignIn = (props) => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <SignInAsync {...props} />
        </Suspense>
    );
};


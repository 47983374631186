import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const LoginAsync = lazy(() => import('./login').then(module => ({ default: module.Login })));

export const Login = () => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <LoginAsync />
        </Suspense>
    );
};


import { Observable } from 'rxjs/Rx';
import axios from 'axios';
import { generateAuthTokens } from '../app.helpers';
import { firebase, Sentry } from '../app.cross-platform';
import { serverInfo } from '../app.constants';

export const getNotificationStatus = () => {
    return Observable.fromPromise(firebase.messaging().getToken());
};

export const requestPermission = () => {
    return Observable.fromPromise(firebase.messaging().requestPermission());
};

export const sendSignInTextMessage = async (tel, email, appStoreLink, playStoreLink) => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/sendText`,
            data: {
                email,
                tel,
                appStoreLink,
                playStoreLink,
                ...await generateAuthTokens(),
            },
        });
    } catch (error) {
        console.log(error);
        return true;
    }
};

export const search = async (conversations, query, from, context) => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/search`,
            data: {
                query,
                conversations,
                from,
                context,
                size: 20,
                ...await generateAuthTokens(),
            },
        });
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

let searchCancelSource = null;
export const searchConversations = async (query) => {
    if (searchCancelSource) {
        searchCancelSource.cancel();
    }

    searchCancelSource = axios.CancelToken.source();
    const request = {
        method: 'post',
        url: `${await serverInfo.getRoot()}/searchConversations`,
        data: {
            query,
            ...await generateAuthTokens(),
        },
        timeout: 1000,
        cancelToken: searchCancelSource.token,
    };
    const defaultResponse = {
        data: {
            results: {},
        },
        status: 200,
        statusText: 'OK',
        request: {},
    };
    const handledPromise = new Promise((resolve, reject) => {
        axios(request).then((response) => {
            resolve(response);
        })
            .catch((error) => {
                resolve(defaultResponse);
                if (!axios.isCancel(error)) {
                    Sentry.captureException(error);
                }
            });
    });

    return handledPromise;
};

export const getOneTimeLoginKey = async () => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/generateOneTimeLoginKey`,
            data: await generateAuthTokens(),
        });
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

export const leaveConversation = async (uid, cid) => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/leaveConversation`,
            data: {
                cid,
                uid,
                ...await generateAuthTokens(),
            },
        });
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

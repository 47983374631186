import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Promise } from 'bluebird';
import { App } from './app';
import { version } from '../package.json';
import './sass/app.scss';

const isLocal = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');
const sentryEnv = isLocal ? 'localhost' : (process.env.SENTRY_ENV || 'localhost');
console.log(`Initialized ${sentryEnv} Sentry error reporting`);
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: sentryEnv,
    release: version,
    debug: false,
    beforeSend(event, hint) {
        const error = hint.originalException;
        // eslint-disable-next-line
        console.error('Sentry', error);
        return event;
    },
    integrations(integrations) {
        return integrations.filter(integration => integration.name !== 'Breadcrumbs');
    },
});


// remove bluebird warnings
// TODO: Enable promise warnings when rxjs is upgraded
Promise.config({
    warnings: false,
});

// window.addEventListener('unhandledrejection', (event) => {
//     if (event.reason) {
//         Sentry.captureException(event.reason);
//         // console.error(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
//         event.preventDefault();
//     }
// });
window.onerror = (msg, url, lineNo, columnNo, error) => {
    Sentry.captureException(error);
    return true; // avoids printing error, because sentry prints it already
};
// }


const render = () => {
    ReactDOM.render(
        <App />,
        document.getElementById('root'),
    );
};

render();

if (module.hot) {
    module.hot.accept('./app', () => {
        render();
    });
}

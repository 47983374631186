import cuid from 'cuid';
import { Observable } from 'rxjs';
import { concatMap, ignoreElements } from 'rxjs/operators';
import { version } from '../../package.json';
import { firebase, database, firebaseCurrentUser, router, Sentry } from '../app.cross-platform';
import {
    sendMagicTokenEmail,
    sendMagicTokenEmailV2,
    pushContactsToken,
    sendMagicTokenText,
    sendMagicTokenTextV2,
    verifyOneTimeLoginKey,
    loginWithPassword,
    loginWithType,
    loginWithTypeV2,
} from './auth.services';
import { LOGIN_FAILED_URL } from '../app.constants';
import { localStorage } from '../app.services';

const rootRef = database.ref();
const usersRef = rootRef.child('users');
const usersPrivateRef = rootRef.child('usersPrivate');

// eslint-disable-next-line
export const currentUser = () => firebase.auth().currentUser || {};

let serverURI = ''; // leave this empty
export const getServerURI = async () => {
    // This method fetches the server config url from firebase
    // Otherwise the default is stored in the .env file or is just api.dev
    if (!serverURI || serverURI === '') {
        serverURI = (await database.ref('/config/server_url').once('value')).val();
    }

    if (!serverURI) {
        alert('There has been an error with this instance of Sonic');
    }

    return serverURI;
};

export const importContacts = async (user, code, type, subtype) => {
    await pushContactsToken(user.uid, code, type, subtype);
    return user;
};

export const importMap = {
    google: () => Observable.fromPromise(importContacts()),
};

export const loginViaGoogle = async () => {
    const loginWithGoogleWeb = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        const { user } = await firebase.auth().signInWithPopup(provider);

        return user;
    };

    const loginWithGoogleNative = async () => {
        try {
            // eslint-disable-next-line
            const { GoogleSignin } = require('@react-native-community/google-signin');
            // eslint-disable-next-line
            const Config = require('react-native-config').default;
            // noinspection Annotator
            GoogleSignin.configure({
                webClientId: Config.GOOGLE_LOGIN_CLIENT_ID,
                iosClientId: Config.GOOGLE_LOGIN_CLIENT_ID_IOS,
                offlineAccess: true,
            });
            const data = await GoogleSignin.signIn();
            return { code: data && data.serverAuthCode };
        } catch (error) {
            return { error };
        }
    };

    if (process.env.WEB) {
        return loginWithGoogleWeb();
    }

    return loginWithGoogleNative();
};

export const loginWithToken = async (email, token) => {
    try {
        return firebase.auth().signInWithEmailAndPassword(email, token);
    } catch (error) {
        Sentry.captureException(error);
        router.next(LOGIN_FAILED_URL);
        return error;
    }
};

export const loginMap = {
    google: () => Observable.fromPromise(loginViaGoogle()),
    password: credentials => Observable.defer(async () => {
        const { data: { token, email } } = await loginWithPassword(credentials);
        return loginWithToken(email, token);
    }),
    oneTimeKey: ({ uid, key }) => Observable.defer(async () => {
        const { data: { token, email } } = await verifyOneTimeLoginKey(uid, key);
        return loginWithToken(email, token);
    }),
    magic: ({ email, token }) => Observable.defer(() => loginWithToken(email, token)),
    email: ({ email }) => Observable.fromPromise(sendMagicTokenEmail(email)),
    session: () => Observable.fromPromise(new Promise(resolve => resolve())),
};


export const sendLoginLinkEmail = (email, options) => Observable.fromPromise(sendMagicTokenEmail(email, options));
export const sendLoginLinkEmailV2 = (identifier, options) => Observable.fromPromise(sendMagicTokenEmailV2(identifier, options));
export const sendLoginLinkText = phone => Observable.fromPromise(sendMagicTokenText(phone));
export const sendLoginLinkTextV2 = (identifier, options) => Observable.fromPromise(sendMagicTokenTextV2(identifier, options));

export const pullUserFromFirebaseOperation = async () => {
    try {
        const {
            uid,
            // displayName,
            // email,
            // photoURL,
        } = firebaseCurrentUser();

        const user = await usersRef.child(uid)
            .once('value');

        const currentUser = (user.val() && user.val().credentials) ? user.val() : { credentials: {} };
        const pushUser = currentUser;

        /* IRRELEVANT Because we are no longer using firebase auth
        And this is just supposed to sync between firebase auth and firebase real-time
        const pushUser = {
            credentials: {
                ...currentUser.credentials,
                name: currentUser.credentials.name || displayName,
                email: currentUser.credentials.email || email,
                profileImageURL: currentUser.credentials.profileImageURL || photoURL,
            },
            meta: {
                ...currentUser.meta,
            },
            settings: currentUser.settings || {},
            conversations: currentUser.conversations || {},
            events: currentUser.events || {},
        };

        if (!currentUser) {
            await usersRef.child(uid)
                .set(pushUser);
        }

        if (!currentUser.credentials) {
            await usersRef.child(`${uid}/credentials`)
                .set(pushUser.credentials);
        }

        if (!currentUser.credentials.name) {
            await usersRef.child(`${uid}/credentials/name`)
                .set(pushUser.credentials.name);
        }

        if (!currentUser.credentials.email) {
            await usersRef.child(`${uid}/credentials/email`)
                .set(pushUser.credentials.email);
        }

        if (!currentUser.profileImageURL && pushUser.credentials.profileImageURL) {
            await usersRef.child(`${uid}/credentials/profileImageURL`)
                .set(pushUser.credentials.profileImageURL);
        } */

        const finalReturn = Object.assign(pushUser, { uid });
        return finalReturn;
    } catch (error) {
        console.log('caught ERROR in auth.firebase.js', error);
        return {};
    }
};


export const _updateMetadataAttributeValue = async (uid, attributeName, attributeValue) => {
    return usersRef.child(`${uid}/meta/web/${attributeName}`).set(attributeValue);
};

export const updateMetadataAttributeValue = (...data) => Observable.defer(async () => _updateMetadataAttributeValue(...data));

export const _setUserOnboardingStatusAsTrue = async (uid) => {
    return usersRef.child(`${uid}/meta/web/onboarded`).set(true);
};

export const setUserOnboardingStatusAsTrue = uid => Observable.defer(async () => _setUserOnboardingStatusAsTrue(uid));

export const pullUserFromFirebase = () => Observable.defer(pullUserFromFirebaseOperation);

export const updateProfile = ({
    uid,
    linkedin = '',
    headline = '',
    profileImageURL = '',
    name = '',
}) => Observable
    .fromPromise(Promise.all([
        usersRef.child(`${uid}/credentials`).update({
            name,
            headline,
            linkedin,
            profileImageURL,
        }),
        firebase.auth().currentUser.updateProfile({ photoURL: profileImageURL, name }),
    ]));

export const updateSettings = (uid, settings) => Observable
    .fromPromise(usersRef.child(`${uid}/settings`).update({
        'content-size-web': settings['content-size-web'],
    })
        .catch(console.log));

export const addNotificationToken = async (uid, token) => {
    let deviceId = await localStorage.get('deviceId');

    if (!deviceId) {
        // eslint-disable-next-line
        deviceId = process.env.WEB ? cuid() : require('react-native-device-info').getUniqueId();
        await localStorage.set('deviceId', deviceId);
    }

    if (!token) {
        return null;
    }

    return usersPrivateRef.child(`${uid}/tokens/FCM/${deviceId}`).set({
        token,
        type: process.env.WEB ? 'web' : 'android',
        build: version,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
};

export const removeNotificationToken = async () => {
    const deviceId = process.env.WEB ? localStorage.get('deviceId') : await localStorage.get('deviceId');
    const { uid } = firebaseCurrentUser();

    if (!deviceId) {
        return;
    }

    localStorage.remove('deviceId', deviceId);

    usersPrivateRef.child(`${uid}/tokens/FCM/${deviceId}`).remove();
};

export const observeAuthStateChange = () => {
    return Observable.using(firebase.auth, auth =>
        Observable.create(observer =>
            auth.onAuthStateChanged((user) => {
                return observer.next(user);
            })));
};

export const observeOnlineStatus = () => Observable.fromEvent(database.ref('/.info/connected'), 'value');

export const modifyOnlineStatus = uid => Observable
    .defer(async () => {
        const userMetaRef = usersRef.child(`${uid}/meta`);
        await userMetaRef.onDisconnect().update({
            online: null,
            lastOnline: firebase.database.ServerValue.TIMESTAMP,
        });
        return userMetaRef.child('online').set(true);
    });

export const login = (type, data) => {
    const loginOperation = async () => {
        switch (type) {
        case 'password':
        case 'magic':
        case 'phone':
        case 'google-mobile':
        case 'google': {
            const { data: { token, user } } = await loginWithType(type, data);

            await firebase.auth().signInWithCustomToken(token);
            return user;
        }
        case 'session':
            return pullUserFromFirebaseOperation();
        default:
            throw new Error('No login method specified!');
        }
    };

    return Observable.defer(() => loginOperation());
};

export const loginV2 = (type, data, returnToken = false) => {
    const loginOperation = async () => {
        switch (type) {
        case 'password':
        case 'magic':
        case 'phone':
        case 'google-mobile':
        case 'google': {
            const { data: { token, user } } = await loginWithTypeV2(type, data);

            if (returnToken) {
                return { token, user };
            }

            await firebase.auth().signInWithCustomToken(token);
            return user;
        }
        case 'session':
            return pullUserFromFirebaseOperation();
        default:
            throw new Error('No login method specified!');
        }
    };

    return Observable.defer(() => loginOperation());
};

export const modifyOnlineFocusStatus = async (uid = currentUser().uid, status = false) => {
    const userMetaRef = usersRef.child(`${uid}/meta`);
    await userMetaRef.update({
        onlineFocused: status,
        lastOnlineFocused: status ? null : firebase.database.ServerValue.TIMESTAMP,
    });
};

/* eslint-disable no-return-await */
export const logoutUser = () => {
    const doBeforeLogout$ = Observable.from([
        removeNotificationToken,
        modifyOnlineFocusStatus,
    ]).pipe(
        concatMap(fn => Observable.defer(async () => await fn())),
        ignoreElements(),
    );
    const logout$ = Observable.defer(async () => await firebase.auth().signOut());
    return Observable.concat(doBeforeLogout$, logout$);
};
/* eslint-enable no-return-await */

export const saveProfilePicture = profileImageURL => usersRef
    .child(`${firebaseCurrentUser().uid}/credentials/profileImageURL`).set(profileImageURL);

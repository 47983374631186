import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const PrivacyAsync = lazy(() => import('./privacy').then(module => ({ default: module.Privacy })));

export const Privacy = () => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <PrivacyAsync />
        </Suspense>
    );
};



/**
 * Decrypt Message Modal
 */
export const OPEN_DECRYPT_MESSAGE_MODAL = 'OPEN_DECRYPT_MESSAGE_MODAL';
export const CLOSE_DECRYPT_MESSAGE_MODAL = 'CLOSE_DECRYPT_MESSAGE_MODAL';
export const UPDATE_DECRYPT_PASSWORD = 'UPDATE_DECRYPT_PASSWORD';
export const DECRYPT_MESSAGE = 'DECRYPT_MESSAGE';
export const DECRYPT_MESSAGE_SUCCESS = 'DECRYPT_MESSAGE_SUCCESS';
export const DECRYPT_MESSAGE_FAIL = 'DECRYPT_MESSAGE_FAIL';

// Encrypt Message Modal Constants
export const SET_BLACK_SMS_OPTIONS_VISIBILITY = 'SET_BLACK_SMS_OPTIONS_VISIBILITY';
export const SET_BLACK_SMS_PASSWORD = 'SET_BLACK_SMS_PASSWORD';
export const SET_BLACK_SMS_MODE = 'SET_BLACK_SMS_MODE';
export const UPDATE_MESSAGE_TO_SEND = 'UPDATE_MESSAGE_TO_SEND';

/**
 * Send Text To Sign In Modal
 */
export const OPEN_SEND_TEXT_TO_SIGN_IN_MODAL = 'OPEN_SEND_TEXT_TO_SIGN_IN_MODAL';
export const CLOSE_SEND_TEXT_TO_SIGN_IN_MODAL = 'CLOSE_SEND_TEXT_TO_SIGN_IN_MODAL';
export const SEND_SIGN_IN_TEXT = 'SEND_SIGN_IN_TEXT';
export const SEND_SIGN_IN_TEXT_SUCCESS = 'SEND_SIGN_IN_TEXT_SUCCESS';
export const SEND_SIGN_IN_TEXT_FAILED = 'SEND_SIGN_IN_TEXT_FAILED';
export const UPDATE_SEND_TEXT_PHONE_NUMBER = 'UPDATE_SEND_TEXT_PHONE_NUMBER';

/**
 * Leave Conversation Modal
 */
export const OPEN_ASK_TO_LEAVE_CONFIRMATION_MODAL = 'OPEN_ASK_TO_LEAVE_CONFIRMATION_MODAL';
export const CLOSE_ASK_TO_LEAVE_CONFIRMATION_MODAL = 'CLOSE_ASK_TO_LEAVE_CONFIRMATION_MODAL';
export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION';
export const LEAVE_EMPTY_CONVERSATION = 'LEAVE_EMPTY_CONVERSATION';
// Special case: only call when leaving a conversation that has not been created yet
export const LEAVE_CONVERSATION_SUCCESS = 'LEAVE_CONVERSATION_SUCCESS';

/**
 * Update Current Conversation Title
 */
export const OPEN_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL = 'OPEN_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL';
export const CLOSE_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL = 'CLOSE_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL';
export const UPDATE_NEXT_CONVERSATION_NAME_ACTION = 'UPDATE_NEXT_CONVERSATION_NAME_ACTION';


/**
 * Manage Participants Modal Modal
 */
export const OPEN_MANAGE_PARTICIPANTS_MODAL = 'OPEN_MANAGE_PARTICIPANTS_MODAL';
export const CLOSE_MANAGE_PARTICIPANTS_MODAL = 'CLOSE_MANAGE_PARTICIPANTS_MODAL';
export const UPDATE_NEXT_USER_TO_ADD_VALUE = 'UPDATE_NEXT_USER_TO_ADD_VALUE';
export const REMOVE_PARTICIPANT_FROM_GROUP = 'REMOVE_PARTICIPANT_FROM_GROUP';
export const REMOVE_PARTICIPANT_FROM_GROUP_SUCCESS = 'REMOVE_PARTICIPANT_FROM_GROUP_SUCCESS';
export const REMOVE_PARTICIPANT_FROM_GROUP_FAIL = 'REMOVE_PARTICIPANT_FROM_GROUP_FAIL';
export const ADD_PARTICIPANT_TO_GROUP = 'ADD_PARTICIPANT_TO_GROUP';
export const ADD_PARTICIPANT_TO_GROUP_SUCCESS = 'ADD_PARTICIPANT_TO_GROUP_SUCCESS';
export const ADD_PARTICIPANT_TO_GROUP_FAIL = 'ADD_PARTICIPANT_TO_GROUP_FAIL';

/**
 * Onboarding Modal
 */
export const OPEN_ONBOARDING_MODAL = 'OPEN_ONBOARDING_MODAL';
export const CLOSE_ONBOARDING_MODAL = 'CLOSE_ONBOARDING_MODAL';
export const GOTO_NEXT_ONBOARDING_STEP = 'GOTO_NEXT_ONBOARDING_STEP';
export const SET_NEXT_ONBOARDING_STEP = 'SET_NEXT_ONBOARDING_STEP';
export const SEND_VERIFICATION_TEXT = 'SEND_VERIFICATION_TEXT';
export const SEND_VERIFICATION_TEXT_V2 = 'SEND_VERIFICATION_TEXT_V2';
export const SEND_VERIFICATION_TEXT_SUCCESS = 'SEND_VERIFICATION_TEXT_SUCCESS';
export const SEND_VERIFICATION_TEXT_FAIL = 'SEND_VERIFICATION_TEXT_FAIL';
export const VERIFY_VERIFICATION_CODE = 'VERIFY_VERIFICATION_CODE';
export const VERIFY_VERIFICATION_CODE_SUCCESS = 'VERIFY_VERIFICATION_CODE_SUCCESS';
export const VERIFY_VERIFICATION_CODE_FAIL = 'VERIFY_VERIFICATION_CODE_FAIL';

export const OPEN_ASK_TO_VERIFY_NUMBER_MODAL = 'OPEN_ASK_TO_VERIFY_NUMBER_MODAL';
export const CLOSE_ASK_TO_VERIFY_NUMBER_MODAL = 'CLOSE_ASK_TO_VERIFY_NUMBER_MODAL';

/**
 * Announcement Modal
 */
export const OPEN_ANNOUNCEMENT_MODAL = 'OPEN_ANNOUNCEMENT_MODAL';
export const CLOSE_ANNOUNCEMENT_MODAL = 'CLOSE_ANNOUNCEMENT_MODAL';

/**
 * Edit Profile Modal
 */
export const OPEN_EDIT_PROFILE_MODAL = 'OPEN_EDIT_PROFILE_MODAL';
export const CLOSE_EDIT_PROFILE_MODAL = 'CLOSE_EDIT_PROFILE_MODAL';

/**
 * Edit Settings Modal
 */
export const OPEN_EDIT_SETTINGS_MODAL = 'OPEN_EDIT_SETTINGS_MODAL';
export const CLOSE_EDIT_SETTINGS_MODAL = 'CLOSE_EDIT_SETTINGS_MODAL';
export const FETCH_USER_IDENTIFIERS = 'FETCH_USER_IDENTIFIERS';
export const FETCH_USER_IDENTIFIERS_SUCCESS = 'FETCH_USER_IDENTIFIERS_SUCCESS';
export const FETCH_USER_IDENTIFIERS_FAILURE = 'FETCH_USER_IDENTIFIERS_FAILURE';
export const RESET_USER_IDENTIFIERS_STATE = 'RESET_USER_IDENTIFIERS_STATE';
/**

 * Add contacts modal
 */
export const OPEN_ADD_CONTACTS_MODAL = 'OPEN_ADD_CONTACTS_MODAL';
export const CLOSE_ADD_CONTACTS_MODAL = 'CLOSE_ADD_CONTACTS_MODAL';

/**
 * Add password modal
 */

export const OPEN_ADD_PASSWORD_MODAL = 'OPEN_ADD_PASSWORD_MODAL';
export const CLOSE_ADD_PASSWORD_MODAL = 'CLOSE_ADD_PASSWORD_MODAL';
export const ADD_PASSWORD_TO_USER_ACCOUNT = 'ADD_PASSWORD_TO_USER_ACCOUNT';
export const ADD_PASSWORD_TO_USER_ACCOUNT_SUCCESS = 'ADD_PASSWORD_TO_USER_ACCOUNT_SUCCESS';
export const ADD_PASSWORD_TO_USER_ACCOUNT_FAIL = 'ADD_PASSWORD_TO_USER_ACCOUNT_FAIL';

/**
 * Composer Modal
 */
export const OPEN_COMPOSER = 'OPEN_COMPOSER';
export const CLOSE_COMPOSER = 'CLOSE_COMPOSER';
export const RESET_COMPOSER = 'RESET_COMPOSER';


/* Input bar */
export const FOCUS_INPUT = 'FOCUS_INPUT';
export const FOCUS_INPUT_COMPLETE = 'FOCUS_INPUT_COMPLETE';

/**
 * Confirm Conversation Creation Modal
 */
export const OPEN_CONFIRM_CONVERSATION_OVERRIDE = 'OPEN_CONFIRM_CONVERSATION_OVERRIDE';
export const CLOSE_CONFIRM_CONVERSATION_OVERRIDE = 'CLOSE_CONFIRM_CONVERSATION_OVERRIDE';

/**
 * Alert Modal
 */
export const OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL';
export const CLOSE_ALERT_MODAL = 'CLOSE_ALERT_MODAL';

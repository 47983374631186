export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const IMPORT_CONTACTS = 'IMPORT_CONTACTS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_V2 = 'LOGIN_USER_V2';
export const RESET_ONBOARDING_MODAL_STATE = 'RESET_ONBOARDING_MODAL_STATE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_WITH_EMAIL_COMPLETE = 'LOGIN_WITH_EMAIL_COMPLETE';
export const VALIDATE_LOGGED_IN_ON_FIREBASE = 'VALIDATE_LOGGED_IN_ON_FIREBASE';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const CLEAR_LOGIN_LINK_ERROR = 'CLEAR_LOGIN_LINK_ERROR';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const SAVE_PROFILE_PICTURE = 'SAVE_PROFILE_PICTURE';
export const PROFILE_PICTURE_ACTION = 'PROFILE_PICTURE_ACTION';
export const SAVE_PROFILE_PICTURE_SUCCESS = 'UPDATE_PROFILE_PICTURE_SUCCESS';
export const SAVE_PROFILE_PICTURE_FAILURE = 'SAVE_PROFILE_PICTURE_FAILURE';
export const UPDATE_DISPLAY_NAME = 'UPDATE_DISPLAY_NAME';
export const UPDATE_DISPLAY_NAME_SUCCESS = 'UPDATE_DISPLAY_NAME_SUCCESS';
export const REDIRECT_USER_SUCCESS = 'REDIRECT_USER_SUCCESS';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_HEADLINE = 'UPDATE_HEADLINE';
export const UPDATE_LINKEDIN_URL = 'UPDATE_LINKEDIN_URL';
export const UPDATE_LINKEDIN_URL_SUCCESS = 'UPDATE_LINKEDIN_URL_SUCCESS';
export const UPDATE_HEADLINE_SUCCESS = 'UPDATE_HEADLINE_SUCCESS';
export const SET_PRE_MERGE_DATA = 'SET_PRE_MERGE_DATA';
export const CLEAR_PRE_MERGE_DATA = 'CLEAR_PRE_MERGE_DATA';
export const MERGE_ACCOUNTS = 'MERGE_ACCOUNTS_ACTION';
export const MERGE_ACCOUNTS_SUCCESS = 'MERGE_ACCOUNTS_SUCCESS';
export const MERGE_ACCOUNTS_FAILED = 'MERGE_ACCOUNTS_FAILED';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS';
export const SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILED = 'SAVE_SETTINGS_FAILED';
export const SEND_LOGIN_LINK_EMAIL = 'SEND_LOGIN_LINK_EMAIL';
export const SEND_LOGIN_LINK_EMAIL_V2 = 'SEND_LOGIN_LINK_EMAIL_V2';
export const SEND_LOGIN_LINK_EMAIL_SUCCESS = 'SEND_LOGIN_LINK_EMAIL_SUCCESS';
export const SEND_LOGIN_LINK_EMAIL_FAIL = 'SEND_LOGIN_LINK_EMAIL_FAIL';
export const SEND_LOGIN_LINK_TEXT = 'SEND_LOGIN_LINK_TEXT';
export const SEND_LOGIN_LINK_TEXT_V2 = 'SEND_LOGIN_LINK_TEXT_V2';
export const SEND_LOGIN_LINK_TEXT_SUCCESS = 'SEND_LOGIN_LINK_TEXT_SUCCESS';
export const SEND_LOGIN_LINK_TEXT_FAIL = 'SEND_LOGIN_LINK_TEXT_FAIL';
export const UPDATE_ONLINE_STATUS_SUCCESS = 'UPDATE_ONLINE_STATUS_SUCCESS';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_TO_LOCALSTORAGE = 'SAVE_USER_PROFILE_TO_LOCALSTORAGE';
export const CLEAR_LOADING_INITIAL_AUTH_STATE = 'CLEAR_LOADING_INITIAL_AUTH_STATE';
export const CLEAR_USER_PROFILE_FROM_LOCALSTORAGE_SUCCESS = 'CLEAR_USER_PROFILE_TO_LOCALSTORAGE_SUCCESS';
export const MAGIC_LINK_CODE_SEND_SUCCESSFUL = 'MAGIC_LINK_CODE_SEND_SUCCESSFUL';
export const MAGIC_LINK_CODE_SEND_UNSUCCESSFUL = 'MAGIC_LINK_CODE_SEND_UNSUCCESSFUL';
export const MAGIC_CODE_LINK_MATCH_SUCCESSFUL = 'MAGIC_CODE_LINK_MATCH_SUCCESSFUL';
export const MAGIC_CODE_LINK_MATCH_UNSUCCESSFUL = 'MAGIC_CODE_LINK_MATCH_UNSUCCESSFUL';
export const UPDATE_METADATA_ATTRIBUTE_VALUE = 'UPDATE_METADATA_ATTRIBUTE_VALUE';
export const CLEAR_EMAIL_SENT_STATUS = 'CLEAR_EMAIL_SENT_STATUS';
export const CLEAR_TEXT_SENT_STATUS = 'CLEAR_TEXT_SENT_STATUS';
export const LOAD_FROM_CACHE = 'LOAD_FROM_CACHE';
export const SYNC_USER_AND_CONVERSATIONS = 'SYNC_USER_AND_CONVERSATION';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const RESET_CHANGE_PASSWORD_STATE = 'RESET_CHANGE_PASSWORD_STATE';
export const SET_NATIVE_MAGIC_URL = 'SET_NATIVE_MAGIC_URL';
export const REMOVE_NATIVE_MAGIC_URL = 'REMOVE_NATIVE_MAGIC_URL';
const magicLifeSpan = Math.abs(process.env.MAGIC_LIFE_SPAN) || 3;
const magicLifeSpanErrorText = magicLifeSpan === 1 ? 'Once' : `${magicLifeSpan} times`;
export const authErrorCodes = {
    'auth/user-not-found': 'Invalid credentials! Please try again',
    'auth/invalid-credentials': 'Invalid credentials! Please try again',
    'auth/already-merged': 'This account was previously merged into another and the process failed in the middle. Please login with your primary account and ask support for help.',
    'missing-identifier': 'You are missing your email or phone',
    'invalid-identifier': 'Your email or phone are invalid',
    'missing-password': 'You are missing a password',
    'invalid-magic-token': `You can only use each magic login link ${magicLifeSpanErrorText}. Please confirm your email address below to generate a new one.`,
    'missing-google-token': 'Error with Google signup, try again',
    'invalid-code': 'Invalid verification code',
};

import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HomeContainer } from '../home/home-container/home-container.async';
import {
    Home,
    Magic,
    MagicMobile,
} from '../home';
import { NotFound } from '../shared/not-found/not-found';
import { store } from '../app.reducer';
import { Privacy } from '../home/privacy/privacy.async';
import { Terms } from '../home/terms-of-service/terms-of-service.async';
import * as db from '../app.firebase';
import * as actions from '../app.actions';
import {
    getCurrentURLQueryString,
    decodeEqual,
    setDefaultURL,
} from '../app.helpers';
import { Login } from '../home/login/login.async';

import history from '../app.history';
import Messenger from '../messenger/messenger.async';
import { localStorage } from '../app.services';

import ErrorHandler from './error-handler';


export default class extends Component {
    componentDidMount() {
        if (this.isMobile()) {
            this.redirectToLandingPage();
            if (store.getState().auth.user) {
                store.dispatch(actions.LogoutUserAction());
            }
            return;
        }
        // if(!store.getState().auth.user) {
        //     if (store.getState().auth.user) {
        //         store.dispatch(actions.LogoutUserAction());
        //     }
        //     return;
        // }
        this.checkForBadPaths();
        this.checkLoginState();
        store.dispatch(actions.InitializeAppAction());
        window.addEventListener('visibilitychange', this.activatedTab);
        window.addEventListener('focus', this.activatedTab);
    }

    activatedTab = () => {
        const { auth: { user } } = store.getState();
        let live;
        if (process.env.WEB) {
            live = document.hasFocus() && document.hidden === false;
        } else {
            live = (document.hidden === false);
        }

        if (live) store.dispatch(actions.SetCurrentConversationAsReadAction());
        if (user) {
            db.modifyOnlineFocusStatus(user.uid, live ? true : null);
        }
    }

    checkForBadPaths() {
        // electron specific hacks
        if (window.location.pathname.includes('index.html')) {
            const { path: electronHash, static_route: staticRoute } = getCurrentURLQueryString();
            global.STATIC_ROUTE = staticRoute;

            if (electronHash) {
                history.push(decodeEqual(electronHash));
            }
        }

        // logged in redirect
        if (localStorage.get('logged-in') && !window.location.pathname.includes('magic')) {
            history.push(setDefaultURL());
        }
    }

    checkLoginState() {
        db.observeAuthStateChange()
            .take(1)
            .subscribe((user) => {
                if (window.location.pathname.includes('/u/')) {
                    const nextPath = window.location.pathname;

                    if (user) {
                        this.activatedTab();
                        history.push(setDefaultURL());
                        return;
                    }
                    const slicingPoint = nextPath.indexOf('u') + 2;
                    const slicedURL = nextPath.slice(slicingPoint);
                    history.push(`/?old-path=${slicedURL}`);
                }
            });
    }

    wrapHomeContainer(child, waitForAuth = false) {
        // eslint-disable-next-line
        return <HomeContainer isMobile={this.isMobile()} waitForAuth={waitForAuth}>{child}</HomeContainer>;
    }

    isMobile = () => /iPhone/ig.test(navigator.userAgent) || /Android/ig.test(navigator.userAgent) || /iPad/ig.test(navigator.userAgent);

    redirectToLandingPage = () => {
        const isAllowedRoute = ['/', '/terms', '/privacy', '/magic'].includes(history.location.pathname);

        if (!isAllowedRoute) history.push('/');
    }

    render() {
        const isMobile = this.isMobile();
        const basename = process.env.BASENAME || '';
        return (
            <Provider store={store}>
                <ErrorHandler>
                    <Router
                        history={history}
                        basename={basename}
                    >
                        <Switch>
                            <Route
                                path="/privacy"
                                component={this.wrapHomeContainer.bind(this, <Privacy />)}
                            />
                            <Route
                                path="/terms"
                                component={this.wrapHomeContainer.bind(this, <Terms />)}
                            />
                            <Route
                                path="/magic"
                                component={isMobile ? MagicMobile : Magic}
                            />
                            <Route
                                path="/u/:id"
                                component={Messenger}
                            />
                            <Route
                                path="/login"
                                component={Login}
                            />
                            <Route
                                path="/outlook"
                                component={() => null}
                            />
                            <Route
                                path="/"
                                component={this.wrapHomeContainer.bind(this, <Home isMobile={isMobile} />, /* waitForAuth */ true)}
                            />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </ErrorHandler>
            </Provider>
        );
    }
}

import _ from 'underscore';
import cuid from 'cuid';
import AES from 'crypto-js/aes';
import _scrollIntoView from 'scroll-into-view-if-needed';
import moment from 'moment';
import * as db from '../app.firebase';
import { updatePageTitle, getCurrentTime, formatPhoneNumber, objectEntriesForKey } from '../app.helpers';
import { firebase as pandaFirebase } from '../app.cross-platform';
import { generateNextMessageId } from './messenger.firebase';
import { localStorage } from '../app.services';
import { addNotificationToken } from '../auth/auth.firebase';

export * from './messenger.overrides';
// Disabling messaging on unsupported browsers

let messaging;

if (pandaFirebase.messaging && (pandaFirebase.messaging.isSupported ? pandaFirebase.messaging.isSupported() : true)) {
    messaging = pandaFirebase.messaging();
}

if (process.env.WEB && messaging) {
    messaging.usePublicVapidKey(process.env.FIREBASE_WEB_VAPID_KEY);
}

export const setupNotifications = async (uid) => {
    try {
        if (messaging) {
            let token;
            if (process.env.WEB) {
                try {
                    token = await messaging.requestPermission();
                } catch (_) {
                    token = null;
                }
            } else {
                if (!(await messaging.hasPermission())) {
                    await messaging.requestPermission();
                }

                token = await messaging.getToken();
            }

            if (token) {
                addNotificationToken(uid, token).catch(reason => console.log('ERROR', reason));
            } else {
                console.log('Warning FCM token from firebase is null');
            }
        }
    } catch (e) {
        if (e.code === 'messaging/permission-blocked') {
            localStorage.set('deviceId', 'blocked');
        }

        console.error(e);
    }
};

export const parseEmail = (email = '') => {
    if (email && email.includes('sim@pandamessage.com')) {
        return formatPhoneNumber(email.replace('sim@pandamessage.com', ''));
    } else if (email) {
        return email.toLowerCase();
    }
    return '';
};

export const getEarliestMessage = (messages = []) => {
    return messages.length
        ? messages.reduce((prev, curr) => {
            return prev.timestamp < curr.timestamp ? prev : curr;
        })
        : {};
};

export const getFirstFile = (files) => {
    if (!files.length) {
        return null;
    }

    const [firstFile] = files;

    if (firstFile.kind !== 'file') {
        return null;
    }

    return firstFile.getAsFile();
};

export const getLatestMessage = (messages = []) => {
    return messages.length
        ? messages.reduce((prev, curr) => {
            return prev.timestamp > curr.timestamp ? prev : curr;
        })
        : {};
};

export const groupByFiveMins = (wholeDayMessages = [], groupedByFiveMins = []) => {
    if (!wholeDayMessages.length) return groupedByFiveMins;
    const earliestMessage = wholeDayMessages[0];
    const resultHolder = [];
    wholeDayMessages.some((msg) => {
        if (
            moment(earliestMessage.timestamp)
                .add(5, 'minutes')
                .format('x') < msg.timestamp
        ) {
            return null;
        }
        resultHolder.push(msg);
        return false;
    });
    groupedByFiveMins = [...groupedByFiveMins, resultHolder];
    wholeDayMessages.splice(0, resultHolder.length);
    return groupByFiveMins(wholeDayMessages, groupedByFiveMins);
};
export const getFirstNonSelfUser = (participants = []) => {
    if (participants.length > 1) {
        return participants.filter(({ id }) => db.currentUser().uid !== id)[0] || {};
    }

    return participants[0] || {};
};

export const getTrueLocalConversationId = ({ type = 'single', id, participants }) => {
    if (type === 'single') {
        return getFirstNonSelfUser(participants).id;
    }

    return id;
};

export const getAnnouncementCount = (tags = {}) => {
    try {
        return Object.values(tags).find(({ name }) => name === 'announcement').frequency;
    } catch (e) {
        return false;
    }
};

export const scrollIntoView = (id, settings) => {
    const el = document.getElementById(id);

    if (el) {
        _scrollIntoView(el, {
            scrollMode: 'if-needed',
            block: 'nearest',
            inline: 'nearest',
            ...settings,
        });
    }
};

export const generateParticipantList = (participants = [], senderEmail, senderPhone) =>
    participants.filter(({ email, phone }) => {
        if (participants.length === 1) {
            return true;
        }

        if (email) {
            return senderEmail !== email;
        }

        if (phone) {
            return phone !== senderPhone;
        }

        return false;
    });

export const applyTags = (message, tags = {}, clean, participants) =>
    message
        .replace(/\B#\w\w+\b/g, (match) => {
            if (!tags[match.replace('#', '')]) {
                return match;
            }

            const tagId = match.replace('#', '');

            return `[${tags[tagId][clean ? 'name' : 'id']}](#search?tag=${tagId})`;
        })
        .replace(/\B@\w\w+\b/g, (match) => {
            const userId = match.replace('@', '');
            const user = participants && participants.find(({ id }) => id === userId);

            if (!user) {
                return match;
            }

            return `[@${user[clean ? 'name' : 'id']}](#user?id=${userId})`;
        });

// eslint-disable-next-line no-confusing-arrow
export const truncateString = (str, n = 30) => (str.length > n ? `${str.substr(0, n - 1)}...` : str);

export const getListOfKeysFromParticipants = (participants = [], key) => {
    if (participants.length) {
        return participants.map(participant => participant[key]);
    }

    return [];
};

export const getNthLastValue = (arr, n = 1, safe) => {
    if (safe) {
        return arr && arr[arr.length - n] ? arr[arr.length - n] : safe;
    }
    return arr[arr.length - n];
};

export const isGroupOwner = (admins = []) => !!Object.keys(admins).find(id => db.currentUser().uid === id);

export const getParticipantFromId = (uid, participants = []) => participants.find(({ id }) => id === uid) || {};

export const getConversationName = ({ type, participants, name }) => {
    const { name: userName, email: userEmail } = getFirstNonSelfUser(participants);

    return type === 'group' ? name || 'Untitled Group' : userName || userEmail;
};

export const populateParticipantsForConversations = async (conversations) => {
    const participantEntries = conversations
        .filter(({ members }) => !!members)
        .reduce((obj, { members, removedMembers = {} }) => {
            const participantIdTuple = [...Object.entries(members), ...objectEntriesForKey(removedMembers, 'email')] || [];
            obj = [...obj, ...participantIdTuple];
            return obj;
        }, []);

    const participants = await db._populateParticipantsFromIds(participantEntries);

    return conversations.map(conversation => ({
        ...conversation,
        participants: [...Object.keys(conversation.members), ...Object.keys((conversation.removedMembers ?? {}))]
            .map(participantId => participants.find(({ id }) => id === participantId))
            .filter(participant => !!participant),
    }));
};

/*
 * A message is not read if the message is:
 *  - a temporary message
 *  - created by the user reading it
 *  - already read by the user
 */

export const messageIsNotRead = ({ read = [], fromID, temp }, currentUserId) => {
    return !temp && fromID !== currentUserId && !read[currentUserId];
};

export const removeObjectByKey = (arr, removeKey) =>
    Object.keys(arr).reduce((result, key) => {
        if (key !== removeKey) {
            // eslint-disable-next-line
            result[key] = arr[key];
        }
        return result;
    }, {});

export const pushUniqueObject = (arr, obj, key) => {
    if (_.findIndex(arr, obj2 => obj2[key] === obj[key]) === -1) {
        return [obj, ...arr];
    }
    return arr;
};

export const checkIfValidEmail = (email) => {
    // eslint-disable-next-line
    const emailRegExp = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    return emailRegExp.test(email);
};

export const standardizeNumber = (phone = '') => {
    const phoneNumber = phone.replace(/\D/g, '');

    if (phoneNumber.length <= 10) {
        return `1${phoneNumber}`;
    }

    return phoneNumber;
};

export const cleanPhoneNumber = (phone = '') => phone.toString().replace(/\D/g, '');

export const checkIfValidPhoneNumber = (number = '') => {
    const phone = number.replace(/\+|-|\(|\)| |\[|\]/g, '');

    if (Number.isNaN(Number(phone))) {
        return false;
    }

    if (phone.length >= 10 && phone.length <= 15) {
        return true;
    }

    return false;
};

export const validateUnknownIdentifier = (unknownIdentifier) => {
    if (checkIfValidEmail(unknownIdentifier)) {
        return { email: unknownIdentifier };
    }

    if (checkIfValidPhoneNumber(unknownIdentifier)) {
        return { phone: cleanPhoneNumber(unknownIdentifier) };
    }

    return null;
};

export const transformMessages = (messages = {}) =>
    Object.keys(messages || {}).reduce((obj, key) => {
        if (messages[key]) {
            obj.push({
                ...messages[key],
                id: Number.isNaN(parseInt(key, 10)) ? key : (messages[key].id || key),
            });
        }

        return obj;
    }, []);

export const shallowApplyIdToTag = (query, tags) => {
    const tagArray = Object.values(tags);

    const extractTagsFromMessage = (match) => {
        const tagName = match.replace('#', '');
        const { id: tagId } = tagArray.find(({ name }) => name === tagName) || {};

        return `${tagId || match}`;
    };

    return query.replace(/\B#\w\w+\b/g, extractTagsFromMessage);
};

export const generateNewTagInMessage = (name, id) => ({
    name,
    id,
    lastUsed: moment().valueOf(),
    frequency: 1,
});

export const extractAndParseTags = (messages, { tags = {}, admins = {} }, uid) => {
    const oldTags = tags;
    let tagStateChanged = false;

    // currently prevent non-admins to send tags
    // we don't care to validate this on the server or anything because it's primarily for demo-sake
    // TODO: in the future, please remove this feature

    if (!admins[uid] && admins[uid] !== '') {
        return { messages, tags: oldTags };
    }

    let newTags = {};
    const tagArray = _.values(tags);
    const tagsInMessage = {};

    const extractTagsFromMessage = (match) => {
        tagStateChanged = true;

        if (!match.includes('annoucement')) {
            return match;
        }

        const tagName = match.replace('#', '');
        const tagMatch = tagArray.find(({ name }) => name === tagName);
        let tagId = '';

        if (!tagMatch) {
            tagId = cuid();
            newTags = {
                [tagId]: generateNewTagInMessage(tagName, tagId),
                ...newTags,
            };
        } else {
            tagId = tagMatch.id;

            oldTags[tagId].lastUsed = moment().valueOf();
            oldTags[tagId].frequency += 1;
        }

        tagsInMessage[tagId] = {
            inline: true,
        };

        return `#${tagId}`;
    };

    const parsedMessages = messages.map((message) => {
        if (message.type === 'text') {
            const content = message.content.replace(/\B#\w\w+\b/g, extractTagsFromMessage);
            return {
                ...message,
                tags: {
                    ...tagsInMessage,
                    ...message.tags,
                },
                content,
            };
        }

        return message;
    });

    return { messages: parsedMessages, tags: { ...newTags, ...oldTags }, tagStateChanged };
};

export const generateMessage = (type, content, tags, meta = {}, cid) => {
    const messageMap = (type) => {
        return {
            file: () => ({
                content,
                type: 'file',
                metadata: meta,
                ...(meta.duration ? { duration: meta.duration } : {}),
            }),
            photo: () => ({
                meta: content,
                content: (meta.password !== undefined && meta.password.length > 0) ? AES.encrypt(content.downloadURL, meta.password).toString() : content.downloadURL,
                isImageEncrypted: (meta.password !== undefined && meta.password.length > 0),
                type: 'photo',
            }),
            text: () => ({
                content,
                type: 'text',
            }),
            composed: () => ({
                content,
                type: 'text',
                meta: { composed: true },
            }),
            secret: () => ({
                content: AES.encrypt(content, meta.password).toString(),
                type: 'secret',
            }),
        }[type]();
    };

    return {
        id: generateNextMessageId(cid),
        fromID: pandaFirebase.auth().currentUser.uid,
        isRead: false,
        tags: {},
        read: {
            [pandaFirebase.auth().currentUser.uid]: getCurrentTime(),
        },
        pushed: false,
        notified: {
            [pandaFirebase.auth().currentUser.uid]: {
                type: 'self',
                timestamp: getCurrentTime(),
            },
        },
        timestamp: getCurrentTime(),
        ...messageMap(type),
    };
};

export const getUserFromId = (id, userObject, participants = []) => {
    const { uid, credentials: user } = userObject;

    if (id === uid) {
        return user;
    }

    // check if participant is in conversation or if userID is a managed user of a participant


    const participant = [{ id: uid, ...user }, ...participants]
        .find(({ id: partnerId, managedUsers = {} }) => partnerId === id || managedUsers[id]);

    // TODO: This is a poor way to do this
    if (id === 'panda') {
        return {
            uid: 'panda',
            name: 'Sonic Bot',
            profileImageURL: 'https://firebasestorage.googleapis.com/v0/b/panda-f5718.appspot.com/o/assets%2Ficon-83.5%402x.png?alt=media&token=e32a2497-1e5b-4a80-84ee-a211a2b6fe54',
        };
    }

    return participant;
};

// we aren't proceeding with this way, but I commented out to make sure we don't get any bug in future.
// export const getNewConversationName = () => {
//     const el = document.getElementById('new-conversation-name-value');
//
//     if (el) {
//         return el.value || '';
//     }
//
//     return '';
// };

export const updateConversation = (state, nextState, fn) => {
    const id = nextState ? nextState.id : state.currentConversation.id;

    const nextConversations = new Map([...state.conversations]);

    const conversationFromList = nextConversations.get(id) || {};

    if (!state.currentConversation.newConversation || conversationFromList.newConversation) {
        nextConversations.set(id, fn(nextState, conversationFromList));
    }

    const updatedConversation = {
        conversations: nextConversations,
    };

    if (state.currentConversation.id === id) {
        updatedConversation.currentConversation = fn(nextState, state.currentConversation);
    }

    return updatedConversation;
};

export const getFormattedTime = (timestamp, forMsgHeader = false) => {
    const stamp = moment(timestamp);
    if (moment().isSame(stamp, 'd')) {
        return stamp.format('h:mma');
    }

    if (
        moment()
            .subtract(1, 'd')
            .isSame(stamp, 'd')
    ) {
        // const format = stamp.minutes() === 0 ? 'h A' : 'h:mma';
        return !forMsgHeader ? 'Yesterday' : 'Yesterday';
    }

    if (
        moment()
            .add(1, 'd')
            .isSame(stamp, 'd')
    ) {
        const format = stamp.minutes() === 0 ? 'ha' : 'h:mma';
        return !forMsgHeader ? 'Tomorrow' : `Tomorrow @${stamp.format(format)}`;
    }

    if (
        moment()
            .subtract(7, 'd')
            .isBefore(stamp, 'd')
    ) {
        return !forMsgHeader ? stamp.format('ddd') : stamp.format('ddd');
    }

    return !forMsgHeader ? stamp.format('M/D') : stamp.format('M/D');
};

export const listParticipantNamesForConversation = (participants) => {
    return participants
        .filter(({ id }) => id !== db.currentUser().uid || participants.length === 1)
        .map(({ name, email, phone }, index, arr) => {
            return (name || parseEmail(email) || formatPhoneNumber(phone)) + (arr.length - 1 > index ? ', ' : '');
        });
};

export const getUserName = ({ name, email, phone }) => name || parseEmail(email) || formatPhoneNumber(phone);

export const flattenUser = ({ uid, credentials }) => ({
    ...credentials,
    id: uid,
});

export const generateSuggestionsFromQuery = ({
    query,
    currentConversationId,
    // allConversationIds,
    tags,
}) => {
    if (!query) {
        return [];
    }

    if (currentConversationId[0] === 'new') {
        return [];
    }

    return [
        {
            type: 'scope',
            scopeLabel: 'In Conversation',
            scope: currentConversationId,
        },
        // {
        //     type: 'scope',
        //     scopeLabel: 'Everywhere',
        //     scope: allConversationIds,
        // },
        ...tags
            .filter(({ name }) => name.includes(query))
            .map(({ name, id }) => ({
                type: 'tag',
                apply: id,
                label: `#${name}`,
                scopeLabel: 'In Conversation',
                scope: currentConversationId,
            })),
    ];
};

let documentTitleInterval;
let documentTitleSetValue = 0;
export const createNotificationForNewMessages = (messages) => {
    const notificationArray = Object.values(messages).map(({ type, name, from: { email = '', name: fromName = '' } = {} }) => {
        const properName = fromName ? fromName.split(' ')[0] : email.replace('sim@pandamessage.com', '');

        if (type === 'group') {
            return `${properName} messaged ${name || 'Untitled Group'}`;
        }

        return `${properName} sent you a message!`;
    });

    if (!notificationArray.length) {
        return;
    }

    clearInterval(documentTitleInterval);
    documentTitleSetValue = 0;

    documentTitleInterval = setInterval(() => {
        documentTitleSetValue += 0.5;

        const currentNotification = notificationArray[documentTitleSetValue % notificationArray.length];
        if (currentNotification) {
            updatePageTitle(currentNotification);
            return;
        }

        updatePageTitle(`(${notificationArray.length}) Sonic`);
    }, 2000);
};

export const clearNotificationForNewMessages = () => {
    clearInterval(documentTitleInterval);
    updatePageTitle();
};

window.onfocus = () => clearNotificationForNewMessages();
// eslint-disable-next-line
export const getMostRecentTags = tags => tags.sort(({ lastUsed: la, frequency: fa }, { lastUsed: lb, frequency: fb }) => lb * fb - la * fa);

export const validateNewParticipantDetails = ({
    email, phone, name = '', unknown,
}) => {
    if (checkIfValidEmail(email || unknown)) {
        return { email: email || unknown, name: name || email || unknown };
    }

    if (checkIfValidPhoneNumber(phone || unknown)) {
        const standardizedPhone = standardizeNumber(phone || unknown);
        return { phone: standardizedPhone, name: name || email || standardizedPhone };
    }

    return null;
};

export const validateParticipantNotAdded = (participant, existingParticipant = [], removedMembers = [], currentUser) => (
    currentUser.email !== participant.email
    && currentUser.phone !== participant.phone
    && (
        !existingParticipant
            .find(({ email, phone }) => (participant.email && participant.email === email) || (participant.phone && participant.phone === phone))
        || removedMembers
            .find(({ email, phone }) => (participant.email && participant.email === email) || (participant.phone && participant.phone === phone))
    )
);

export const getUniqueParticipants = (source, compareTo) => {
    const arr = source.filter(p => !compareTo.some(p2 =>
        p2.id === p.id
        || (p2.email && p.email && p2.email.toLowerCase() === p.email.toLowerCase())
        || (p2.phone && p.phone && p2.phone === p.phone)));
    // console.log('SOURCE', source);
    // console.log('COMPARETO', compareTo);
    // console.log('Result', arr);
    return arr;
// || (p2.name && p.name && p2.name.toLowerCase() === p.name.toLowerCase())
};

export const stringSplice = (str, index, count, add) => {
    if (index < 0) {
        index += str.length;

        if (index < 0) {
            index = 0;
        }
    }

    return str.slice(0, index) + (add || '') + str.slice(index + count);
};

export const getMessagesWithDays = (messages = []) => {
    const data = {};
    messages.forEach((msg) => {
        const messageDate = moment(msg.timestamp).format('MM/DD/YYYY');
        data[messageDate] = data[messageDate] && data[messageDate].length ? [...data[messageDate]] : [];
        data[messageDate].push(msg);
    });
    return data;
};

export const dayFinder = (day, metaInfo = '') => {
    switch (day) {
    case 0:
        return `Sunday ${metaInfo}`;
    case 1:
        return `Monday ${metaInfo}`;
    case 2:
        return `Tuesday ${metaInfo}`;
    case 3:
        return `Wednesday ${metaInfo}`;
    case 4:
        return `Thursday ${metaInfo}`;
    case 5:
        return `Friday ${metaInfo}`;
    case 6:
        return `Saturday ${metaInfo}`;
    default:
        return day;
    }
};

const isWithinAWeek = (momentDate, REFERENCE = moment()) => {
    const A_WEEK_OLD = REFERENCE.clone()
        .subtract(7, 'days')
        .startOf('day');
    return momentDate.isAfter(A_WEEK_OLD);
};

const getDay = (day, metaInfo = '') => {
    switch (day) {
    case moment().format('MM/DD/YYYY'):
        return 'Today ';
    case moment()
        .subtract(1, 'day')
        .format('MM/DD/YYYY'):
        return 'Yesterday ';
    case moment()
        .add(1, 'day')
        .format('MM/DD/YYYY'):
        return 'Tomorrow ';
    default:
        return dayFinder(moment(day).day(), metaInfo);
    }
};

export const printDay = (day) => {
    if (!isWithinAWeek(moment(day))) {
        // const FORMAT = 'MMM  ddd YYYY  DD HH:mm';

        return getDay(day, moment(day).format('LL'));
    }
    return getDay(day);
};

export const getConversationIdFromUrl = () => {
    const url = process.env.WEB ? window.location.href.replace(/\/$/, '') : '';
    const URLArray = url.split('/');
    let indexCIDParam;
    URLArray.forEach((s, i) => {
        if (s === 'u') indexCIDParam = i + 1;
    });
    let idParam;
    if (indexCIDParam !== undefined) {
        idParam = URLArray[indexCIDParam];
    }
    if (idParam && idParam.includes('?')) {
        const indexOfQuery = idParam.indexOf('?');
        idParam = idParam.slice(0, indexOfQuery);
    }
    return idParam;
};

export const autocompleteUsersFromConversations = (query, user = null) => (conversationsArray) => {
    query = query.trim();
    const parsedConversations = conversationsArray
        .map(c => ({
            ...c,
            participants: c.participants.filter(({ id }) => !user || id !== user.uid),
        }))
        .filter(c => !c.newConversation)
        .filter((c) => {
            if (query.match(/[^\w\s@._-]+/)) {
                return false;
            }

            const regexQuery = Object.is(NaN, Number(query)) ? new RegExp(`^${query}`, 'i') : query;

            // const regexQuery = query
            const isMatched =
                (c.type === 'group' && c.name.match(regexQuery))
                || Object.values(c.participants).some((p) => {
                    // eslint-disable-next-line no-undef
                    const result = String(p.displayContact)?.match(regexQuery) || String(p.email)?.match(regexQuery) || String(p.phone)?.match(regexQuery) || String(p.name)?.match(regexQuery);
                    return !!result;
                });
            return isMatched;
        })
        .sort(({ lastUpdatedAt: a }, { lastUpdatedAt: b }) => b - a);
    return _.uniq(parsedConversations, 'id');
};

export const constructNewConversation = (override = {}) => ({
    type: 'single',
    participants: [],
    messages: [],
    name: '',
    id: cuid(),
    eventId: undefined,
    lastUpdatedAt: moment().valueOf(),
    newConversation: true,
    paginationCount: 0,

    ...override,
});

// group buffered messages by conversation id, filter by existing conversation
export const groupAndFilterMessagesByConversation = (messages, existedConversations) => _.chain(messages)
    .map(({ newState: { message, id } }) => ({ id, message }))
    .reduce((acc, { id, message }) => {
        if (!existedConversations.has(id)) return acc;

        if (!acc[id]) {
            acc[id] = [];
        }

        acc[id].push(message);

        return acc;
    }, {})
    .value();
export const concatMap = (array) => {
    const results = [];
    array.forEach((subArray) => {
        /* eslint-disable */
        results.push.apply(results, subArray);
        /* eslint-disable */
    });
    return results;
};
// export const queryPredicate = (p, query) =>  (p.email && p.email.slice(0, query.length) === query) || (p.displayContact && p.displayContact.slice(0, query.length) === query) || (p.phone && String(p.phone).slice(0, query.length) === query) || (p.name && p.name.slice(0, query.length) === query)

export const queryPredicate = (p, query) => {
    const escapedFilter = query.trim().replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
    return RegExp(`\\b(?=\\w)${escapedFilter.trim().replace(' ', '(((-| ).*( |-))| |@)')}`, 'i')
        .test(`${p.name} ${p.email} ${p.phone} ${p.displayContact}`);
}

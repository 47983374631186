import { oneForOne, superviseEpics } from '@mixer/epic-supervisor';
import { Observable } from 'rxjs';
import Fingerprint2 from 'fingerprintjs2';
import Hex from 'crypto-js/enc-hex';
import MD5 from 'crypto-js/md5';
import SHA1 from 'crypto-js/sha1';
import tinycolor from 'tinycolor2';
import moment from 'moment';
import { firebase } from './app.cross-platform';
import { currentUser } from './auth/auth.firebase';

export * from './auth/auth.helpers';
export * from './messenger/messenger.helpers';
// eslint-disable-next-line
export const getCurrentURLQueryString = () => location
    .search.replace('?', '&').split('&')
    .filter(query => query.split('=').length > 1)
    .map(query => decodeURIComponent(query))
    .reduce((qs, query) => {
        const [key, value] = query.split('=');
        qs[key.toLowerCase()] = value;
        return qs;
    }, {});

export const getQueryStringFromURL = url => url
    .replace('?', '&').split('&')
    .filter(query => query.split('=').length > 1)
    .map(query => decodeURIComponent(query))
    .reduce((qs, query) => {
        const [key, value] = query.split('=');
        qs[key.toLowerCase()] = value;
        return qs;
    }, {});

export const DEFAULT_PAGE_TITLE = process.env.WEB ? document.title : '';

export const updatePageTitle = (text) => {
    document.title = text || DEFAULT_PAGE_TITLE;
};

export const getUniqueDeviceFingerprint = () => {
    return new Promise((resolve) => {
        new Fingerprint2({
            excludeWebGL: true,
        }).get(() => {
            resolve();
        });
    });
};

export const elementIdScrollToBottom = (elementId) => {
    if (!process.env.WEB) {
        return true;
    }

    const element = document.getElementById(elementId);
    return window.requestAnimationFrame(() => {
        if (element !== undefined) {
            element.scrollTop = element.scrollHeight;
        }
    });
};

export const objectEntriesForKey = (obj, key) =>
    Object.entries(obj)
        .map(([uid, contents]) => {
            let val;
            if (contents instanceof Object && contents[key]) val = contents[key];
            else if (typeof contents === 'string') val = contents;
            else return false;
            return [uid, val];
        })
        .filter(Boolean);

export const formatPhoneNumber = (phone = '') => {
    const formattedNumber = phone.toString().replace(/\D/g, '');
    const match = formattedNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    return (!match) ? null : `(${match[2]}) ${match[3]}-${match[4]}`;
};

export const getColorFromString = (str) => {
    if (str === 'Undefined') {
        return {
            backgroundColor: 'transparent',
            color: 'transparent',
        };
    }

    const color = MD5(str).toString(Hex)
        .slice(0, 6);

    return {
        backgroundColor: `#${color}`,
        color: tinycolor(color).isDark ? '#fff' : '#000',
    };
};

export const pullConversationIdFromURI = () => window.location.pathname.split('/')[2];

export const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const isAndroid = /(android)/i.test(navigator.userAgent);

export const isMac = process.env.WEB ? !!navigator.platform.match(/(Mac)/i) : false;

export const platform = {
    get type() {
        if (process.env.ELECTRON) {
            return 'desktop';
        }

        if (process.env.WEB) {
            return 'web';
        }

        return 'native';
    },
};

export const acceptUntilTimeout = ((fn, timeout) => {
    let timeoutFn;

    (function accept() {
        clearTimeout(timeoutFn);

        timeoutFn = setTimeout(() => fn, timeout);
    }());
});

export const tryJSONParse = (data) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return false;
    }
};

export const getFirstNonKeyObject = (object, key, value) => {
    if (object[0][key] === value) {
        return object[1];
    }

    return object[0];
};

export const generateAuthTokens = async () => (
    {
        auth_uid: currentUser().uid,
        auth_token: await firebase.auth().currentUser.getIdToken(true),
    }
);

export const hashValue = (data = '') => SHA1(data).toString();

export const md5 = (data = '') => MD5(data).toString();

export const addKeyFromRefToObject = (ref, transform = () => {}) => ({
    ...transform(ref.val()),
    id: ref.key,
});

export const getCurrentTime = () => moment().valueOf();

export const getGravitar = (email) => {
    return `https://www.gravatar.com/avatar/${md5(email.toLowerCase())}`;
};

export const translateErrorCode = (code) => {
    const errorCodes = {
        'invalid-code': 'You have provided a wrong code, please try again!',
        'user-already-associated-with-this-number': 'Sorry! It seems that you have already connected your phone number to this account, please hit the exit button above.',
        'invalid-phone-number': 'The number provided is a not a valid number, please try again!',
        'invalid-password/too-short': 'The password provided is too short',
        'invalid-password/default': 'Invalid password provided, please try something else',
        'invalid-forgot-password-token': 'It seems like there has been an issue with resetting your password, please' +
        ' log out and try asking for a reset again!',
        'user-already-exists': 'Account with this identified already exists',
    };

    return errorCodes[code] || 'Please try again in a few minutes. If the error persists, please reach out to us at support@pandamessage.com.';
};

export const shortenString = (str = '') => {
    if (str.length > 35) {
        return `${str.substr(0, 20)}...${str.substr(str.length - 10, str.length)}`;
    }

    return str;
};

export const decodeEqual = str => str.replace(/\$eq\$/g, '=').replace(/\$an\$/g, '&');

const options = {
    restart: oneForOne,
    onError: ({ epicName, error }, actions, state, services) => {
        // Send another action when the error occurs:
        console.error(`An error occurred in epic ${epicName}`, error);
        // Wait a second before restarting epics:
        return Observable.of('RESTARTED');
    },
};


const combineEpics = (...epics) => {
    return superviseEpics(options, ...epics);
};

export const customCombineEpics = combineEpics;

import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const MagicAsync = lazy(() => import('./magic').then(module => ({ default: module.Magic })));
const MagicComponentAsync = lazy(() => import('./magic').then(module => ({ default: module.MagicComponent })));

export const Magic = () => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <MagicAsync />
        </Suspense>
    );
};

export const MagicComponent = (props) => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <MagicComponentAsync {...props} />
        </Suspense>
    );
};

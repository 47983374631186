import axios from 'axios';
import { Observable } from 'rxjs';
import { generateAuthTokens } from '../app.helpers';
import { serverInfo } from '../app.constants';
import { Sentry } from '../app.cross-platform';

export const sendMagicTokenEmail = async (email, options) => {
    const data = { email, options };


    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/validateEmail`,
        data,
    });
};

export const sendMagicTokenEmailV2 = async (identifier, options) => {
    const data = {
        identifier,
        options,
        // TODO: on android we can't create new user without this code, request specification for this case
        code: !process.env.WEB ? 'black-SMS' : undefined,
    };


    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/validateEmail/v2`,
        data,
    });
};

export const sendMagicTokenText = async phone => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/validatePhoneNumber`,
    data: {
        phone,
    },
});

export const sendMagicTokenTextV2 = async (identifier, options) => {
    const data = { identifier, options };

    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/validatePhoneNumber/v2`,
        data,
    });
};

export const loginWithPassword = async ({ email, password }) => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/authorize`,
    data: {
        email,
        password,
    },
});

export const loginWithType = async (type, data) => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/login/${type}`,
    data,
});

export const loginWithTypeV2 = async (type, data) => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/login/${type}/v2`,
    data,
});

export const preauth = async identifier => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/preauth`,
    data: { identifier },
});

export const preMerge = async identifier => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/preMergeAccount`,
    data: { identifier },
});

export const createNewUser = async ({
    uid,
    email = false,
    phone = false,
    name = '',
}) => {
    let cleanPhone = phone;
    if (cleanPhone) {
        cleanPhone = phone.replace(/\D/g, '');
        if (cleanPhone.length === 10) {
            cleanPhone = `1${cleanPhone}`;
        }
    }
    axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/createNewUser`,
        data: {
            email,
            phone: cleanPhone,
            uid,
            data: {
                name,
            },
            ...await generateAuthTokens(),
        },
    });
};

export const pushContactsToken = async (uid, code, type, subtype) => {
    const redirectUri = type === 'outlook' ? process.env.OUTLOOK_REDIRECT_URL : undefined;

    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/updateContacts`,
        data: {
            uid,
            code,
            type,
            subtype, // 'contacts' or 'calendar'
            redirectUri,
            ...await generateAuthTokens(),
        },
    });
};

export const updateContacts = async (uid, userContacts) => {
    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/updateContacts`,
        data: {
            uid,
            contacts: {
                data: userContacts,
            },
            type: 'manual',
            ...await generateAuthTokens(),
        },
    });
};

export const mergeAccount = async (uid, email, phone, token, code) => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/mergeAccount`,
            data: {
                uid,
                email,
                phone,
                code,
                magic_token: token,
                ...await generateAuthTokens(),
            },
        });
    } catch (error) {
        Sentry.captureException(error);
        return Observable.error(error);
    }
};

export const verifyOneTimeLoginKey = async (uid, key) => {
    try {
        return axios({
            method: 'post',
            url: `${await serverInfo.getRoot()}/validateOneTimeLoginKey`,
            data: {
                uid,
                key,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

export const changePassword = async (oldPassword, newPassword) => {
    return axios({
        method: 'post',
        url: `${await serverInfo.getRoot()}/changePassword`,
        data: {
            oldPassword,
            newPassword,
            ...await generateAuthTokens(),
        },
    });
};

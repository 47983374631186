import { Observable } from 'rxjs';
import _ from 'underscore';
import cuid from 'cuid';
import moment from 'moment';
import { firebase, database } from '../app.cross-platform';
import { createNewUser } from '../auth/auth.services';
import { addKeyFromRefToObject, formatPhoneNumber, objectEntriesForKey } from '../app.helpers';

import { generateMessage, transformMessages, parseEmail } from './messenger.helpers';

const rootRef = database.ref();
const usersRef = rootRef.child('users');
const contactsRef = rootRef.child('contacts');
const eventsRef = rootRef.child('events');
const identifierMapRef = rootRef.child('identifierMap');
const conversationsRef = rootRef.child('conversations');

const getUserIdentifier = ({ managedContact, phone, email }) => managedContact || (email ? parseEmail(email) : phone);

export const generateParticipantIDList = (participants = []) =>
    _.object(
        participants.map(({ id }) => id),
        participants.map(getUserIdentifier),
    );

export const generateConversation = ({
    type, intro, tags, participants, groupImageURL, name,
}) => {
    const currentUserUid = firebase.auth().currentUser.uid;
    const members = generateParticipantIDList(participants);

    return {
        info: {
            members,
            tags,
            intro,
            type,
            ...(type === 'group'
                ? {
                    admins: {
                        [currentUserUid]: members[currentUserUid],
                    },
                    groupImageURL,
                }
                : {
                    admins: members,
                }),
            ...(name ? { name } : {}),
        },
        messages: {},
    };
};

export const createNewUserForMessenger = async (uid, email, phone, name = '') => {
    if (!email) {
        return createNewUser({ uid, phone, name });
    }

    return createNewUser({ uid, email, name });
};

export const addParticipantsToGroup = async (participants, key, userConvoInfo) =>
    Promise.all(participants.map(async ({
        email: userEmail, name, phone, id, newUser,
    }) => {
        if (newUser) {
            await createNewUserForMessenger(id, userEmail, phone, name);
        }

        return usersRef.child(`${id}/conversations/${key}`).set(userConvoInfo);
    }));

const tempUserMap = {};

// eslint-disable-next-line
export const _populateParticipantsFromIds = participantEntries =>
    Promise.all(participantEntries
        .filter(([uid]) => !!uid && uid !== 'undefined')
        .map(async ([uid, displayContact]) => {
            if (tempUserMap[uid]) {
                return tempUserMap[uid];
            }

            const participantRef = await usersRef.child(uid).child('credentials')
                .once('value');

            if (!participantRef.exists()) {
                return { badUser: true };
            }

            const participant = {
                id: uid,
                ...participantRef.val(),
                displayContact,
            };

            tempUserMap[participant.id] = participant;

            return participant;
        }));

export const transformConversationInternal = async (messageConversationObject) => {
    if (!messageConversationObject) {
        return {};
    }

    if (!messageConversationObject.info) {
        return [
            messageConversationObject.id,
            {
                ...messageConversationObject,
            },
        ];
    }

    const {
        info: {
            members = {}, removedMembers = {}, name, group, location: id, groupImageURL, type = group ? 'group' : 'single', admins, ...convoData
        },
        messages = {},
    } = messageConversationObject;

    const participantIdTuple = [...Object.entries(members), ...objectEntriesForKey(removedMembers, 'email')] || [];

    const participants = await _populateParticipantsFromIds(participantIdTuple);

    const nextConversation = {
        id,
        participants,
        removedMembers,
        type,
        paginationCount: 0,
        messages: messages ? transformMessages(messages) : [],
        ...convoData,
    };

    if (type === 'single') {
        return [id, nextConversation];
    }

    return [
        id,
        {
            ...nextConversation,
            name,
            groupImageURL,
            admins,
        },
    ];
};

export const transformEventInternal = async (event) => {
    if (!event) {
        return {};
    }

    const {
        id, existedUsers, uninvitedUsers = [], ...eventInfo
    } = event;

    const participantIdTuple = (existedUsers && Object.entries(existedUsers)) || [];

    const participants = await _populateParticipantsFromIds(participantIdTuple);
    participants.push(...uninvitedUsers.map(email => ({ email })));

    const nextConversation = {
        ...eventInfo,
        id,
        participants,
        type: participants.length > 1 ? 'group' : 'single',
        isEvent: true,
    };

    return [id, nextConversation];
};

const resolveEvent = async (eventId) => {
    const { existedUsers = {} } = (await eventsRef.child(eventId).once('value')).val();

    return Promise.all([await eventsRef.child(`${eventId}/resolved`).set(true), ..._.keys(existedUsers).map(async uid => usersRef.child(`${uid}/events/${eventId}`).remove())]);
};
// TODO:BUG here
export const createNewConversation = (conversation, message = {}) =>
    Observable.defer(async () => {
        const { displayName, email } = firebase.auth().currentUser;
        const {
            type = 'single', participants, groupImageURL = '', name = '', tags = {}, intro = false, id: cid, eventId,
        } = conversation;

        const updatedConversation = generateConversation({
            type,
            intro,
            tags,
            participants,
            groupImageURL,
            name,
        });

        // if conversation was created from event then we should resolve event
        if (eventId) {
            await resolveEvent(eventId);
        }
        const {
            fileType = 'text', content = `${displayName || email} created a group.`, tags: messageTags = [], meta = {},
        } = message;
        // eslint-disable-next-line
        const updatedMessage = { cid, data: generateMessage(fileType, content, messageTags, meta, cid) };

        const userConvoInfo = {
            lastUpdatedAt: moment().valueOf(), // TODO: Use server value
            mute: false,
            location: cid,
            group: type === 'group',
        };

        // create new users
        await Promise.all(participants
            .filter(({ newUser }) => newUser)
            .map((participant, index) => {
                const {
                    id, email, phone, name,
                } = participant;
                delete participant.newUser;
                return createNewUserForMessenger(id, email, phone, name);
            }));

        // create conversation
        await conversationsRef.child(cid).set(updatedConversation);

        // assign conversation to users
        if (type === 'group') {
            await addParticipantsToGroup(participants, cid, userConvoInfo);
        } else {
            await usersRef.update({
                [`${participants[0].id}/conversations/${participants[1].id}`]: userConvoInfo,
                [`${participants[1].id}/conversations/${participants[0].id}`]: userConvoInfo,
            });
        }

        const finalConversation = await transformConversationInternal({
            id: cid,
            ...updatedConversation,
            info: {
                ...updatedConversation.info,
                ...userConvoInfo,
            },
        });

        return {
            updatedMessage,
            cid,
            conversation: finalConversation,
        };
    });

export const populateParticipantsData = ({
    id, name, email, profileImageURL, ...rest
}) => ({
    id,
    name: name || '',
    email: email.toLowerCase(),
    profileImageURL: profileImageURL || '',
    ...rest,
});

/* DEPRECATED
export const populateParticipants = async participants => Promise
    .all(participants
        .filter(({ email }) => !!email)
        .map(async ({ email }) => {
            const participant = (await usersRef.orderByChild('credentials/email').equalTo(email)
                .once('value')).val();

            if (participant) {
                const key = Object.keys(participant)[0];

                return populateParticipantsData({
                    id: key,
                    ...participant[key].credentials,
                });
            }

            return populateParticipantsData({
                email,
            });
        })); */

export const populateParticipantsFromIds = (participantEntries) => {
    return Observable.defer(async () => _populateParticipantsFromIds(participantEntries));
};

export const getParticipantIdFromEmailOrPhone = ({ email, phone }) => Observable.defer(async () => (await identifierMapRef.child(window.btoa(phone || email.toLowerCase())).once('value')).val());

export const generateEmptyUser = ({ email, name, phone }) => ({
    id: cuid(),
    newUser: true,
    email,
    name,
    phone,
});

export const getParticipantsWithoutCurrentUser = participants => participants.filter(({ id }) => id !== firebase.auth().currentUser.uid);

/* DEPRECATED
export const searchParticipantByEmailOrPhone = async ({ email, phone }) => {
    let userObj = null;

    if (email) {
        userObj = (await usersRef
            .orderByChild('credentials/email')
            .equalTo(email)
            .once('value'))
            .val();
    } else {
        userObj = (await usersRef
            .orderByChild('credentials/phone')
            .equalTo(phone)
            .once('value'))
            .val();
    }

    if (!_.size(userObj)) {
        return null;
    }


    const [[id, { credentials }]] = Object.entries(userObj);

    return { id, ...credentials };
}; */

export const getUserByIncompleteObject = async (incompleteUserObject) => {
    const {
        email, phone, name, uid,
    } = incompleteUserObject;

    let identifier;
    if (email) {
        identifier = email.toLowerCase();
    }
    // eslint-disable-next-line
    const emailRefHash = btoa(identifier || phone);
    const userId = uid || (await identifierMapRef.child(emailRefHash).once('value')).val();
    let user = null;

    // searchParticipant deprecated
    // user = await searchParticipantByEmailOrPhone(incompleteUserObject);

    if (userId) {
        user = addKeyFromRefToObject(await usersRef.child(userId).once('value'), ({ credentials }) => credentials);
    }

    // we cannot find a user, generate a empty user
    if (!user) {
        return generateEmptyUser(incompleteUserObject);
    }

    // go to account manager
    if (user.managed && user.manager !== userId) {
        return {
            ...(await getUserByIncompleteObject({ uid: user.manager })),
            managedName: name || parseEmail(email) || formatPhoneNumber(phone), // we want to store what the user entered as to not confuse context
            managedContact: email || phone,
        };
    }

    return user;
};

export const populateParticipantsFromIncompleteObjects = participants => Observable.defer(async () => Promise.all(participants.map(getUserByIncompleteObject)));

export const addOrCreateParticipantsToGroupConvo = (participants, { id, lastUpdatedAt, participants: currentParticipants }) =>
    Observable.defer(async () => {
        const fullParticipants = (await Promise.all(participants.map(getUserByIncompleteObject))).filter(({ id: uid }) => !currentParticipants.find(({ id }) => id === uid));

        const userConvoInfo = {
            lastUpdatedAt,
            mute: false,
            location: id,
            group: true,
        };

        await addParticipantsToGroup(fullParticipants, id, userConvoInfo);

        return fullParticipants;
    });

// listeners
export const listenToUser = uid => Observable.fromEvent(usersRef.child(uid), 'value');


export const listenToUserConversations = uid => Observable.merge(
    Observable.create(observer =>
        usersRef.child(`${uid}/conversations`).orderByChild('lastUpdatedAt')
            .limitToLast(4)
            .on('child_added', (snap) => {
                return observer.next(snap);
            })),
    Observable.fromEvent(usersRef.child(`${uid}/conversations`), 'child_changed'),
).map(snap => ({ [snap.key]: snap.val() }));

export const listenToUserConversationsRemove = uid => Observable.fromEvent(usersRef.child(`${uid}/conversations`), 'child_removed').map(snap => ({ messages: snap.val(), id: snap.key }));

export const listenToUserEvents = uid =>
    Observable.fromEvent(usersRef.child(`${uid}/events`), 'value').map((snap) => {
        return snap.val() || {};
    });

export const listenToUserEventsRemoved = uid => Observable.fromEvent(usersRef.child(`${uid}/events`), 'child_removed').map(snap => ({ id: snap.key }));

export const listenToEvent = eventId =>
    Observable.fromEvent(eventsRef.child(eventId), 'value')
        .filter((snap) => {
            const event = snap.val();
            return event && !event.resolved;
        })
        .map(snap => ({ ...snap.val(), id: eventId }));

export const listenToConversationInfo = conversation => Observable.fromEvent(conversationsRef.child(`${conversation.location}/info`), 'value').map(snap => ({ info: { ...conversation, ...snap.val() }, id: conversation.location }));

export const listenToConversationMessages = (conversation, uid) => {
    const loadMessagesCount = Math.floor((window.screen.availHeight || 1200) / 30);
    let query = conversationsRef
        .child(`${conversation.location}/messages`)
        .orderByKey()
        .limitToLast(loadMessagesCount);
    try {
        const endAt = conversation.info.removedMembers[uid].lastMessage;
        query = query.endAt(endAt);
    } catch (e) {
        // empty
    }
    return Observable.fromEvent(query, 'value').map(snap => ({ messages: snap.val(), ...conversation }));
};

export const listenToConversation = (conversation, uid) =>
    listenToConversationInfo(conversation).mergeMap((conversationWithInfo) => {
        return listenToConversationMessages({ ...conversation, ...conversationWithInfo }, uid);
    });

export const listenToConversations = conversations => Observable.merge(...conversations);

export const listenToEvents = events => Observable.merge(...events);

// setters
export const muteConversation = (conversationId, uid) => Observable.fromPromise(usersRef.child(`${uid}/conversations/${conversationId}/mute`).set(true));

export const unmuteConversation = (conversationId, uid) => Observable.fromPromise(usersRef.child(`${uid}/conversations/${conversationId}/mute`).set(false));

export const updateGroupPhoto = (currentConversationId, participants, groupImageURL) => Observable.fromPromise(conversationsRef.child(`${currentConversationId}/info/groupImageURL`).set(groupImageURL));

export const updateGroupTitle = (currentConversationId, participants, name) => Observable.fromPromise(conversationsRef.child(`${currentConversationId}/info/name`).set(name));

export const addGroupParticipants = (currentConversationId, participants) => Observable.fromPromise(Promise.all(participants.map(({ id, email, phone }) => conversationsRef.child(`${currentConversationId}/info/members/${id}/`).set(email ? parseEmail(email) : phone))));

export const addParticipantToConversation = (cid, {
    id, email, phone, managedContact,
}) =>
    Observable.fromPromise(rootRef.update({
        [`conversations/${cid}/info/members/${id}`]: managedContact || (email ? parseEmail(email) : phone),
        [`conversations/${cid}/info/removedMembers/${id}`]: null,
    }));

export const removeParticipantFromConversation = (cid, { id, email }) =>
    Observable.fromPromise(rootRef.update({
        [`users/${id}/conversations/${cid}`]: null,
        [`conversations/${cid}/info/members/${id}`]: null,
        [`conversations/${cid}/info/removedMembers/${id}`]: { email },
    }));

export const appendTagInMessage = (currentConversationId, messageId, tagId) => Observable.fromPromise(conversationsRef.child(`${currentConversationId}/messages/${messageId}/tags/${tagId}`).set({ inline: false }));

export const updateTags = (currentConversationId, tags) => Observable.fromPromise(conversationsRef.child(`${currentConversationId}/info/tags`).set(tags));

// eslint-disable-next-line object-curly-newline
export const addReaction = ({ currentConversationId, uid, messageId, code }) =>
    Observable.fromPromise((async () => {
        const reactionsRef = conversationsRef.child(`${currentConversationId}/messages/${messageId}/reactions/${uid}/`);
        const previousReaction = (await reactionsRef.once('value')).val();

        const valueToSet = (() => {
            if (Array.isArray(previousReaction)) {
                if (previousReaction.find(r => r.reaction === code)) return previousReaction;
                return previousReaction.concat({
                    id: uid,
                    reaction: code,
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                });
            } else if (previousReaction) {
                if (previousReaction.reaction === code) return previousReaction;
                return [
                    previousReaction,
                    {
                        id: uid,
                        reaction: code,
                        timestamp: firebase.database.ServerValue.TIMESTAMP,
                    },
                ];
            }

            return {
                id: uid,
                reaction: code,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
            };
        })();

        return reactionsRef.set(valueToSet);
    })());

// eslint-disable-next-line object-curly-newline
export const removeReaction = ({ currentConversationId, uid, messageId, code }) =>
    Observable.fromPromise((async () => {
        const reactionsRef = conversationsRef.child(`${currentConversationId}/messages/${messageId}/reactions/${uid}/`);
        const reactionsVal = (await reactionsRef.once('value')).val();

        if (Array.isArray(reactionsVal)) return reactionsRef.set(reactionsVal.filter(r => r.reaction !== code));

        return reactionsRef.remove();
    })());

export const sendMessageToConversation = async (conversationId, { id: tempID, ...message }) => {
    await conversationsRef.child(`${conversationId}/messages/${tempID}`).set({
        ...message,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        id: tempID,
    });

    return {
        ...message,
        temp: !tempID,
    };
};

export const generateNextMessageId = conversationId => conversationsRef.child(`${conversationId}/messages`).push().key;

export const unsubscribeFromMessageId = (conversationId) => {
    conversationsRef.child(`${conversationId}/info`).off();
    conversationsRef.child(`${conversationId}/messages`).off();
};

export const sendMessage = groupedMessages => Observable.defer(() => Promise.all(Object.keys(groupedMessages).map(cid => Promise.all(groupedMessages[cid].map(message => sendMessageToConversation(cid, message))))));

export const updateMessageFileUpload = (message, conversationId, filePromise) => {
    if (!filePromise) return;
    filePromise.then(({ downloadURL }) => {
        const updateData = {
            downloadURL,
        };
        conversationsRef
            .child(`${conversationId}/messages/${message.id}/content`)
            .update(updateData)
            .catch((error) => {
                console.log(error);
            });
    });
};

// getters
export const getDataFromReference = ref => Observable.fromPromise(ref.once('value'));

// eslint-disable-next-line
export const _getDataFromReference = ref => ref.once("value");

const getLatestMessagesPromise = cid =>
    conversationsRef
        .child(`${cid}/messages`)
        .orderByKey()
        .limitToLast(5)
        .once('value');

const getLoadForwardMessagePromise = (cid, lastMessage) =>
    conversationsRef
        .child(`${cid}/messages`)
        .orderByKey()
        .startAt(lastMessage.id)
        .limitToFirst(30)
        .once('value');

const getLoadBackwardMessagePromise = (cid, lastMessage) =>
    conversationsRef
        .child(`${cid}/messages`)
        .orderByKey()
        .endAt(lastMessage.id)
        .limitToLast(30)
        .once('value');

/**
 * Paginates messages
 * @param cid
 * @param lastMessage
 * @param forwards - Paginate to newer messages
 * @param both - Paginate to both older and newer messages
 */
export const loadMoreMessages = (cid, lastMessage, { forwards, both }) => {
    const promises = [];
    if (!Object.keys(lastMessage).length) {
        promises.push(getLatestMessagesPromise(cid));
    } else {
        if (both || forwards) {
            promises.push(getLoadForwardMessagePromise(cid, lastMessage));
        }

        if (both || !forwards) {
            promises.push(getLoadBackwardMessagePromise(cid, lastMessage));
        }
    }

    return Observable.defer(async () => Promise.all(promises))
        .map(data => data.map(messagesRef => _.omit(messagesRef.val(), lastMessage.id)))
        .map(([firstLoadedMessages = {}, secondLoadedMessages = {}]) => ({ ...firstLoadedMessages, ...secondLoadedMessages }));
};

export const updateMessageAsRead = (currentConversationId, messages, uid) =>
    Observable.fromPromise(Promise.all(messages.map(async (messageId) => {
        if (!messageId || messageId === 'new') {
            return false;
        }

        const messageRef = conversationsRef.child(`${currentConversationId}/messages/${messageId}`);

        try {
            if ((await messageRef.once('value')).val()) {
                return messageRef.update({
                    isRead: true,
                    [`read/${uid}`]: firebase.database.ServerValue.TIMESTAMP,
                });
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    })));

export const subscribeToContactListUpdates = uid => Observable.fromEvent(contactsRef.child(uid), 'value').map(snap => _.values(snap.val()));

export const addNewContacts = (uid, contactsToAdd) =>
    Observable.defer(async () => {
        const newContactsData = {};
        contactsToAdd.forEach((contact) => {
            newContactsData[contactsRef.push().key] = contact;
        });

        return contactsRef.child(uid).update(newContactsData);
    });

export const getUserOnline = (uid, cb) => {
    database.ref(`users/${uid}/meta/online`).on('value', cb);
};

export const getUserLastOnline = (uid, cb) => {
    database.ref(`users/${uid}/meta/lastOnline`).on('value', cb);
};

const func = () => {
    console.log('process', process.env);
    if (process.env.ELECTRON) {
        // eslint-disable-next-line
        // return require('electron');
    }
    return {};
};

export default func;

import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';
import * as methods from '../app.constants';
import * as actions from '../app.actions';
import { sendSignInTextMessage } from '../messenger/messenger.services';
import { customCombineEpics, translateErrorCode } from '../app.helpers';
import * as db from '../app.firebase';
import { checkIfValidEmail, checkIfValidPhoneNumber, standardizeNumber } from '../messenger/messenger.helpers';
import * as services from './modal.services';
import { markOnboardingComplete } from '../auth/auth.cache';

/**
 * Decrypt Message Modal
 */
export const decryptMessage = (action$, { getState }) =>
    action$.ofType(methods.DECRYPT_MESSAGE)
        .map(() => {
            const {
                isDecryptMessageModalVisible: { content, id },
                decryptPassword,
            } = getState().modal;

            const bytes = AES.decrypt(content, decryptPassword);
            const decryptedData = bytes.toString(enc);

            if (decryptedData) {
                return actions.DecryptMessageSuccessAction({ id, content: decryptedData });
            }

            return actions.DecryptMessageFailAction('Decryption failed, invalid password!');
        });

export const decryptMessageSuccess = (action$, { dispatch }) =>
    action$.ofType(methods.DECRYPT_MESSAGE_SUCCESS)
        .do(() => dispatch(actions.CloseDecryptMessageModalAction()))
        .map(({ newState }) => actions.AppendDecryptedMessageObjectAction(newState));


/**
 * Send text to sign in modal
 */
export const sendSignInText = (action$, { getState }) =>
    action$.ofType(methods.SEND_SIGN_IN_TEXT)
        .switchMap(({ newState: sendTextPhoneNumber }) => {
            const { credentials: { email } } = getState().auth.user;
            return Observable.defer(() => sendSignInTextMessage(sendTextPhoneNumber, email, methods.APP_STORE_LINK, methods.PLAY_STORE_LINK))
                .map(() => actions.SendSignInTextSuccessAction())
                .catch(() => Observable.of(actions.SendSignInTextFailAction()));
        });

/**
 * Manage Participants Modal
 */
export const removeParticipantFromGroup = (action$, { getState }) =>
    action$.ofType(methods.REMOVE_PARTICIPANT_FROM_GROUP)
        .switchMap(({ newState: uid }) => {
            const { id: currentConversationId, participants } = getState().messenger.currentConversation;
            const participant = participants.find(({ id }) => id === uid);
            return db.removeParticipantFromConversation(currentConversationId, participant)
                .map(() => actions.RemoveParticipantFromGroupSuccessAction({ id: currentConversationId, participantId: uid }))
                .catch(() => Observable.of(actions.RemoveParticipantFromGroupFailAction()));
        });

export const addParticipantToGroup = (action$, { getState, dispatch }) =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_GROUP)
        .switchMap(({
            newState: {
                email,
                phone,
                unknown,
                name,
            },
        }) => {
            const {
                currentConversation,
            } = getState().messenger;

            const newParticipant = (() => {
                if (checkIfValidEmail(email || unknown)) {
                    return { email: email || unknown, name };
                }

                if (checkIfValidPhoneNumber(phone || unknown)) {
                    return { phone: standardizeNumber(phone || unknown), name };
                }

                return false;
            })();

            if (!newParticipant) {
                return Observable.of(actions.AddParticipantToGroupFailAction());
            }

            return db.addOrCreateParticipantsToGroupConvo([newParticipant], currentConversation)
                .do(() => dispatch(actions.UpdateNextUserToAddValueAction('')))
                .switchMap(([participant] = []) => {
                    if (participant) {
                        return db.addParticipantToConversation(currentConversation.id, participant)
                            .map(() => actions
                                .AddParticipantToGroupSuccessAction({ id: currentConversation.id, participant }))
                            .catch(() => Observable.of(actions.AddParticipantToGroupFailAction()));
                    }

                    return Observable.of(actions.AddParticipantToGroupFailAction());
                })
                .catch(() => Observable.of(actions.AddParticipantToGroupFailAction()));
        });

export const removeOnboardingStatusOnUser = (action$, { getState }) =>
    action$.ofType(methods.CLOSE_ONBOARDING_MODAL)
        .switchMap(() => db
            .setUserOnboardingStatusAsTrue(getState().auth.user.uid)
            .do(() => markOnboardingComplete())
            .map(() => actions.DummyAction()));

export const addParticipantFromGroupToReducer = action$ =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_GROUP_SUCCESS)
        .map(({ newState: { id, participant } }) => {
            return actions.UpdateCurrentConversationParticipantsSuccessAction({
                id,
                participants: [participant],
                type: 'add',
            });
        });

export const addParticipantToGroupFail = action$ =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_GROUP_FAIL)
        .filter(() => !process.env.WEB)
        .do(() => {
            // eslint-disable-next-line global-require
            const { ToastAndroid } = require('react-native');
            ToastAndroid.show('User is invalid', ToastAndroid.SHORT);
        })
        .filter(() => false);

export const sendVerificationText = action$ =>
    action$.ofType(methods.SEND_VERIFICATION_TEXT)
        .switchMap(({ newState: { phone, isOnboarding = false } }) => Observable
            .defer(async () => services.sendVerificationText(phone, isOnboarding))
            .map(() => actions.SendVerificationTextSuccessAction(phone))
            .catch((e) => {
                let err = e;

                if (err.response && err.response.data) {
                    err = err.response.data.error;
                }

                console.log(err);

                return Observable
                    .of(actions.SendVerificationTextFailAction(translateErrorCode(err)));
            }));

export const sendVerificationTextV2 = action$ =>
    action$.ofType(methods.SEND_VERIFICATION_TEXT_V2)
        .switchMap(({ newState: { identifier } }) => Observable
            .defer(() => services.sendVerificationTextV2(identifier))
            .map(({ data: { phone, uid } }) => actions.SendVerificationTextSuccessAction({ phone, uid, isOnboarding: !uid }))
            .catch((e) => {
                let err = e;

                if (err.response && err.response.data) {
                    err = err.response.data.error;
                }

                console.log(err);

                return Observable
                    .of(actions.SendVerificationTextFailAction(translateErrorCode(err)));
            }));

export const verifyVerificationCode = action$ =>
    action$.ofType(methods.VERIFY_VERIFICATION_CODE)
        .switchMap(({ newState: code }) => Observable
            .defer(() => services.verifyVerificationCode(code))
            .map(() => actions.VerifyVerificationCodeSuccessAction())
            .catch(({ response: { data: { error } } = { data: {} } }) => Observable
                .of(actions.VerifyVerificationCodeFailAction(translateErrorCode(error)))));

export const addPassword = (action$, { getState }) =>
    action$.ofType(methods.ADD_PASSWORD_TO_USER_ACCOUNT)
        .switchMap(({ newState: { password } }) => Observable
            .defer(() => services.addPasswordToAccount(password, getState().modal.isForgotPasswordMode))
            .map(() => actions.AddPasswordToUserAccountSuccessAction())
            .catch(({ response: { data: { error } } = { data: {} } }) => Observable
                .of(actions.AddPasswordToUserAccountFailAction(translateErrorCode(error)))));

export const alert = (action$, { getState }) =>
    action$.ofType(methods.OPEN_ALERT_MODAL)
        .filter(({ newState: { nextAction } }) => !!nextAction)
        .map(({ newState: { nextAction } }) => nextAction);


export const fetchUserIdentifiers = action$ =>
    action$.ofType(methods.FETCH_USER_IDENTIFIERS)
        .switchMap(() => Observable
            .defer(() => services.userIdentifiers())
            .map(({ data: { userIdentifiers } }) => actions.fetchUserIdentifiersSuccessAction(userIdentifiers))
            .catch(({ response: { data: { error } } = { data: {} } }) => Observable
                .of(actions.fetchUserIdentifiersFailureAction(translateErrorCode(error)))));


export const modalEpic = customCombineEpics(
    alert,
    decryptMessage,
    addPassword,
    decryptMessageSuccess,
    sendSignInText,
    removeOnboardingStatusOnUser,
    removeParticipantFromGroup,
    addParticipantToGroup,
    addParticipantFromGroupToReducer,
    addParticipantToGroupFail,
    sendVerificationText,
    sendVerificationTextV2,
    verifyVerificationCode,
    fetchUserIdentifiers,
);

import cuid from 'cuid';
import moment from 'moment/moment';
import { superviseEpics, oneForOne } from '@mixer/epic-supervisor';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/operators/catchError';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/operator/withLatestFrom';
import { Observable } from 'rxjs';
import _ from 'underscore';

import * as db from '../app.firebase';
import * as methods from '../app.constants';
import * as actions from '../app.actions';
import * as services from './messenger.services';

import {
    pullConversationIdFromURI,
    getFirstNonKeyObject,
    setDefaultURL,
} from '../app.helpers';

import { storage, localStorage } from '../app.services';

import {
    transformMessages,
    flattenUser,
    getEarliestMessage,
    generateNewTagInMessage,
    populateParticipantsForConversations,
    setupNotifications,
    getTrueLocalConversationId,
    messageIsNotRead,
    getLatestMessage,
    extractAndParseTags,
    generateMessage,
    constructNewConversation,
    groupAndFilterMessagesByConversation,
    getUniqueParticipants,
} from './messenger.helpers';
import { updateCache } from './messenger.cache';
import { router } from '../app.cross-platform';
import { newMessengerEpic } from './messenger.conversation-handler.epic';
import { newMessengerEventEpic } from './messenger.event-handler.epic';
import * as analytics from '../app.analytics';

export const loadCacheBecomesSuccessfulLogin = action$ =>
    action$.ofType(methods.LOAD_FROM_CACHE)
        .map(({ newState: { auth: { user } } }) => actions.LoginUserSuccessAction({ ...user, existingConversationsSize: 0, fromCache: true }));

export const syncUserWithCache = action$ =>
    action$.ofType(methods.LOAD_FROM_CACHE)
        .switchMap(() => db.pullUserFromFirebase()
            .map(user => actions.SyncUserAndConversationsAction({ ...user, existingConversationsSize: 0 })));


export const subscribeToContactListUpdates = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .do(() => router.next(setDefaultURL()))
        .mergeMap(() => {
            if (process.env.WEB) {
                localStorage.set('logged-in', true);
            }


            const { uid } = getState().auth.user;

            setupNotifications(uid);

            return db.subscribeToContactListUpdates(uid)
                .map(contactList => contactList.sort(({ dateQuery: a }, { dateQuery: b }) => b - a))
                .map((contactList) => {
                    const _now = moment();
                    const oneHourFromNow = _now.add(1, 'h').valueOf();
                    const now = _now.valueOf();

                    const getLastUpdatedAt = (dateQuery) => {
                        if (dateQuery > now) {
                            if (dateQuery < oneHourFromNow) {
                                return now;
                            }
                        }

                        return dateQuery;
                    };

                    return contactList
                        .reduce((arr, {
                            email,
                            phone,
                            dateQuery,
                            ...rest
                        }) => {
                            // filter out contacts with no dateQuery
                            // if (!dateQuery) {
                            //     return arr;
                            // }

                            const contactContent = {
                                lastUpdatedAt: getLastUpdatedAt(dateQuery, rest),
                                isContact: true,
                                ...rest,
                            };

                            if (email) {
                                arr.push({
                                    email,
                                    ...contactContent,
                                });
                            }

                            if (phone) {
                                arr.push({
                                    phone,
                                    ...contactContent,
                                });
                            }

                            return arr;
                        }, []);
                })
                .filter(contactList => contactList.length > 0)
                .map(contactList => actions.UpdateContactListAction(contactList))
                .takeUntil(action$.ofType(methods.LOGOUT_USER));
        });

export const selectAsCurrentConversation = action$ =>
    action$.ofType(methods.START_CONVERSATION_LISTENER_SUCCESS)
        .filter(({ setAsCurrentConversation }) => !!setAsCurrentConversation)
        .filter(() => process.env.WEB) // TODO: react-native
        .map(({ id }) => actions.SetCurrentConversationAction({ id }));

export const loadMoreMessages = (action$, { getState }) =>
    action$.ofType(methods.SET_CURRENT_CONVERSATION)
        .filter(() => {
            const { currentConversation } = getState().messenger;
            return process.env.WEB
                ? !!currentConversation && currentConversation.id && currentConversation.id !== pullConversationIdFromURI()
                : true;
        })
        .do(() => {
            if (!process.env.WEB) {
                return router.next('MessengerContent');
            }

            const { currentConversation } = getState().messenger;
            updateCache({ currentConversation });
            return router.next(`/u/${currentConversation.id}`);
        })
        .filter(() => {
            const {
                currentConversation: {
                    messages,
                    allMessagesLoaded,
                    newConversation,
                    conversationIsLoading,
                },
            } = getState().messenger;
            return !conversationIsLoading && (messages.length <= 1) && !allMessagesLoaded && !newConversation;
        })
        .map(() => actions.PaginateMessagesAction());


export const paginateMessages = (action$, { getState }) =>
    action$.ofType(methods.PAGINATE_MESSAGES)
        .switchMap(({ newState: { forwards, both, currentConversation = getState().messenger.currentConversation } = {} }) => {
            const { filterByTags: tags } = getState().messenger;
            if (currentConversation && ((currentConversation.messages) || tags)) {
                const { messages: _messages = [], id } = currentConversation;
                const messages = _messages.filter(m => !!m.timestamp);
                const messageToPaginateFrom = forwards ? getLatestMessage(messages) : getEarliestMessage(messages);

                if (!messageToPaginateFrom.id && !messages.length === 0) {
                    return Observable.of(actions.PaginateMessagesFailAction({ message: 'No message to paginate from' }));
                }
                return db.loadMoreMessages(id, messageToPaginateFrom, { forwards, both })
                    .catch((e) => {
                        return Observable.of(actions.PaginateMessagesFailAction({ error: e.message }));
                    })
                    .flatMap((messages) => {
                        const observables = [];
                        if (messages) {
                            observables.push(Observable
                                .of(actions.PaginateMessagesSuccessAction({
                                    messages: transformMessages(messages),
                                    id,
                                })));

                            if (Object.keys(messages).length === 0) {
                                observables.push(Observable.of(actions.AllMessagesLoadedAction({ id })));
                            }
                        }

                        if (!observables.length) {
                            return Observable.of(actions.PaginateMessagesFailAction({ error: 'No messages loaded' }));
                        }

                        return Observable.concat(...observables);
                    });
            }

            return Observable.of(actions.PaginateMessagesFailAction({ error: 'Malformed conversation' }));
        });

export const setCurrentConversationOnContentLoad = (action$, { getState }) =>
    action$.ofType(methods.START_CONVERSATION_LISTENER_SUCCESS)
        .filter(() => process.env.WEB)
        .switchMap(({ newState: conversations }) => conversations)
        // only update the conversation id if this is a new session/mismatch in conversations
        .filter(({ id }) => (id === pullConversationIdFromURI() && id !== getState().messenger.currentConversation.id))
        .map(({ id }) => {
            return actions.SetCurrentConversationAction({ id });
        });

export const confirmConversationOverride = (action$, { getState }) =>
    action$.ofType(methods.START_EMPTY_CONVERSATION)
        .map(({ newState: { override, ...newState } }) => {
            if (
                !override
                && process.env.WEB
                && (getState().messenger.currentConversation.newConversation
                    || [...getState().messenger.conversations.values()].find(c => c.newConversation))
            ) {
                return actions.OpenConfirmConversationOverrideAction(newState);
            }

            if (newState.support) {
                const existingConversation = Array.from(getState().messenger.conversations).find(c =>
                    c[1].type === 'single' && c[1].participants && c[1].participants.find(p => p.email === process.env.SUPPORT_TEAM_EMAIL));

                if (existingConversation) {
                    return actions.SetCurrentConversationAction({ id: existingConversation[0] });
                }
            }

            return actions.CreateEmptyConversationAction(newState);
        });

export const setCreateNewConversationURI = (action$, { getState }) =>
    action$.ofType(methods.CREATE_EMPTY_CONVERSATION, methods.ANDROID_CREATE_EMPTY_CONVERSATION)
        .do(() => {
            if (process.env.WEB) {
                router.next('/u/new');

                return;
            }

            router.next('MessengerContent');
        })
        .filter(({ newState }) => newState.support)
        .map(() => actions.AddParticipantToCurrentConversationAction({
            participant: {
                email: process.env.SUPPORT_TEAM_EMAIL,
                name: process.env.SUPPORT_TEAM_EMAIL,
                id: cuid(),
            },
            id: getState().messenger.currentConversation.id,
        }));

export const setAsRead = (action$, { getState }) =>
    action$.ofType(methods.SET_CURRENT_CONVERSATION_AS_READ)
        .debounceTime(100)
        // .filter(() => getState().auth?.user)
        .map(() => {
            const {
                messenger: {
                    currentConversation: {
                        messages = [],
                        id: currentConversationId,
                        removedMembers = {},
                    },
                },
                auth: {
                    user: { uid },
                },
            } = getState();
            if (removedMembers[uid]) {
                return { unreadMessages: [] };
            }

            const unreadMessages = messages.filter(message => messageIsNotRead(message, uid));
            return { unreadMessages, uid, currentConversationId };
        })
        .filter(({ unreadMessages }) => !!unreadMessages.length)
        .switchMap(({ unreadMessages, uid, currentConversationId }) => db
            .updateMessageAsRead(currentConversationId, unreadMessages.map(({ id }) => id), uid)
            .map(() => {
                return actions.SetCurrentConversationAsReadSuccessAction({
                    id: currentConversationId,
                    messages: unreadMessages.map((message) => {
                        return {
                            ...message,
                            read: {
                                ...message.read,
                                [uid]: new Date().getTime(),
                            },
                        };
                    }),
                });
            }));

export const addTagToMessage = (action$, { getState }) =>
    action$.ofType(methods.ADD_TAG_TO_MESSAGE)
        .map(({ newState: { tag, messageId } }) => ({ tag: tag.replace('#', ''), messageId: messageId.replace(/\s/g, '') }))
        .filter(({ tag, messageId }) => messageId && tag)
        .map(({ tag: tagName, messageId }) => {
            const { currentConversation: { id: currentConversationId = false } } = getState().messenger;

            const { currentConversation: { tags } } = getState().messenger;
            const oldTags = tags;
            const tagArray = _.values(tags);
            const tagMatch = tagArray.find(({ name }) => name === tagName);
            let newTags = {};
            let tagId = '';

            if (!tagMatch) {
                tagId = cuid();
                newTags = {
                    [tagId]: generateNewTagInMessage(tagName, tagId),
                    ...newTags,
                };
            } else {
                tagId = tagMatch.id;

                oldTags[tagId].lastUsed = moment().valueOf();
                oldTags[tagId].frequency += 1;
            }

            return actions.AddTagToMessageUISuccessAction({
                id: currentConversationId,
                tags: { ...oldTags, ...newTags },
                messageId,
                tagId,
            });
        });

export const addTagToServer = (action$, { getState }) =>
    action$.ofType(methods.ADD_TAG_TO_MESSAGE_UI_SUCCESS)
        .switchMap(({ newState: { messageId, tagId } }) => {
            const { currentConversation: { newConversation, id: currentConversationId = false } } = getState().messenger;

            if (currentConversationId && !newConversation) {
                if (messageId === 'new') {
                    return Observable.of(true)
                        .map(() => actions.AddTagToMessageSuccessAction());
                }

                return db.appendTagInMessage(currentConversationId, messageId, tagId).map(() =>
                    actions.AddTagToMessageSuccessAction());
            }

            return Observable.of(actions.CreateConversationAction());
        });

export const updateConversationPhoto = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_PHOTO)
        .filter(() => !getState().messenger.currentConversation.newConversation)
        .switchMap(({ newState: { groupImageURL } }) => {
            const { currentConversation: { id: currentConversationId = false, participants } } = getState().messenger;

            return db.updateGroupPhoto(currentConversationId, participants, groupImageURL).map(() => {
                return actions.UpdateCurrentConversationPhotoSuccessAction({
                    id: currentConversationId,
                    groupImageURL,
                });
            });
        });

export const updateConversationTitle = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_TITLE)
        .switchMap(({ newState: title }) => {
            const { currentConversation: { newConversation, id: currentConversationId = false, participants } } = getState().messenger;

            if (currentConversationId && !newConversation) {
                return db.updateGroupTitle(currentConversationId, participants, title).map(() => {
                    return actions.UpdateCurrentConversationTitleSuccessAction({
                        id: currentConversationId,
                        title,
                    });
                });
            }

            return Observable.of(actions.CreateConversationAction());
        });

export const manageParticipants = action$ =>
    action$.ofType(methods.MANAGE_PARTICIPANTS)
        .filter(() => !process.env.WEB && !process.env.ELECTRON)
        .map(() => actions.UpdateRouterLocationAction({ native: 'MessengerConversationManageParticipants' }));

export const addParticipantToConversation = (action$, { getState }) =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION)
        .mergeMap(({ newState: { participant } }) => {
            const { messenger: { currentConversation } } = getState();

            return db.populateParticipantsFromIncompleteObjects([participant])
                .flatMap(([user]) => {
                    return Observable.of(actions.AddParticipantToCurrentConversationSuccessAction({
                        id: currentConversation.id,
                        participant: {
                            ...user,
                            tempId: participant.id,
                        },
                    }));
                });
        });

export const toggleCurrentConversationLoading = (action$, { getState }) =>
    Observable.merge(
        action$.ofType(
            methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION,
            methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS,
            methods.REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION,
        ).filter(() => !getState().messenger.currentConversationLoading)
            .mapTo(actions.SetCurrentConversationLoading(true)),

        action$.ofType(
            methods.STOP_COMPOSING_CONVERSATION,
            methods.UPDATE_CONVERSATIONS,
            methods.LOAD_CURRENT_CONVERSATION,
            methods.SET_CURRENT_CONVERSATION,
            methods.CREATE_EMPTY_CONVERSATION,
            methods.LEAVE_EMPTY_CONVERSATION,
        ).filter(() => getState().messenger.currentConversationLoading)
            .mapTo(actions.SetCurrentConversationLoading(false)),
    );

export const changeCurrentConversationWhenAddedParticipant = (action$, { getState }) =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS, methods.REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION)
        .filter(() => getState().messenger.currentConversation.newConversation)
        .switchMap(() => {
            const {
                messenger: { currentConversation, conversations: conversationList },
                auth: {
                    user: { uid, conversations: userConversations },
                },
            } = getState();

            if (!userConversations || Object.keys(userConversations).length === 0) {
                return Observable.of(actions.SetCurrentConversationLoading(false));
            }

            const {
                id: currentConversationId, backlogId, participants: _currentParticipants = [], lastUpdatedAt, type,
            } = currentConversation;

            const currentParticipants = _currentParticipants.filter(({ id }) => id !== uid);

            const getNewConversationState = id => ({
                id,
                newConversation: true,
                backlogId: backlogId || currentConversationId,
                participants: currentParticipants,
            });

            const findSimilarConversation = conversations => conversations
                .map(c => ({ ...c, participants: c.participants.filter(p => p.id !== uid) }))
                .filter(c => !c.newConversation && c.type === type && c.participants.length === currentParticipants.length)
                .filter(({ participants }) => getUniqueParticipants(participants, currentParticipants).length === 0)
                .find(({ participants }) => getUniqueParticipants(currentParticipants, participants).length === 0);

            const findSimilarSingleConversation = (needle, haystack) => {
                if (!needle.id) return null;
                if (!haystack) return null;
                const idArray = [needle.id, ...Object.keys(needle.managedUsers ?? [])];
                const findID = idArray.find(id => haystack[id]);
                if (findID) {
                    return { id: findID, ...haystack[findID] };
                }
                return null;
            };

            const conversationsToSearchIn = [...conversationList.values()];
            let similarConversation = findSimilarConversation(conversationsToSearchIn);
            // similarConversation = false;

            if (similarConversation) {
                // console.log('Option 1, found locally normal');
                return Observable.concat(
                    Observable.of(actions.SetCurrentConversationAction(getNewConversationState(similarConversation.id))),
                    !backlogId || currentConversation.isRemote
                        ? Observable.of(actions.LeaveEmptyConversationAction({
                            id: currentConversationId,
                            persistCurrentConversation: true,
                        }))
                        : Observable.of(actions.DummyAction()),
                    Observable.of(actions.LoadOverriddenConversationAction()),
                    Observable.of(actions.SetOverriddenConversationAction({
                        overriddenConversation: similarConversation,
                    })),
                );
            }

            if (currentParticipants.length === 1 && type === 'single') {
                similarConversation = findSimilarSingleConversation(currentParticipants[0], userConversations);
            }

            if (similarConversation) {
                // console.log('Option 2, found in auth users. Will paginate');
                const newConversation = constructNewConversation({
                    id: backlogId || currentConversationId, participants: _currentParticipants, lastUpdatedAt, type, backlogId: backlogId || currentConversationId, override: true,
                });

                newConversation.id = similarConversation.location;
                const messagesStored = Boolean(conversationList[newConversation.id]);
                currentConversation.isRemote = true;

                const conversationsToUpdateWith = new Map();
                conversationsToUpdateWith.set(newConversation.id, newConversation);

                return Observable.concat(
                    Observable.of(!messagesStored ? actions.UpdateConversationsAction({
                        conversations: conversationsToUpdateWith,
                        allConversationsLength: Object.keys(userConversations).length,
                    }) : actions.DummyAction),
                    Observable.of(currentConversation.isRemote ? actions.SetCurrentConversationAction(newConversation) : actions.CreateEmptyConversationAction(newConversation)),
                    Observable.of(actions.PaginateMessagesAction({ id: newConversation.id, both: true })),
                    currentConversation.isRemote
                        ? Observable.of(actions.LeaveEmptyConversationAction({
                            id: currentConversationId,
                            persistCurrentConversation: true,
                        }))
                        : Observable.of(actions.DummyAction()),
                    Observable.of(actions.LoadOverriddenConversationAction()),
                );
            }

            return Observable.defer(async () => {
                const anyOtherParticipant = currentParticipants.find(p => p.id !== uid);

                if (!anyOtherParticipant) {
                    return [];
                }

                const valueToSearchBy = anyOtherParticipant.email || anyOtherParticipant.phone;

                const response = await services.searchConversations(valueToSearchBy);

                if (!response) {
                    return [];
                }

                const searchResults = response.data.results;

                const conversationsToPopulateParticipants = Object.entries(searchResults)
                    .map(([id, c]) => ({ id, ...c.info, messages: c.messages }));

                const result = await populateParticipantsForConversations(conversationsToPopulateParticipants);

                // Filter out conversations that are not yet deleted from cache but the user has left them
                const userConversationLocations = Object.values(userConversations).map(conv => conv.location);
                const filteredResult = result.filter(conv => userConversationLocations.includes(conv.id) || Boolean(userConversations[conv.id]));

                return filteredResult;
            }).switchMap((conversations) => {
                const _remoteSimilarConversation = findSimilarConversation(conversations);
                // _remoteSimilarConversation = false;

                if (_remoteSimilarConversation) {
                    console.log('Option 3, Search found it', _remoteSimilarConversation);
                    const remoteSimilarConversation = {
                        ..._remoteSimilarConversation,
                        messages: transformMessages(_remoteSimilarConversation.messages),
                    };

                    const newConversation = {
                        ...remoteSimilarConversation,
                        newConversation: true,
                        lastUpdatedAt: moment().valueOf(),
                    };

                    const conversationsToUpdateWith = new Map();
                    conversationsToUpdateWith.set(remoteSimilarConversation.id, newConversation);

                    const newConversationToSet = {
                        ...getNewConversationState(remoteSimilarConversation.id),
                        ...newConversation,
                        isRemote: true,
                    };

                    return Observable.concat(
                        Observable.of(actions.LeaveEmptyConversationAction({
                            id: currentConversationId,
                            persistCurrentConversation: true,
                        })),
                        Observable.of(actions.LoadOverriddenConversationAction()),
                        Observable.of(actions.UpdateConversationsAction({
                            conversations: conversationsToUpdateWith,
                            allConversationsLength: Object.keys(userConversations).length,
                        })),
                        Observable.of(actions.SetCurrentConversationAction(newConversationToSet)),
                        Observable.of(actions.PaginateMessagesAction({ id: currentConversationId, both: true })),
                    );
                }

                console.log('Option 4, search returned nothing.');

                const newConversation = constructNewConversation({
                    id: backlogId || currentConversationId, participants: currentParticipants, lastUpdatedAt, type,
                });

                return Observable.concat(
                    Observable.of(actions.LeaveEmptyConversationAction({
                        id: currentConversationId,
                        persistCurrentConversation: true,
                    })),
                    Observable.of(actions.LoadOverriddenConversationAction()),
                    Observable.of(actions.StartEmptyConversationAction({ ...newConversation, override: true })),
                );
            });
        });

export const addParticipantToFirebaseConversation = (action$, { getState }) =>
    action$.ofType(methods.ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS)
        .filter(() => !getState().messenger.currentConversation.newConversation)
        .switchMap(({ newState: { participant } }) => {
            const { currentConversation } = getState().messenger;
            const userConvoInfo = {
                lastUpdatedAt: currentConversation.lastUpdatedAt,
                mute: false,
                location: currentConversation.id,
                group: true,
            };


            return Observable.fromPromise(db.addParticipantsToGroup([participant], currentConversation.id, userConvoInfo))
                .switchMap(() => db
                    .addParticipantToConversation(
                        currentConversation.id,
                        participant,
                    ).map(() => actions.DummyAction()));
        });

export const stopComposingConversation = (action$, { getState }) =>
    action$.ofType(methods.STOP_COMPOSING_CONVERSATION)
        .filter(() => getState().messenger.currentConversation.backlogId)
        .switchMap(() => {
            return Observable.of(actions.SetCurrentConversationAction({ id: getState().messenger.currentConversation.id }));
        });

export const redirectUserOnLeaveEmptyConversation = (action$, { getState }) =>
    action$.ofType(methods.LEAVE_EMPTY_CONVERSATION)
        .filter(({ newState: { persistCurrentConversation } }) => (
            !persistCurrentConversation && getState().messenger.currentConversation.newConversation
        ))
        .map(({ newState: { id: conversationId } }) => {
            const { conversations } = getState().messenger;

            if (Object.keys(conversations).length >= 1) {
                return actions.SetCurrentConversationAction(getFirstNonKeyObject(conversations, 'id', conversationId));
            }

            return actions.StartEmptyConversationAction({ type: 'group' });
        });

export const addUsersToContactList = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS)
        .switchMap(({ newState: { participants } }) => {
            // TODO: look through exiting contacts and do a union for participants
            const { contactList } = getState().messenger;
            const { uid } = getState().auth.user;

            // TODO: do the efficient _js thing
            const newContacts = participants
                .filter(({ email, phone }) => {
                    const data = contactList.find(({ email: foundEmail, phone: foundPhone }) => {
                        if (email) {
                            return email === foundEmail;
                        }
                        if (phone) {
                            return phone === foundPhone;
                        }

                        return false;
                    });

                    return !data;
                })
                .map(({ email, phone, name }) => {
                    const data = {};

                    if (email) {
                        data.email = email;
                    }

                    if (phone) {
                        data.phone = phone;
                    }

                    if (name) {
                        data.name = name;
                    }

                    return data;
                });

            return db.addNewContacts(uid, newContacts)
                .map(() => actions.DummyAction());
        });

export const loadCurrentConversation = action$ =>
    action$.ofType(methods.LOAD_CURRENT_CONVERSATION)
        .do(({ newState: { id } }) => process.env.WEB && router.next(`/u/${id}`))
        .map(({ newState: conversation }) => {
            return actions.PaginateConversationsAction({
                limit: 1,
                existingConversationsSize: 0,
                conversations: {
                    [conversation.id]: {
                        location: conversation.id,
                        ...conversation,
                        lastUpdatedAt: moment().valueOf(),
                    },
                },
            });
        });

export const updateCurrentConversationTags = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_TAGS)
        .filter(() => {
            const { messenger: { currentConversation: { id, newConversation } } } = getState();
            return id && !newConversation;
        })
        .switchMap(({ newState: { tags = {} } }) => {
            const { messenger: { currentConversation: { id } } } = getState();

            return db.updateTags(id, tags)
                .map(() => actions.UpdateCurrentConversationTagsSuccessAction());
        });

export const sendMessage = (action$, { getState }) =>
    action$.ofType(methods.SEND_MESSAGE)
        .map(({ newState: { message, id, filePromise } }) => {
            const { messenger: { currentConversation: { newConversation, backlogId } } } = getState();

            db.updateMessageFileUpload(message, id, filePromise);

            if (id && !newConversation) {
                return actions.SendMessageSuccessAction({ messages: [message], id });
            }

            if (backlogId) {
                return actions.SendMessageSuccessAction({ messages: [message], id: backlogId });
            }

            return actions.CreateConversationAction({ message });
        });

export const sendMessageToFirebase = (action$, { getState }) =>
    action$.ofType(methods.SEND_MESSAGE)
        .filter(() => {
            const { messenger: { currentConversation: { newConversation, creating, backlogId } } } = getState();
            return !(newConversation || creating) || backlogId;
        })
        .bufferTime(1500)
        .filter(data => data && data.length)
        .map((bufferState) => {
            const { messenger: { conversations } } = getState();
            return groupAndFilterMessagesByConversation(bufferState, conversations);
        })
        .filter(groupedMessages => _.size(groupedMessages))
        .switchMap((groupedMessages) => {
            return db.sendMessage(groupedMessages)
                .filter(() => false);
        });

export const sendMessageToNewConversationFirebase = (action$, { getState }) =>
    action$.ofType(methods.SEND_MESSAGE)
        .filter(() => {
            const { messenger: { currentConversation: { newConversation, creating, backlogId } } } = getState();
            return !backlogId && (newConversation || creating);
        })
        .buffer(action$.ofType(methods.CREATE_CONVERSATION_SUCCESS, methods.CREATE_CONVERSATION_FAIL))
        .filter(data => data && data.length)
        .map((bufferState) => {
            const { messenger: { conversations } } = getState();
            return groupAndFilterMessagesByConversation(bufferState, conversations);
        })
        .filter(groupedMessages => _.size(groupedMessages))
        .switchMap((groupedMessages) => {
            return db.sendMessage(groupedMessages)
                .filter(() => false);
        });

export const updateUserConversationsOnTagsUpdate = action$ =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_TAGS_SUCCESS)
        .map(() => actions.UpdateConversationStorageAction());

export const updateUserConversationsOnMute = action$ =>
    action$.ofType(methods.MUTE_CONVERSATION_SUCCESS)
        .map(() => actions.UpdateConversationStorageAction());

export const updateUserConversationsOnUnMute = action$ =>
    action$.ofType(methods.UNMUTE_CONVERSATION_SUCCESS)
        .map(() => actions.UpdateConversationStorageAction());

export const updateUserConversationsOnUpdateParticipants = action$ =>
    action$.ofType(methods.UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS)
        .map(() => actions.UpdateConversationStorageAction());

export const updateUserConversationsStorage = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_CONVERSATION_STORAGE)
        .debounceTime(1000)
        .map(() => getState().messenger.conversations)
        .filter(conversations => !!conversations && conversations.length)
        .map(conversations => conversations
            .filter(({ id, newConversation }) => id && !newConversation)
            .filter(({ messages = [] }) => messages.filter(({ content }) => !!content).length)
            .map(({ messages, ...conversation }) => ({ ...conversation, messages: [] })))
        .do(conversations => storage.set('conversations', { data: conversations }, true))
        .map(() => getState().messenger.currentConversation)
        .map(() => actions.UpdateStorageSuccessAction());

export const createConversation = (action$, { getState }) =>
    action$.ofType(methods.CREATE_CONVERSATION)
        .map(({ newState: { id, message } = {} }) => ({
            currentConversation: {
                id,
                ...getState().messenger.currentConversation,
                participants: [
                    ...getState().messenger.currentConversation.participants,
                    flattenUser(getState().auth.user),
                ],
                name: getState().messenger.newGroupConversationTitle,
            },
            message,
        }))
        .do(({ currentConversation: { id } }) => process.env.WEB && router.next(`/u/${id}`))
        .switchMap(({ currentConversation, message }) => db
            .createNewConversation(currentConversation, message)
            .map(({ conversation, cid }) => actions.CreateConversationSuccessAction({ conversation, id: cid }))
            .catch((e) => {
                console.dir(e);

                if (!process.env.WEB) {
                    return Observable.of(actions.CreateConversationFailAction());
                }

                return Observable.of(actions.OpenAlertModalAction({
                    text: 'Failed to create conversation, please try again later',
                    nextAction: actions.CreateConversationFailAction(),
                }));
            }));

export const createConversationFail = $action =>
    $action.ofType(methods.CREATE_CONVERSATION_FAIL)
        .filter(() => !process.env.WEB)
        .do(() => {
            // eslint-disable-next-line global-require
            const { Alert } = require('react-native');
            Alert.alert(
                'Something went wrong!',
                'Please check if you input valid users!',
            );
        })
        .filter(() => false);

export const parseMessage = (action$, { getState }) =>
    action$.ofType(methods.PARSE_MESSAGE)
        .filter(({ newState: { meta: { cid } } }) => {
            const { messenger: { conversations } } = getState();
            const currentConversation = conversations.get(cid);
            console.log(cid);
            console.log(currentConversation);
            return currentConversation && currentConversation.participants.length >= 1;
        })
        .filter(({
            newState: { content, fileType, meta: { cid } },
        }) => ((fileType === 'secret' || fileType === 'text') ? (content && content.length && cid) : (fileType && cid)))
        .do(({ type, newState: { meta: { cid } } }) => {
            const { messenger: { conversations }, auth: { user: { uid } } } = getState();
            const { participants } = conversations.get(cid);
            if (!process.env.WEB) {
                return true;
            }
            return analytics.messageSent(type, participants, uid);
        })
        .flatMap(({
            newState: {
                content,
                fileType,
                meta: { cid, ...meta },
                replyTo,
                filePromise,
            },
        }) => {
            const { messenger: { conversations }, auth: { user: { uid }, user } } = getState();
            const currentConversation = conversations.get(cid);
            const parsedMessage = [generateMessage(fileType, content, {}, meta, cid)];
            const { messages: [message], tags, tagStateChanged } = extractAndParseTags(parsedMessage, currentConversation, uid);

            if (replyTo) {
                const {
                    content,
                    fromID,
                    isRead,
                    id: key,
                    type,
                    meta = null,
                } = replyTo;

                const {
                    name,
                    email,
                    phone,
                    profileImageURL,
                } = user.credentials;


                message['reply-to'] = {
                    content,
                    fromID,
                    isRead,
                    key,
                    location: cid,
                    type,
                    profilePic: profileImageURL,
                    uid: user.uid,
                    username: name || email || phone,
                    meta,
                };
            }

            const $action = actions.SendMessageAction({
                message,
                tags,
                id: cid,
                filePromise,
            });

            const observables = [Observable.of($action)];

            if (tagStateChanged) {
                observables.push(Observable.of(actions.UpdateCurrentConversationTagsAction({ tags })));
            }

            if (currentConversation.replyTo) {
                observables.push(Observable.of(actions.RemoveReplyMessage()));
            }

            return Observable.concat(...observables);
        });

export const addReaction = (action$, { getState }) =>
    action$.ofType(methods.ADD_REACTION)
        .map(({ newState: { code, messageId } }) => ({
            currentConversationId: getState().messenger.currentConversation.id,
            uid: getState().auth.user.uid,
            messageId,
            code,
        }))
        .switchMap(params => db.addReaction(params)
            .map(() => actions.AddReactionSuccessAction({ id: params.currentConversationId })));

export const removeReaction = (action$, { getState }) =>
    action$.ofType(methods.REMOVE_REACTION)
        .map(({ newState: { code, messageId } }) => ({
            currentConversationId: getState().messenger.currentConversation.id,
            uid: getState().auth.user.uid,
            messageId,
            code,
        }))
        .switchMap(params => db.removeReaction(params)
            .map(() => actions.RemoveReactionSuccessAction({ id: params.currentConversationId })));


export const muteConversation = (action$, { getState }) =>
    action$.ofType(methods.MUTE_CONVERSATION)
        .map(() => ({
            conversationId: getTrueLocalConversationId(getState().messenger.currentConversation),
            actualConversationId: getState().messenger.currentConversation.id,
            uid: getState().auth.user.uid,
        }))
        .switchMap(({ conversationId, uid, actualConversationId }) =>
            db.muteConversation(conversationId, uid)
                .map(() => actions.MuteConversationSuccessAction({ id: actualConversationId })));

export const unmuteConversation = (action$, { getState }) =>
    action$.ofType(methods.UNMUTE_CONVERSATION)
        .map(() => ({
            conversationId: getTrueLocalConversationId(getState().messenger.currentConversation),
            actualConversationId: getState().messenger.currentConversation.id,
            uid: getState().auth.user.uid,
        }))
        .switchMap(({ conversationId, uid, actualConversationId }) =>
            db.unmuteConversation(conversationId, uid)
                .map(() => actions.UnmuteConversationSuccessAction({ id: actualConversationId })));

export const selectUserProfileToOpen = (action$, { getState }) =>
    action$.ofType(methods.OPEN_USER_PROFILE)
        .map(({ newState: user }) => {
            if (getState().auth.user.uid === user.id || !user.id) {
                return actions.OpenEditProfileModalAction();
            }

            return actions.EnableSidebarAction('otherProfile', { user });
        });

export const startSingleConversationWithID = (action$, { getState }) =>
    action$.ofType(methods.START_SINGLE_CONVERSATION_WITH_ID)
        .switchMap(({ newState: { uid, displayContact } }) => {
            let sameConversation = getState().auth.user.conversations[uid];

            // we should be sure that we don't have duplicate new single chat with same user
            if (!process.env.WEB && !sameConversation) {
                const conversationList = getState().messenger.conversations;
                sameConversation = [...conversationList.values()].find(({ newConversation, type, participants }) => (
                    newConversation
                    && type === 'single'
                    && participants[0]
                    && participants[0].id === uid
                ));
            }

            if (sameConversation && (sameConversation.location || sameConversation.id)) {
                return Observable.of(true).map(() => actions.SetCurrentConversationAction({
                    id: sameConversation.location || sameConversation.id,
                }));
            }

            return db.populateParticipantsFromIds([[uid, displayContact]])
                .map(participants => actions.StartEmptyConversationAction({ type: 'single', participants }));
        });

export const startSingleConversationWithContactData = (action$, { getState }) =>
    action$.ofType(methods.START_SINGLE_CONVERSATION_WITH_CONTACT_DATA)
        .switchMap(({ newState: { email, phone, name } }) =>
            db.populateParticipantsFromIncompleteObjects([{ email, phone, name }])
                .map(([participant]) => {
                    const userConversations = getState().auth.user.conversations;
                    const sameConversation = userConversations && userConversations[participant.id];
                    if (sameConversation && sameConversation.location) {
                        return actions.LoadCurrentConversationAction({ id: sameConversation.location, inherit: true });
                    }

                    return actions.StartEmptyConversationAction({ type: 'single', participants: [participant] });
                }));

export const startConversationWithEventData = (action$, { getState }) =>
    action$.ofType(methods.START_CONVERSATION_WITH_EVENT_DATA)
        .switchMap(({ newState: { participants, id, title } }) =>
            db.populateParticipantsFromIncompleteObjects(participants)
                .map((participants) => {
                    // console.log('Participants', participants);
                    const participantsWithoutCurrentUser = db.getParticipantsWithoutCurrentUser(participants);

                    if (participantsWithoutCurrentUser.length === 1) {
                        const [participant] = participantsWithoutCurrentUser;
                        const userConversations = getState().auth.user.conversations;
                        const sameConversation = userConversations && userConversations[participant.id];
                        if (sameConversation && sameConversation.location) {
                            return actions.LoadCurrentConversationAction({
                                id: sameConversation.location,
                                inherit: true,
                            });
                        }

                        return actions.StartEmptyConversationAction({
                            type: 'single',
                            participants: [participant],
                            eventId: id,
                        });
                    }

                    return actions.StartEmptyConversationAction({
                        name: title,
                        type: 'group',
                        participants: participantsWithoutCurrentUser,
                        eventId: id,
                    });
                }));

export const searchConversations = (action$, { getState }) =>
    action$.ofType(methods.SEARCH_CONVERSATIONS)
        .debounceTime(300)
        .map(({ newState: { query, scope, context } }) => {
            const { conversations } = getState().auth.user;

            return ({
                from: 0,
                query: query.trim(),
                context,
                scope: conversations ? Object.values(conversations).map(c => c.location) : [],
            });
        })
        .filter(({ query }) => !!query)
        .mergeMap(({
            query,
            scope,
            from,
            context,
        }) => {
            const { messenger: { searchType: mode } } = getState();

            return Observable.defer(async () => {
                if (query) {
                    if (mode === 'global') {
                        return services.searchConversations(query);
                    }

                    if (mode === 'messages') {
                        return services.search(scope, query, from, context);
                    }
                }

                return null;
            })
                .filter(v => v)
                .catch(() => Observable.of(actions.SearchConversationsFailAction('Search Failed')))
                .mergeMap(({ data: { results = {}, errors = {} } = {} }) => {
                    const parsedConversations = Object.entries(results)
                        .map(([id, { info, messages }]) => ({
                            ...info,
                            id,
                            messages: transformMessages(messages),
                            searchError: errors[id],
                        }))
                        .filter(({ messages }) => _.size(messages) > 0)
                        .sort(({ lastUpdatedAt: a }, { lastUpdatedAt: b }) => b - a);

                    return Observable.defer(async () => populateParticipantsForConversations(parsedConversations))
                        .map(data => actions.SearchConversationsSuccessAction(data))
                        .catch(() => Observable.of(actions.SearchConversationsFailAction('Search Failed')));
                });
        });

const options = {
    restart: oneForOne,
    onError: ({ error, epicName }) => {
        console.error(`An error occurred in epic ${epicName}`, error);
    },
};

export const messengerEpic = superviseEpics(
    options,
    toggleCurrentConversationLoading,
    newMessengerEpic,
    newMessengerEventEpic,
    confirmConversationOverride,
    setCreateNewConversationURI,
    startSingleConversationWithID,
    selectUserProfileToOpen,
    updateConversationPhoto,
    updateUserConversationsOnUpdateParticipants,
    updateConversationTitle,
    updateUserConversationsOnMute,
    updateUserConversationsOnUnMute,
    setCurrentConversationOnContentLoad,
    parseMessage,
    sendMessage,
    createConversation,
    createConversationFail,
    addTagToMessage,
    loadCurrentConversation,
    sendMessageToFirebase,
    sendMessageToNewConversationFirebase,
    addTagToServer,
    muteConversation,
    unmuteConversation,
    loadMoreMessages,
    paginateMessages,
    syncUserWithCache,
    loadCacheBecomesSuccessfulLogin,
    subscribeToContactListUpdates,
    updateCurrentConversationTags,
    updateUserConversationsOnTagsUpdate,
    updateUserConversationsStorage,
    selectAsCurrentConversation,
    startSingleConversationWithContactData,
    startConversationWithEventData,
    redirectUserOnLeaveEmptyConversation,
    setAsRead,
    addUsersToContactList,
    manageParticipants,
    addParticipantToConversation,
    changeCurrentConversationWhenAddedParticipant,
    stopComposingConversation,
    addParticipantToFirebaseConversation,
    searchConversations,
    addReaction,
    removeReaction,
);

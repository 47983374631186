import * as methods from '../app.constants';
import { getCurrentURLQueryString } from '../app.helpers';

const initialState = {
    /**
     * Leave Conversation Modal
     */
    isLeaveConversationModalVisible: false,

    /**
     * Announcement Modal
     */
    isAnnouncementModalVisible: false,

    /**
     * Current conversation name Modal
     */
    isUpdateCurrentConversationTitleModalVisible: false,
    nextConversationName: '',

    /**
     * Decrypt message modal
     */
    isDecryptMessageModalVisible: null,
    decryptPassword: '',
    decryptStatus: '',

    /**
     * Encrypt Message Modal
     */

    isBlackSMSSettingsOpen: false,
    isBlackSMSEnabled: false,
    blackSMSPassword: '',

    /**
     * Send text to sign in modal
     */
    isSendTextModalVisible: false,
    sendingText: false,
    sendTextError: false,
    sentSignInText: false,

    /**
     * Manage Participants Modal
     */
    isManageParticipantsModalVisible: false,


    /**
     * Onboarding Modal
     */
    onboardingStage: 0,
    lastOnboardingState: 0,
    isOnboardingModalVisible: false,
    isSendingVerificationText: false,
    isVerificationCodeSent: false,
    isVerifyingVerificationCode: false,
    isVerificationInvalid: false,
    verifyCodeError: null,
    sendingCodeToPhoneError: null,
    /**
     * Ask To Verify Number Modal
     */
    isVerifyNumberModalVisible: false,

    /**
     * Edit Profile Modal
     */
    isEditProfileModalVisible: false,

    /**
     * Edit Settings Modal
     */
    isEditSettingsModalVisible: false,
    isUserIdentifiersFetching: false,
    userIdentifiers: null,

    /**
     * Add Contacts Modal
     */
    isAddContactsModalVisible: false,

    /**
     * Add Password Modal
     */
    isAddPasswordModalVisible: false,
    isAddingPassword: false,
    passwordAddingError: null,
    isForgotPasswordMode: false,

    /**
     * Composer Modal
     */
    isComposerModalVisible: false,
    sharedInputText: '',

    /**
     * Confirm Conversation Creation Modal
     */
    isConfirmConversationOverrideModalVisible: false,
    newConversation: null,

    /**
     * Alert Modal
     */
    isAlertModalVisible: false,
    alertText: null,
};

export function modalReducer(state = initialState, action) {
    switch (action.type) {
    /**
     * Announcement Modal
     */
    case methods.OPEN_ANNOUNCEMENT_MODAL:
        return {
            ...state,
            isAnnouncementModalVisible: true,
        };

    case methods.CLOSE_ANNOUNCEMENT_MODAL:
        return {
            ...state,
            isAnnouncementModalVisible: false,
        };

    /**
     * Leave Conversation Modal
     */
    case methods.OPEN_ASK_TO_LEAVE_CONFIRMATION_MODAL:
        return {
            ...state,
            isLeaveConversationModalVisible: true,
        };

    case methods.CLOSE_ASK_TO_LEAVE_CONFIRMATION_MODAL:
        return {
            ...state,
            isLeaveConversationModalVisible: false,
        };

    /**
     * Decrypt Message Modal
     */
    case methods.OPEN_DECRYPT_MESSAGE_MODAL:
        return {
            ...state,
            isDecryptMessageModalVisible: action.newState,
        };

    case methods.CLOSE_DECRYPT_MESSAGE_MODAL:
        return {
            ...state,
            isDecryptMessageModalVisible: null,
            decryptPassword: '',
            decryptStatus: '',
        };

    case methods.UPDATE_DECRYPT_PASSWORD:
        return {
            ...state,
            decryptPassword: action.newState,
        };

    case methods.DECRYPT_MESSAGE_SUCCESS:
        return {
            ...state,
            decryptStatus: '',
        };

    case methods.DECRYPT_MESSAGE_FAIL:
        return {
            ...state,
            decryptStatus: action.newState,
        };

        /**
         * Encrypt Message Modal
         */

    case methods.SET_BLACK_SMS_OPTIONS_VISIBILITY: {
        return {
            ...state,
            isBlackSMSOptionsVisible: !state.isBlackSMSOptionsVisible,
            isBlackSMSEnabled: Boolean(state.blackSMSPassword.length) && state.isBlackSMSEnabled,
        };
    }

    case methods.SET_BLACK_SMS_MODE: {
        return {
            ...state,
            isBlackSMSEnabled: action.newState,
        };
    }

    case methods.SET_BLACK_SMS_PASSWORD: {
        return {
            ...state,
            blackSMSPassword: action.newState,
        };
    }

    /**
     * Send Text To Sign In Modal
     */
    case methods.OPEN_SEND_TEXT_TO_SIGN_IN_MODAL:
        return {
            ...state,
            isSendTextModalVisible: true,
        };

    case methods.CLOSE_SEND_TEXT_TO_SIGN_IN_MODAL:
        return {
            ...state,
            isSendTextModalVisible: false,
            sentSignInText: false,
        };

    case methods.SEND_SIGN_IN_TEXT:
        return {
            ...state,
            sendingText: true,
        };

    case methods.SEND_SIGN_IN_TEXT_SUCCESS:
        return {
            ...state,
            sendingText: false,
            sentSignInText: true,
        };

    case methods.SEND_SIGN_IN_TEXT_FAILED:
        return {
            ...state,
            sendingText: false,
            sendTextError: true,
        };

    /**
     * Update Current Conversation Title
     */
    case methods.OPEN_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL:
        return {
            ...state,
            nextConversationName: action.newState.name || '',
            isUpdateCurrentConversationTitleModalVisible: true,
        };

    case methods.CLOSE_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL:
        return {
            ...state,
            isUpdateCurrentConversationTitleModalVisible: false,
        };

    case methods.UPDATE_NEXT_CONVERSATION_NAME_ACTION:
        return {
            ...state,
            nextConversationName: action.newState,
        };


    /**
     * Manage Participants Modal
     */
    case methods.OPEN_MANAGE_PARTICIPANTS_MODAL:
        return {
            ...state,
            isManageParticipantsModalVisible: true,
        };

    case methods.CLOSE_MANAGE_PARTICIPANTS_MODAL:
        return {
            ...state,
            isManageParticipantsModalVisible: false,
        };

    /**
     * Onboarding Modal
     */
    case methods.OPEN_ONBOARDING_MODAL:
        return {
            ...state,
            lastOnboardingStage: 0,
            onboardingStage: 0,
            isOnboardingModalVisible: true,
            isSendingVerificationText: false,
            isVerificationCodeSent: false,
        };

    case methods.CLOSE_ONBOARDING_MODAL:
        return {
            ...state,
            lastOnboardingStage: 0,
            onboardingStage: 0,
            isOnboardingModalVisible: false,
        };

    case methods.VERIFY_VERIFICATION_CODE_SUCCESS:
    case methods.GOTO_NEXT_ONBOARDING_STEP:
        return {
            ...state,
            lastOnboardingStage: state.onboardingStage,
            onboardingStage: state.onboardingStage + 1,
        };

    case methods.SAVE_PROFILE_SUCCESS:
        if (!action.newState.isOnboarding) {
            return state;
        }

        return {
            ...state,
            lastOnboardingStage: state.onboardingStage,
            onboardingStage: state.onboardingStage + 1,
            isVerificationCodeSent: false,
        };

    case methods.SET_NEXT_ONBOARDING_STEP:
        return {
            ...state,
            lastOnboardingStage: state.onboardingStage,
            onboardingStage: action.newState,
            isSendingVerificationText: false,
            isVerificationCodeSent: false,
        };

    case methods.SEND_VERIFICATION_TEXT_V2:
    case methods.SEND_VERIFICATION_TEXT:
        return {
            ...state,
            isSendingVerificationText: true,
            isVerificationCodeSent: false,
        };

    case methods.SEND_VERIFICATION_TEXT_SUCCESS:
        return {
            ...state,
            isSendingVerificationText: false,
            isVerificationCodeSent: true,
        };

    case methods.SEND_VERIFICATION_TEXT_FAIL:
        return {
            ...state,
            isSendingVerificationText: false,
            isVerificationCodeSent: false,
            sendingCodeToPhoneError: action.newState,
        };

    case methods.VERIFY_VERIFICATION_CODE:
        return {
            ...state,
            isVerifyingVerificationCode: true,
        };

    case methods.LOGIN_USER_SUCCESS: {
        // disabled no-undef rule, coz it has sort of conflict with optional-chaining
        // eslint-disable-next-line no-undef
        if ((action.newState?.meta?.web && action.newState?.meta?.web?.onboarded) || getCurrentURLQueryString().noonboarding) {
            return {
                ...state,
                isOnboardingModalVisible: false,
            };
        }
        // eslint-disable-next-line no-undef
        if ((action.newState?.credentials && action.newState?.credentials?.phone)) {
            return {
                ...state,
                isOnboardingModalVisible: true,
                onboardingStage: 1,
                lastOnboardingState: 0,
            };
        }
        return {
            ...state,
            isOnboardingModalVisible: true,
        };
    }

    case methods.SYNC_USER_AND_CONVERSATIONS: {
        return {
            ...state,
        };
    }

    case methods.VERIFY_VERIFICATION_CODE_FAIL:
        return {
            ...state,
            isVerifyingVerificationCode: false,
            isVerificationInvalid: true,
            verifyCodeError: action.newState,
        };

    case methods.OPEN_ASK_TO_VERIFY_NUMBER_MODAL:
        return {
            ...state,
            isVerifyNumberModalVisible: true,
        };

    case methods.CLOSE_ASK_TO_VERIFY_NUMBER_MODAL:
        return {
            ...state,
            isVerifyNumberModalVisible: false,
        };

    /**
     * Edit Profile Modal
     */
    case methods.OPEN_EDIT_PROFILE_MODAL:
        return {
            ...state,
            isEditProfileModalVisible: true,
        };

    case methods.CLOSE_EDIT_PROFILE_MODAL:
        return {
            ...state,
            isEditProfileModalVisible: false,
        };

    /**
     * Edit Profile Modal
     */
    case methods.OPEN_EDIT_SETTINGS_MODAL:
        return {
            ...state,
            isEditSettingsModalVisible: true,
        };

    case methods.CLOSE_EDIT_SETTINGS_MODAL:
        return {
            ...state,
            isEditSettingsModalVisible: false,
        };
    case methods.FETCH_USER_IDENTIFIERS:
        return {
            ...state,
            isUserIdentifiersFetching: true,
        };
    case methods.FETCH_USER_IDENTIFIERS_SUCCESS:
        return {
            ...state,
            userIdentifiers: action.newState,
            isUserIdentifiersFetching: false,
        };
    case methods.FETCH_USER_IDENTIFIERS_FAILURE:
        return {
            ...state,
            userIdentifiers: action.newState,
            isUserIdentifiersFetching: false,
        };
    case methods.RESET_USER_IDENTIFIERS_STATE:
        return {
            ...state,
            userIdentifiers: null,
            isUserIdentifiersFetching: false,
        };
    /**
     * Add contacts modal
     */
    case methods.OPEN_ADD_CONTACTS_MODAL:
        return {
            ...state,
            isAddContactsModalVisible: true,
        };

    case methods.CLOSE_ADD_CONTACTS_MODAL:
        return {
            ...state,
            isAddContactsModalVisible: false,
        };

    /**
     * Add password modal
     */
    case methods.OPEN_ADD_PASSWORD_MODAL:
        return {
            ...state,
            isAddPasswordModalVisible: true,
            isForgotPasswordMode: action.newState,
        };

    case methods.CLOSE_ADD_PASSWORD_MODAL:
        return {
            ...state,
            isAddPasswordModalVisible: false,
            isForgotPasswordMode: false,
        };

    case methods.ADD_PASSWORD_TO_USER_ACCOUNT:
        return {
            ...state,
            passwordAddingError: null,
            isAddingPassword: true,
        };

    case methods.ADD_PASSWORD_TO_USER_ACCOUNT_SUCCESS:
        return {
            ...state,
            isAddPasswordModalVisible: false,
            isAddingPassword: false,
        };

    case methods.ADD_PASSWORD_TO_USER_ACCOUNT_FAIL:
        return {
            ...state,
            passwordAddingError: action.newState,
            isAddingPassword: false,
        };

    case methods.OPEN_COMPOSER:
        return {
            ...state,
            isComposerModalVisible: true,
            sharedInputText: action.newState,
        };

    case methods.CLOSE_COMPOSER:
        return {
            ...state,
            isComposerModalVisible: false,
            sharedInputText: action.newState ? action.newState : '',
        };

    case methods.FOCUS_INPUT:
        return {
            ...state,
            shouldFocus: true,
        };

    case methods.FOCUS_INPUT_COMPLETE:
        return {
            ...state,
            shouldFocus: false,
        };

    case methods.RESET_COMPOSER:
        return {
            ...state,
            sharedInputText: undefined,
        };

    case methods.OPEN_CONFIRM_CONVERSATION_OVERRIDE:
        return {
            ...state,
            isConfirmConversationOverrideModalVisible: true,
            newConversation: action.newState,
        };

    case methods.CLOSE_CONFIRM_CONVERSATION_OVERRIDE:
        return {
            ...state,
            isConfirmConversationOverrideModalVisible: false,
            newConversation: null,
        };

    case methods.OPEN_ALERT_MODAL:
        return {
            ...state,
            isAlertModalVisible: true,
            alertText: action.newState.text,
        };

    case methods.CLOSE_ALERT_MODAL:
        return {
            ...state,
            isAlertModalVisible: false,
            alertText: null,
        };

    case methods.RESET_ONBOARDING_MODAL_STATE:
        return {
            ...state,
            onboardingStage: 0,
            lastOnboardingState: 0,
            isOnboardingModalVisible: false,
            isSendingVerificationText: false,
            isVerificationCodeSent: false,
            isVerifyingVerificationCode: false,
            isVerificationInvalid: false,
            verifyCodeError: null,
            sendingCodeToPhoneError: null,
        };

    default:
        return state;
    }
}

import React, { Component } from 'react';

const intervalStore = {};

export class CTA extends Component {
    constructor() {
        super();
        this.clients = ['clients', 'investors', 'associates'];
        this.services = [
            {
                name: 'facebook-messenger',
                color: '#2384F6',
            },
            {
                name: 'whatsapp',
                color: '#22CD5B',
            },
            {
                name: 'telegram-plane',
                color: '#0088cc',
            },
            {
                name: 'line',
                color: '#00c300',
            },
            {
                name: 'skype',
                color: '#00aff0',
            },
        ];

        this.state = {
            client: this.clients[0],
            service: this.services[0],
        };
    }

    componentWillMount() {
        this.updateClient();
        this.updateService();
    }

    shouldComponentUpdate(a, nextState) {
        return this.state.client !== nextState.client ||
            this.state.service.name !== nextState.service.name;
    }

    componentWillUnmount() {
        clearInterval(intervalStore.client);
        clearInterval(intervalStore.service);
    }

    updateClient() {
        let i = 0;
        intervalStore.client = setInterval(() => {
            i += 1;
            this.setState({
                ...this.state,
                client: this.clients[i % this.clients.length],
            });
        }, 3500);
    }

    updateService() {
        let i = 0;
        intervalStore.service = setInterval(() => {
            i += 1;
            this.setState({
                ...this.state,
                service: this.services[i % this.services.length],
            });
        }, 3500);
    }

    render() {
        return (
            <h1>The world&apos;s most <span className="usePrimaryColorText">productive</span> messenger.</h1>
        );
    }
}

import * as db from './app.firebase';

export * from './auth/auth.constants';
export * from './messenger/messenger.constants';
export * from './router/router.constants';
export * from './modals/modal.constants';

export const INIT_SHARED_DATA_ANDROID = 'INIT_SHARED_DATA_ANDROID';
export const NEW_SHARED_DATA_ANDROID = 'NEW_SHARED_DATA_ANDROID';
export const DISPLAY_SHARED_DATA_ANDROID = 'DISPLAY_SHARED_DATA_ANDROID';
export const CLEAR_SHARED_DATA_ANDROID = 'CLEAR_SHARED_DATA_ANDROID';
export const SHOW_SHARE_MODAL_ANDROID = 'SHOW_SHARE_MODAL_ANDROID';
export const SET_SHOW_SHARE_LOADING_ANDROID = 'SET_SHOW_SHARE_LOADING_ANDROID';

export const ERROR_MAXIMUM_FILESIZE_EXCEEDED = 'You are uploading a file that is too large!';
export const ERROR_UPLOAD_FAIL = 'Upload failed! Try again!';

export const APP_STORE_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/panda-f5718.appspot.com/o/assets%2Ficon-small-50%402x.png?alt=media&token=1e556404-502b-4732-aa78-3a4cee415a57';
export const BLANK_PROFILE_PHOTO = 'https://s3-us-west-1.amazonaws.com/panda-public/blank_profile_picture.png';

export const LOGIN_FAILED_URL = '/?login-failed=true';

// eslint-disable-next-line
export const APP_STORE_LINK = process.env.APP_STORE_LINK;
// eslint-disable-next-line
export const PLAY_STORE_LINK = process.env.PLAY_STORE_LINK;


export const LOGO_URI =
    'https://firebasestorage.googleapis.com/v0/b/panda-f5718.appspot.com/o/assets%2Ficon-83.5%402x.png?alt=media&token=e32a2497-1e5b-4a80-84ee-a211a2b6fe54';

export const CONVERSATION_PAGINATION_SIZE = 5;

export const ONLINE_AVAILABLE_DELAY = 10;

export const LOCAL_FILES = {
    get google_logo() {
        return `${global.STATIC_ROUTE || ''}/static/google-logo-icon-transparent.png`;
    },
};

let fontScale = 1.0;
export const getFontScale = () => fontScale;
export const updateFontScale = () => {
    if (!process.env.WEB) {
        // eslint-disable-next-line
        const { getFontScale } = require('react-native-device-info');
        getFontScale().then((scale) => { fontScale = scale; });
    }
};

// To change the Server URI to localhost
// Add SERVER_URI_OVERRIDE=http://localhost:2032/ to the web/.env file
let globalURI = process.env.SERVER_URI_OVERRIDE || 'https://api.dev.pandamessage.com/1.0/';
// console.log('SERVER=', globalURI);
export const serverInfo = {
    async getRoot() {
        if (globalURI) {
            // remove the last '/'
            return globalURI.slice(0, -1);
        }

        // remove the last '/'
        globalURI = (await db.getServerURI()).slice(0, -1);
        return globalURI;
    },
};

import * as methods from '../app.constants';


/**
 * Decrypt Message Modal
 */
export const OpenDecryptMessageModalAction = newState => ({ type: methods.OPEN_DECRYPT_MESSAGE_MODAL, newState });
export const CloseDecryptMessageModalAction = newState => ({ type: methods.CLOSE_DECRYPT_MESSAGE_MODAL, newState });
export const UpdateDecryptPasswordAction = newState => ({ type: methods.UPDATE_DECRYPT_PASSWORD, newState });
export const DecryptMessageAction = () => ({ type: methods.DECRYPT_MESSAGE });
export const DecryptMessageSuccessAction = newState => ({ type: methods.DECRYPT_MESSAGE_SUCCESS, newState });
export const DecryptMessageFailAction = newState => ({ type: methods.DECRYPT_MESSAGE_FAIL, newState });

// Encrypt Modal
export const SetBlackSMSOptionsVisibilityAction = () => ({ type: methods.SET_BLACK_SMS_OPTIONS_VISIBILITY });
// export const ParseMessageAction = newState => ({ type: methods.PARSE_MESSAGE, newState });
export const UpdateMessageToSendAction = newState => ({ type: methods.UPDATE_MESSAGE_TO_SEND, newState });
export const SetBlackSMSModeAction = newState => ({ type: methods.SET_BLACK_SMS_MODE, newState });
export const SetBlackSMSPasswordAction = newState => ({ type: methods.SET_BLACK_SMS_PASSWORD, newState });

/**
 * Send Text To Sign In Modal
 */
export const OpenSendTextToSignInModalAction = () => ({ type: methods.OPEN_SEND_TEXT_TO_SIGN_IN_MODAL });
export const CloseSendTextToSignInModalAction = () => ({ type: methods.CLOSE_SEND_TEXT_TO_SIGN_IN_MODAL });
export const SendSignInTextAction = newState => ({ type: methods.SEND_SIGN_IN_TEXT, newState });
export const SendSignInTextSuccessAction = () => ({ type: methods.SEND_SIGN_IN_TEXT_SUCCESS });
export const SendSignInTextFailAction = () => ({ type: methods.SEND_SIGN_IN_TEXT_FAILED });
export const UpdateSendTextPhoneNumberAction = newState => ({ type: methods.UPDATE_SEND_TEXT_PHONE_NUMBER, newState });

/**
 * Leave Conversation Modal
 */
export const AskToLeaveConversationModalAction = () => ({ type: methods.OPEN_ASK_TO_LEAVE_CONFIRMATION_MODAL });
export const AskToLeaveConversationModalCloseAction = () => ({ type: methods.CLOSE_ASK_TO_LEAVE_CONFIRMATION_MODAL });
export const LeaveConversationAction = newState => ({ type: methods.LEAVE_CONVERSATION, newState });
export const LeaveEmptyConversationAction = newState => ({ type: methods.LEAVE_EMPTY_CONVERSATION, newState });
export const LeaveConversationSuccessAction = newState => ({ type: methods.LEAVE_CONVERSATION_SUCCESS, newState });

/**
 * Update Current Conversation Title
 */
export const OpenUpdateCurrentConversationModalAction =
    newState => ({ type: methods.OPEN_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL, newState });
export const CloseUpdateCurrentConversationModalAction = () => ({ type: methods.CLOSE_UPDATE_CURRENT_CONVERSATION_TITLE_MODAL });
export const UpdateNextConversationNameAction = newState => ({ type: methods.UPDATE_NEXT_CONVERSATION_NAME_ACTION, newState });

/**
 * Manage Participants Modal
 */
export const OpenManageParticipantsModalAction = () => ({ type: methods.OPEN_MANAGE_PARTICIPANTS_MODAL });
export const CloseManageParticipantsModalAction = () => ({ type: methods.CLOSE_MANAGE_PARTICIPANTS_MODAL });
export const UpdateNextUserToAddValueAction = newState => ({ type: methods.UPDATE_NEXT_USER_TO_ADD_VALUE, newState });
export const RemoveParticipantFromGroupAction = newState => ({ type: methods.REMOVE_PARTICIPANT_FROM_GROUP, newState });
export const RemoveParticipantFromGroupSuccessAction = newState => ({ type: methods.REMOVE_PARTICIPANT_FROM_GROUP_SUCCESS, newState });
export const RemoveParticipantFromGroupFailAction = newState => ({ type: methods.REMOVE_PARTICIPANT_FROM_GROUP_FAIL, newState });
export const AddParticipantToGroupAction = newState => ({ type: methods.ADD_PARTICIPANT_TO_GROUP, newState });
export const AddParticipantToGroupSuccessAction = newState => ({ type: methods.ADD_PARTICIPANT_TO_GROUP_SUCCESS, newState });
export const AddParticipantToGroupFailAction = newState => ({ type: methods.ADD_PARTICIPANT_TO_GROUP_FAIL, newState });
/**
 * Onboarding Modal
 */

export const OpenOnboardingModalAction = () => ({ type: methods.OPEN_ONBOARDING_MODAL });
export const CloseOnboardingModalAction = () => ({ type: methods.CLOSE_ONBOARDING_MODAL });
export const GoToNextOnboardingStepAction = () => ({ type: methods.GOTO_NEXT_ONBOARDING_STEP });
export const SetNextOnboardingStepAction = newState => ({ type: methods.SET_NEXT_ONBOARDING_STEP, newState });
export const SendVerificationTextAction = newState => ({ type: methods.SEND_VERIFICATION_TEXT, newState });
export const SendVerificationTextActionV2 = newState => ({ type: methods.SEND_VERIFICATION_TEXT_V2, newState });
export const SendVerificationTextSuccessAction = newState => ({ type: methods.SEND_VERIFICATION_TEXT_SUCCESS, newState });
export const SendVerificationTextFailAction = newState => ({ type: methods.SEND_VERIFICATION_TEXT_FAIL, newState });
export const VerifyVerificationCodeAction = newState => ({ type: methods.VERIFY_VERIFICATION_CODE, newState });
export const VerifyVerificationCodeSuccessAction = () => ({ type: methods.VERIFY_VERIFICATION_CODE_SUCCESS });
export const VerifyVerificationCodeFailAction = newState => ({ type: methods.VERIFY_VERIFICATION_CODE_FAIL, newState });

export const AskToVerifyNumberModalAction = () => ({ type: methods.OPEN_ASK_TO_VERIFY_NUMBER_MODAL });
export const AskToVerifyNumberModalCloseAction = () => ({ type: methods.CLOSE_ASK_TO_VERIFY_NUMBER_MODAL });

/**
 * Announcement Modal
 */
export const OpenAnnouncementModalAction = () => ({ type: methods.OPEN_ANNOUNCEMENT_MODAL });
export const CloseAnnouncementModalAction = () => ({ type: methods.CLOSE_ANNOUNCEMENT_MODAL });

/**
 * Edit Profile Modal
 */
export const OpenEditProfileModalAction = () => ({ type: methods.OPEN_EDIT_PROFILE_MODAL });
export const CloseEditProfileModalAction = () => ({ type: methods.CLOSE_EDIT_PROFILE_MODAL });

/**
 * Edit Settings Modal
 */
export const OpenEditSettingsModalAction = () => ({ type: methods.OPEN_EDIT_SETTINGS_MODAL });
export const CloseEditSettingsModalAction = () => ({ type: methods.CLOSE_EDIT_SETTINGS_MODAL });
export const fetchUserIdentifiersAction = () => ({ type: methods.FETCH_USER_IDENTIFIERS });
export const fetchUserIdentifiersSuccessAction = newState => ({ type: methods.FETCH_USER_IDENTIFIERS_SUCCESS, newState });
export const fetchUserIdentifiersFailureAction = newState => ({ type: methods.FETCH_USER_IDENTIFIERS_FAILURE, newState });
export const resetUserIdentifiersStateAction = () => ({ type: methods.RESET_USER_IDENTIFIERS_STATE });
/**
 * Add contacts modal
 */
export const OpenAddContactsModalAction = () => ({ type: methods.OPEN_ADD_CONTACTS_MODAL });
export const CloseAddContactsModalAction = () => ({ type: methods.CLOSE_ADD_CONTACTS_MODAL });

/**
 * Add password modal
 */

export const OpenAddPasswordModalAction = newState => ({ type: methods.OPEN_ADD_PASSWORD_MODAL, newState });
export const CloseAddPasswordModalAction = () => ({ type: methods.CLOSE_ADD_PASSWORD_MODAL });
export const AddPasswordToUserAccountAction = newState => ({ type: methods.ADD_PASSWORD_TO_USER_ACCOUNT, newState });
export const AddPasswordToUserAccountSuccessAction = () => ({ type: methods.ADD_PASSWORD_TO_USER_ACCOUNT_SUCCESS });
export const AddPasswordToUserAccountFailAction = newState => ({ type: methods.ADD_PASSWORD_TO_USER_ACCOUNT_FAIL, newState });

/**
 * Compose Modal
 */

export const OpenComposerAction = newState => ({ type: methods.OPEN_COMPOSER, newState });

export const CloseComposerAction = newState => ({ type: methods.CLOSE_COMPOSER, newState });
export const ResetComposerAction = () => ({ type: methods.RESET_COMPOSER });
export const FocusInputAction = () => ({ type: methods.FOCUS_INPUT });
export const FocusInputCompleteAction = () => ({ type: methods.FOCUS_INPUT_COMPLETE });

/**
 * Confirm Conversation Creation
 */

export const OpenConfirmConversationOverrideAction = newState => ({ type: methods.OPEN_CONFIRM_CONVERSATION_OVERRIDE, newState });

export const CloseConfirmConversationCreationAction = () => ({ type: methods.CLOSE_CONFIRM_CONVERSATION_OVERRIDE });

/**
 * Alert Modal
 */
export const OpenAlertModalAction = newState => ({ type: methods.OPEN_ALERT_MODAL, newState });

export const CloseAlertModalAction = () => ({ type: methods.CLOSE_ALERT_MODAL });

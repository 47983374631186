import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/withLatestFrom';
import { oneForOne, superviseEpics } from '@mixer/epic-supervisor';
import * as methods from '../app.constants';
import * as actions from '../app.actions';
import { fetchAndroidContacts, setDefaultURL } from './auth.helpers';
import { checkIfValidEmail, checkIfValidPhoneNumber, validateUnknownIdentifier } from '../messenger/messenger.helpers';
import { localStorage, storage } from '../app.services';
import { login, loginV2 } from './auth.firebase';
import * as services from './auth.services';
import * as db from '../app.firebase';
import { getCurrentURLQueryString, updatePageTitle, getConversationIdFromUrl } from '../app.helpers';
import * as analytics from '../app.analytics';
import { Sentry, router, electron } from '../app.cross-platform';
import { flushCache, loadAuthCache } from './auth.cache';
import { loadCache } from '../messenger/messenger.cache';

export const initializeApplication = action$ =>
    action$.ofType(methods.INITIALIZE_APP)
        // initialize plugins
        .do(() => {
            if (process.env.ELECTRON) {
                // eslint-disable-next-line
                const win = electron().remote.getCurrentWindow();
                win.show();
            }

            analytics.init();
        })
        // check if the user is cached in our storage
        .switchMap(() => db.observeAuthStateChange()
            .take(1)
            .switchMap(user => Observable.defer(async () => {
                if (user) {
                    const authCache = await loadAuthCache();
                    if (authCache.user && !process.env.DISABLE_CACHE_LOAD) {
                        let conversationCache = await loadCache();
                        let paramId;
                        if (process.env.WEB) {
                            paramId = getConversationIdFromUrl();
                        }
                        if (paramId && paramId !== 'default' && paramId !== 'magic' && paramId !== 'new') {
                            // TODO: Is this .get() valid?
                            // eslint-disable-next-line
                            conversationCache = { ...conversationCache, currentConversation: conversationCache.conversations?.get(paramId) };
                        }
                        return actions.LoadFromCacheAction({ auth: authCache, messenger: conversationCache });
                    }

                    if (user) {
                        return actions.LoginUserAction({ type: 'session' });
                    }

                    return actions.LogoutUserAction();
                }

                // user is not logged in
                return actions.ClearLoadingInitialAuthStateAction();
            })));

export const redirectToHomeOnClearAuthState = action$ =>
    action$.ofType(methods.CLEAR_LOADING_INITIAL_AUTH_STATE)
        .do(() => flushCache())
        .filter(() => !(process.env.WEB && !window.location.pathname.includes('/u/')))
        .map(() => {
            return actions.UpdateRouterLocationAction({
                web: (() => {
                    if (window.location.pathname.includes('/u/')) {
                        return '/';
                    }

                    return window.location.pathname;
                })(),
                desktop: '/login',
                native: 'Home',
            });
        });

export const checkForMergeAccounts = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .filter(() => window.location.pathname.includes('magic'))
        .map(() => getCurrentURLQueryString())
        .filter(({ email, uid, token }) => email && uid && token && uid !== getState().auth.user.uid)
        .map(() => {
            const { email, uid, token } = getCurrentURLQueryString();

            return actions.SetPreMergeDataAction({
                email: email.replace(' ', '+'),
                uid,
                token,
            });
        });

export const loadAndroidContacts = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .filter(() => !process.env.WEB)
        .switchMap(() => {
            // eslint-disable-next-line
            const uid = getState().auth?.user?.uid;
            if (uid) {
                return Observable.from(fetchAndroidContacts()).flatMap((userContacts) => {
                    services.updateContacts(uid, userContacts).catch((err) => {
                        // TODO: IN this case we are essentially going to be replacing the state with the Android contacts without getting an update back from Firebase with the merged contacts. Therefore, in this case we should merge the contact list on the client
                        Sentry.captureException(err);
                    });
                    return Observable.of(actions.UpdateContactListAction(userContacts));
                })
                    .catch((err) => {
                        return Observable.of(actions.SilentErrorAction({ message: err.message, stackTrace: err.stack }));
                    });
            }
            return Observable.of(actions.SilentErrorAction({ message: 'No uid available' }));
        });

export const checkIfUserIsOnline = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .switchMap(() => {
            return db.observeOnlineStatus()
                .takeUntil(action$.ofType(methods.LOGOUT_USER))
                .filter(snapshot => !!snapshot.val())
                .map(() => getState().auth.user)
                .switchMap(({ uid }) => db
                    .modifyOnlineStatus(uid)
                    .filter(() => false));
        });


export const loginUser = action$ =>
    action$.ofType(methods.LOGIN_USER, methods.LOGIN_USER_V2)
        .do(() => {
            if (process.env.ELECTRON) {
                // eslint-disable-next-line
                const win = electron().remote.getCurrentWindow();
                win.setSize(400, 400);
                win.center();
            }
        })
        .switchMap(({ type: actionType, newState: { type, data, isOnboarding } }) => {
            const isV2 = actionType === methods.LOGIN_USER_V2;
            const loginFunc = isV2 ? loginV2 : login;

            return loginFunc(type, data)
                .map((user) => {
                    if (!user.meta.web && process.env.WEB) {
                        return { ...user, meta: { ...user.meta, web: {} } };
                    }
                    return user;
                })
                .map(user => actions.LoginUserSuccessAction({ ...user, isOnboarding: (isOnboarding || !process.env.WEB) }))
                .catch((error) => {
                    if (!error || !error.response) {
                        // eslint-disable-next-line
                        process.env.ENV === 'DEV' && console.log(error);

                        const errorMessage = 'Unspecified error, please contact support!';
                        return Observable.of(actions.LoginUserFailedAction({ errorMessage, type, isOnboarding }));
                    }

                    const { email } = data;
                    const { data: res } = error.response;
                    const errorCode = res.code || res.error;
                    const errorMessage = methods.authErrorCodes[errorCode]
                        || 'Internal Server Error (code: 101)';
                    return Observable.of(actions.LoginUserFailedAction({
                        isOnboarding,
                        errorMessage,
                        type,
                        errorCode,
                        errorEmail: email,
                    }));
                });
        });

export const redirectUserOnLoginFailForMagic = action$ =>
    action$.ofType(methods.LOGIN_USER_FAILED)
        .filter(({ newState: { type, isOnboarding } }) => type !== 'password' && !isOnboarding)
        .map(() => {
            return actions.UpdateRouterLocationAction({
                web: '/',
                desktop: '/login',
                native: 'Home',
            });
        });

export const updateUserProfile = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_USER_DATA)
        .map(({ newState: user }) => ({ ...getState().auth.user, ...user }))
        .switchMap(user => storage
            .setFromObservable('user', user)
            .map(() => actions.UpdateUserDataSuccessAction(user)));

export const mergeAccounts = (action$, { getState }) =>
    action$.ofType(methods.MERGE_ACCOUNTS)
        .switchMap(() => {
            const {
                preMergeData: {
                    email, phone, token, code,
                }, user: { uid },
            } = getState().auth;

            return Observable.defer(() => services.mergeAccount(uid, email, phone, token, code))
                .map(() => actions.MergeAccountsSuccessAction())
                .catch(() => Observable.of(actions.MergeAccountsFailedAction()));
        });

export const mergeAccountsSuccess = (actions$, { getState }) =>
    actions$.ofType(methods.MERGE_ACCOUNTS_SUCCESS)
        .do(() => !getState().auth.preventReload && window.location.reload())
        .map(() => actions.SyncUserAndConversationsAction())
        .map(() => actions.SilentErrorAction());


export const logoutUser = action$ =>
    action$.ofType(methods.LOGOUT_USER)
        .do(() => {
            flushCache();
            updatePageTitle();
        })
        .switchMap(() => db.logoutUser()
            .do(() => {
                if (process.env.WEB) {
                    localStorage.remove('logged-in');
                    return;
                }

                router.next('Home');
            })
            .map(() => actions.LogoutUserSuccessAction()));

export const redirectUserAfterLogin = action$ =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .filter(() => {
            if (!process.env.WEB) {
                return true;
            }

            return window.location.pathname === '/' || window.location.pathname.includes('/magic');
        })
        .map(({ newState: { isOnboarding } }) => actions.UpdateRouterLocationAction({
            web: setDefaultURL(),
            native: isOnboarding ? 'BuildProfileView' : 'App',
        }));

export const saveProfile = (action$, { getState }) =>
    action$.ofType(methods.SAVE_PROFILE)
        .switchMap(({ newState: isOnboarding = false }) => {
            const { tempUser, user } = getState().auth;
            const nextUser = {
                ...user,
                credentials: {
                    ...user.credentials,
                    ...tempUser,
                },
            };

            return Observable.forkJoin(
                db.updateProfile({ uid: nextUser.uid, ...nextUser.credentials }),
                storage.setFromObservable('user', nextUser),
            )
                .map(() => actions.SaveProfileSuccessAction({ nextUser, isOnboarding }));
        });

export const saveSettings = (action$, { getState }) =>
    action$.ofType(methods.SAVE_SETTINGS)
        .switchMap(({ newState: nextSettings }) => {
            const { user: { uid } } = getState().auth;

            return db.updateSettings(uid, nextSettings)
                .map(() => actions.SaveSettingsSuccessAction({ nextSettings }));
        });

export const sentLoginLink = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_EMAIL)
        .switchMap(({ newState: { email, options } }) => {
            if (!checkIfValidEmail(email)) {
                return Observable.of(actions.SendLoginLinkEmailFailAction('Your email was entered incorrectly!'));
            }

            return db.sendLoginLinkEmail(email, { desktop: !!process.env.ELECTRON, ...options })
                .map(({ data: { status } }) => actions.SendLoginLinkEmailSuccessAction({ status, email }))
                .catch((e) => {
                    if (e && e.response && e.response.data && e.response.data.title === 'Member Exists') {
                        return Observable.of(actions.SendLoginLinkEmailFailAction('You\'re already on our waiting list!'));
                    }
                    return Observable.of(actions.SendLoginLinkEmailFailAction('There was an error on our side - we shall fix this!'));
                });
        });

export const sentLoginLinkV2 = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_EMAIL_V2)
        .switchMap(({ newState: { identifier, options } }) => {
            const realIdentifier = validateUnknownIdentifier(identifier);
            if (!realIdentifier) {
                return Observable.of(actions.SendLoginLinkEmailFailAction('Your email or phone was entered incorrectly!'));
            }

            return db.sendLoginLinkEmailV2(realIdentifier.email || realIdentifier.phone, { desktop: !!process.env.ELECTRON, replaceBaseURL: process.env.MAGIC_LINK_BASE, ...options })
                .map(({ data: { status } }) => actions.SendLoginLinkEmailSuccessAction({ status, ...realIdentifier }))
                .catch((e) => {
                    if (e && e.response && e.response.data && e.response.data.title === 'Member Exists') {
                        return Observable.of(actions.SendLoginLinkEmailFailAction('You\'re already on our waiting list!'));
                    }
                    return Observable.of(actions.SendLoginLinkEmailFailAction('There was an error on our side - we shall fix this!'));
                });
        });

export const sentLoginText = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_TEXT)
        .switchMap(({ newState: phone }) => {
            if (!checkIfValidPhoneNumber(phone)) {
                return Observable.of(actions.SendLoginLinkTextFailAction('Your phone number was entered incorrectly!'));
            }

            return db.sendLoginLinkText(phone)
                .map(({ data: { status } }) => actions.SendLoginLinkTextSuccessAction(status))
                .catch((e) => {
                    if (e && e.response && e.response.data && e.response.data.error === 'invalid-phone-number') {
                        return Observable.of(actions.SendLoginLinkEmailFailAction('Phone number isn\'t supported!'));
                    }
                    return Observable.of(actions.SendLoginLinkTextFailAction('There was an error on our side - we shall fix this!'));
                });
        });

export const sentLoginTextV2 = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_TEXT_V2)
        .switchMap(({ newState: { identifier, options } }) => {
            const realIdentifier = validateUnknownIdentifier(identifier);
            if (!realIdentifier) {
                return Observable.of(actions.SendLoginLinkEmailFailAction('Your email or phone was entered incorrectly!'));
            }
            return db.sendLoginLinkTextV2(realIdentifier.email || realIdentifier.phone, { ...options })
                .map(({ data: { status } }) => actions.SendLoginLinkTextSuccessAction(status))
                .catch((e) => {
                    if (e && e.response && e.response.data && e.response.data.error === 'invalid-phone-number') {
                        return Observable.of(actions.SendLoginLinkEmailFailAction('Phone number isn\'t supported!'));
                    }
                    return Observable.of(actions.SendLoginLinkTextFailAction('There was an error on our side - we shall fix this!'));
                });
        });

export const sentLoginTextSuccess = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_TEXT_SUCCESS)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'TextSignInStepTwo' }));

export const sentLoginEmailSuccess = action$ =>
    action$.ofType(methods.SEND_LOGIN_LINK_EMAIL_SUCCESS)
        .filter(() => !process.env.WEB)
        .map(({ newState: { email } }) => actions.UpdateRouterLocationAction({ native: ['EmailSignInStepTwo', { email }] }));

export const editUserProfile = action$ =>
    action$.ofType(methods.EDIT_USER_PROFILE)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'MessengerUserProfile' }));

export const magicLinkCodeSendSuccessful = action$ =>
    action$.ofType(methods.MAGIC_LINK_CODE_SEND_SUCCESSFUL)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'App' }));

export const magicLinkCodeSendUnsuccessful = action$ =>
    action$.ofType(methods.MAGIC_LINK_CODE_SEND_UNSUCCESSFUL)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'App' }));

export const magicCodeLinkMatchSuccessful = action$ =>
    action$.ofType(methods.MAGIC_CODE_LINK_MATCH_SUCCESSFUL)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'App' }));

export const magicCodeLinkMatchUnsuccessful = action$ =>
    action$.ofType(methods.MAGIC_CODE_LINK_MATCH_UNSUCCESSFUL)
        .filter(() => !process.env.WEB)
        .map(() => actions.UpdateRouterLocationAction({ native: 'App' }));

export const updateMetadataAttribueValue = (action$, { getState }) =>
    action$.ofType(methods.UPDATE_METADATA_ATTRIBUTE_VALUE)
        .switchMap(({ newState: { name, value } }) => db.updateMetadataAttributeValue(getState().auth.user.uid, name, value)
            .map(() => actions.DummyAction()));

export const updateOnlineFocused = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .map(() => {
            db.modifyOnlineFocusStatus(getState().auth.user.uid, document.hidden ? null : true);
            return null;
        })
        .filter(() => false);

const resetOnboardingModalStateAfterLogin = action$ =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .filter(({ newState: { isOnboarding } }) => isOnboarding)
        .map(() => actions.ResetOnboardingModalState());

const cancelOnboardingWithFromParam = action$ =>
    action$.ofType(methods.LOGIN_USER_SUCCESS)
        .filter(() => getCurrentURLQueryString().from === '-message')
        .map(() => actions.CloseOnboardingModalAction());

const changePassword = action$ =>
    action$.ofType(methods.CHANGE_PASSWORD)
        .switchMap(({ newState: { oldPassword, newPassword } }) => {
            return Observable.defer(() => services.changePassword(oldPassword, newPassword).then((res) => {
                return actions.ChangePasswordSuccessAction();
            })
                .catch(({ response: { statusText } }) => {
                    return actions.ChangePasswordFailedAction(statusText);
                }));
        });

const saveProfilePhoto = action$ =>
    action$.ofType(methods.SAVE_PROFILE_PICTURE)
        .switchMap(({ newState }) => {
            return Observable.defer(async () => {
                await db.saveProfilePicture(newState);
                return actions.SaveProfilePictureSuccessAction(newState);
            });
        });

const options = {
    restart: oneForOne,
    onError: ({ epicName, error }, actions, state, services) => {
        // Send another action when the error occurs:
        console.error(`An error occurred in epic ${epicName}`, error);
        // Wait a second before restarting epics:
        return Observable.of('RESTARTED');
    },
};

export const homeEpic = superviseEpics(
    options,
    logoutUser,
    loginUser,
    saveProfile,
    saveSettings,
    updateUserProfile,
    saveProfilePhoto,
    sentLoginLink,
    sentLoginLinkV2,
    initializeApplication,
    updateMetadataAttribueValue,
    checkIfUserIsOnline,
    mergeAccounts,
    redirectToHomeOnClearAuthState,
    redirectUserOnLoginFailForMagic,
    checkForMergeAccounts, // this function must be fired before switchToMessengerOnValidated
    mergeAccountsSuccess,
    redirectUserAfterLogin,
    sentLoginText,
    sentLoginTextV2,
    sentLoginEmailSuccess,
    sentLoginTextSuccess,
    editUserProfile,
    updateOnlineFocused,
    loadAndroidContacts,
    resetOnboardingModalStateAfterLogin,
    cancelOnboardingWithFromParam,
    changePassword,
);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-electron-drag-header-drag-header__drag_header--3izo9{-webkit-app-region:drag;width:100%;height:20px;position:fixed;background-color:transparent;z-index:99999;pointer-events:none;display:flex}.src-electron-drag-header-drag-header__buttons--2X0OW{pointer-events:all;display:flex;flex-direction:row;margin-left:15px;margin-top:8px}.src-electron-drag-header-drag-header__buttons--2X0OW .src-electron-drag-header-drag-header__button--3NmOV{margin-right:10px;width:15px;border-radius:10px;height:5px;font-size:0.7em;display:flex;flex-direction:row;justify-content:center;align-items:center;cursor:pointer;text-align:center}.src-electron-drag-header-drag-header__buttons--2X0OW .src-electron-drag-header-drag-header__button--3NmOV>*{display:none}.src-electron-drag-header-drag-header__buttons--2X0OW .src-electron-drag-header-drag-header__button--3NmOV:last-child{margin:0px}.src-electron-drag-header-drag-header__buttons--2X0OW:not(:hover) .src-electron-drag-header-drag-header__button--3NmOV{background-color:#c7c7c7 !important}\n", ""]);

// exports
exports.locals = {
	"drag_header": "src-electron-drag-header-drag-header__drag_header--3izo9",
	"buttons": "src-electron-drag-header-drag-header__buttons--2X0OW",
	"button": "src-electron-drag-header-drag-header__button--3NmOV"
};
import React from 'react';
import * as styles from './content-blob.scss';

export const ContentBlob = ({ icon, title, children }) => {
    return (
        <article>
            <div className={styles.blob_icon}>
                <i className={icon} />
            </div>
            <h5 className={styles.blob_title}>{title}</h5>
            <p>
                {children}
            </p>
        </article>
    );
};

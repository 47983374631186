import { create } from 'rxjs-spy';
import { Observable, BehaviorSubject } from 'rxjs';
import { combineReducers, applyMiddleware, createStore } from 'redux';
import reduxCatch from 'redux-catch';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { createEpicMiddleware, ofType } from 'redux-observable';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { authReducer } from './auth/auth.reducer';
import { Sentry } from './app.cross-platform';
import { messengerReducer } from './messenger/messenger.reducer';
import { modalReducer } from './modals/modal.reducer';
import rootEpic from './app.epic';
import * as methods from './app.constants';

Observable.prototype.log = (message) => {
    // eslint-disable-next-line
    return this.do(x => console.log(message, x));
};

const reducer = combineReducers({
    auth: authReducer,
    messenger: messengerReducer,
    modal: modalReducer,
});

let traceFlag = false;
if (process.env.TRACE_REDUX) {
    traceFlag = true;
}
const composeEnhancers = composeWithDevTools({ serialize: true, trace: traceFlag, traceLimit: 25 });

const epic$ = new BehaviorSubject(rootEpic);

const hotReloadingEpic = (action$, ...rest) =>
    epic$.pipe(mergeMap(epic =>
        epic(action$, ...rest).pipe(
            catchError((error, source$) => {
                console.error('Shutting down application due to critical error:', error);
                return Observable.of({
                    type: 'FAILED_EPIC',
                    location: 'ROOT',
                    source$,
                    error,
                });
            }),
            takeUntil(action$.pipe(ofType('HOT_RELOAD_EPICS'))),
        )));

const epicMiddleware = createEpicMiddleware(hotReloadingEpic);
const isDev = (process.env.ENV === 'DEV' || process.env.FORCE_DEV);

const errorHandler = (error, getState, lastAction, dispatch) => {
    Sentry.captureException(error);
    console.error(error);
    dispatch({ type: methods.CRITICAL_ERROR, newState: error });
};

if (isDev) create(); // rxjs-spy

export const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(
        epicMiddleware,
        reduxCatch(errorHandler),
        ...(isDev && !process.env.DISABLE_REDUX_LOG ? [logger] : []),
    )),
);

if (module.hot) {
    module.hot.accept('./app.epic', () => {
        // eslint-disable-next-line
        const nextRootEpic = require('./app.epic').default;
        // First kill any running epics
        store.dispatch({ type: 'HOT_RELOAD_EPICS' });
        // Now setup the new one
        epic$.next(nextRootEpic);
    });
}

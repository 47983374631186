export const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST';
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const SET_CURRENT_CONVERSATION_AS_READ = 'SET_CURRENT_CONVERSATION_AS_READ';
export const SET_CURRENT_CONVERSATION_AS_READ_SUCCESS = 'SET_CURRENT_CONVERSATION_AS_READ_SUCCESS';
export const SET_OVERRIDDEN_CONVERSATION = 'SET_OVERRIDDEN_CONVERSATION';
export const LOAD_OVERRIDDEN_CONVERSATION = 'LOAD_OVERRIDDEN_CONVERSATION';
export const START_EMPTY_CONVERSATION = 'START_EMPTY_CONVERSATION';
export const CREATE_EMPTY_CONVERSATION = 'CREATE_EMPTY_CONVERSATION';
export const ANDROID_CREATE_EMPTY_CONVERSATION = 'ANDROID_CREATE_EMPTY_CONVERSATION';
export const UPDATE_CURRENT_CONVERSATION_TITLE = 'UPDATE_CURRENT_CONVERSATION_TITLE';
export const UPDATE_CURRENT_CONVERSATION_TITLE_SUCCESS = 'UPDATE_CURRENT_CONVERSATION_TITLE_SUCCESS';
export const UPDATE_CURRENT_CONVERSATION_PHOTO = 'UPDATE_CURRENT_CONVERSATION_PHOTO';
export const UPLOAD_CURRENT_CONVERSATION_PHOTO = 'UPLOAD_CURRENT_CONVERSATION_PHOTO';
export const UPDATE_CURRENT_CONVERSATION_PHOTO_SUCCESS = 'UPDATE_CURRENT_CONVERSATION_PHOTO_SUCCESS';
export const UPDATE_CURRENT_CONVERSATION_PARTICIPANTS = 'UPDATE_CURRENT_CONVERSATION_PARTICIPANTS';
export const UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS = 'UPDATE_CURRENT_CONVERSATION_PARTICIPANTS_SUCCESS';
export const MUTE_CONVERSATION = 'MUTE_CONVERSATION';
export const MUTE_CONVERSATION_SUCCESS = 'MUTE_CONVERSATION_SUCCESS';
export const UNMUTE_CONVERSATION = 'UNMUTE_CONVERSATION';
export const UNMUTE_CONVERSATION_SUCCESS = 'UNMUTE_CONVERSATION_SUCCESS';
export const ENABLE_RIGHT_SIDEBAR = 'ENABLE_RIGHT_SIDEBAR';
export const OPEN_USER_PROFILE = 'OPEN_USER_PROFILE';
export const DISABLE_RIGHT_SIDEBAR = 'DISABLE_RIGHT_SIDEBAR';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const REQUEST_NOTIFICATION_PERMISSION = 'REQUEST_NOTIFICATION_PERMISSION';
export const SILENT_ERROR = 'SILENT_ERROR';
export const START_CONVERSATIONS_LISTENER = 'START_CONVERSATIONS_LISTENER';
export const START_CONVERSATIONS_LISTENER_SUCCESS = 'START_CONVERSATIONS_LISTENER_SUCCESS';
export const START_CONVERSATION_LISTENER = 'START_CONVERSATION_LISTENER';
export const START_CONVERSATION_LISTENER_SUCCESS = 'START_CONVERSATION_LISTENER_SUCCESS';
export const PAGINATE_MESSAGES = 'PAGINATE_MESSAGES';
export const PAGINATE_MESSAGES_FAIL = 'PAGINATE_MESSAGES_FAIL';
export const PAGINATE_MESSAGES_SUCCESS = 'PAGINATE_MESSAGES_SUCCESS';
export const PAGINATE_CONVERSATIONS = 'PAGINATE_CONVERSATIONS';
export const PAGINATE_CONVERSATIONS_SUCCESS = 'PAGINATE_CONVERSATIONS_SUCCESS';
export const PAGINATE_EVENTS = 'PAGINATE_EVENTS';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_FAIL = 'CREATE_CONVERSATION_FAIL';
export const SET_LIGHTBOX_STATE = 'SET_LIGHTBOX_STATE';
export const ALL_MESSAGES_LOADED = 'ALL_MESSAGES_LOADED';
export const SET_USER_IS_NEW = 'SET_USER_IS_NEW';
export const UPDATE_STORAGE_SUCCESS = 'UPDATE_STORAGE_SUCCESS';
export const UPDATE_CONVERSATION_STORAGE = 'UPDATE_CONVERSATION_STORAGE';
export const UPDATE_CURRENT_CONVERSATION_TAGS = 'UPDATE_CURRENT_CONVERSATION_TAGS';
export const UPDATE_CURRENT_CONVERSATION_TAGS_SUCCESS = 'UPDATE_CURRENT_CONVERSATION_TAGS_SUCCESS';
export const ADD_TAG_TO_MESSAGE = 'ADD_TAG_TO_MESSAGE';
export const ADD_TAG_TO_MESSAGE_UI_SUCCESS = 'ADD_TAG_TO_MESSAGE_UI_SUCCESS';
export const ADD_TAG_TO_MESSAGE_SUCCESS = 'ADD_TAG_TO_MESSAGE_SUCCESS';
export const START_SINGLE_CONVERSATION_WITH_ID = 'START_SINGLE_CONVERSATION_WITH_ID';
export const APPEND_DECRYPTED_MESSAGE_OBJECT = 'APPEND_DECRYPTED_MESSAGE_OBJECT';
export const CLEAR_DECRYPTED_MESSAGE_OBJECT = 'CLEAR_DECRYPTED_MESSAGE_OBJECT';
export const SPLICE_DECRYPTED_MESSAGE_OBJECT = 'SPLICE_DECRYPTED_MESSAGE_OBJECT';
export const LOAD_CURRENT_CONVERSATION = 'LOAD_CURRENT_CONVERSATION';
export const LOAD_CURRENT_CONVERSATION_SUCCESS = 'LOAD_CURRENT_CONVERSATION_SUCCESS';
export const UPDATE_CONVERSATION_INFO_SUCCESS = 'UPDATE_CONVERSATION_INFO_SUCCESS';
export const OPEN_ADD_TAG_TO_CURRENT_MESSAGE_MODAL = 'OPEN_ADD_TAG_TO_CURRENT_MESSAGE_MODAL';
export const CLOSE_ADD_TAG_TO_CURRENT_MESSAGE_MODAL = 'CLOSE_ADD_TAG_TO_CURRENT_MESSAGE_MODAL';
export const ALL_CONVERSATIONS_LOADED = 'ALL_CONVERSATIONS_LOADED';
export const DISABLE_LOADING = 'DISABLE_LOADING';
export const RESET_CONVERSATION_PAGINATION_STATE = 'RESET_CONVERSATION_PAGINATION_STATE';
export const DUMMY_TODO_REMOVE = 'DUMMY_TODO_REMOVE';
export const MANAGE_PARTICIPANTS = 'MANAGE_PARTICIPANTS';
export const ADD_PARTICIPANT_TO_CURRENT_CONVERSATION = 'ADD_PARTICIPANT_TO_CURRENT_CONVERSATION';
export const ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS = 'ADD_PARTICIPANT_TO_CURRENT_CONVERSATION_SUCCESS';
export const SET_GROUP_INTRO_FLAG = 'SET_GROUP_INTRO_FLAG';
export const REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION = 'REMOVE_PARTICIPANT_FROM_CURRENT_CONVERSATION';
export const START_SINGLE_CONVERSATION_WITH_CONTACT_DATA = 'START_SINGLE_CONVERSATION_WITH_CONTACT_DATA';
export const START_CONVERSATION_WITH_EVENT_DATA = 'START_CONVERSATION_WITH_EVENT_DATA';
export const UPDATE_SUGGESTIONS = 'UPDATE_SUGGESTIONS';
export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS';

export const UPDATE_CONVERSATIONS = 'UPDATE_CONVERSATIONS';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';

export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const SET_NEW_GROUP_CONVERSATION_TITLE = 'SET_NEW_GROUP_CONVERSATION_TITLE';

export const STOP_COMPOSING_CONVERSATION = 'STOP_COMPOSING_CONVERSATION';
export const SET_CURRENT_CONVERSATION_LOADING = 'SET_CURRENT_CONVERSATION_LOADING';

/**
 * Search
 */
export const SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS';
export const SEARCH_CONVERSATIONS_SUCCESS = 'SEARCH_CONVERSATIONS_SUCCESS';
export const SEARCH_CONVERSATIONS_FAIL = 'SEARCH_CONVERSATIONS_FAIL';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL';
export const CLOSE_SEARCH_MODAL = 'CLOSE_SEARCH_MODAL';

/**
 * App login
 */
export const OPEN_DESKTOP_APP = 'OPEN_DESKTOP_APP';
export const OPEN_DESKTOP_APP_SUCCESS = 'OPEN_DESKTOP_APP_SUCCESS';
export const OPEN_DESKTOP_APP_FAILED = 'OPEN_DESKTOP_APP_FAILED';

/**
 * Sending Message
 */
export const PARSE_MESSAGE = 'PARSE_MESSAGE';
export const ADD_REACTION = 'ADD_REACTION';
export const ADD_REACTION_SUCCESS = 'ADD_REACTION_SUCCESS';
export const REMOVE_REACTION = 'REMOVE_REACTION';
export const REMOVE_REACTION_SUCCESS = 'REMOVE_REACTION_SUCCESS';
export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE';
export const REMOVE_REPLY_MESSAGE = 'REMOVE_REPLY_MESSAGE';

/**
 *  Error handling
 */
export const CRITICAL_ERROR = 'CRITICAL_ERROR';

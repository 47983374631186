import _ from 'underscore';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/withLatestFrom';

import * as actions from '../app.actions';
import * as methods from '../app.constants';
import * as db from './messenger.firebase';
import { customCombineEpics } from '../app.helpers';

const eventObservers = {
    length: 0,
    assign: function assign(eventsToObserve) {
        this.observers = _.flatten(eventsToObserve.map(id => [db.listenToEvent(id)]));

        this.length = this.observers.length;

        return this;
    },
    observe: function observe(action$) {
        return db
            .listenToEvents(this.observers)
            .takeUntil(action$.ofType(methods.LOGOUT_USER))
            .bufferTime(500)
            .filter(events => events.length);
    },
    observers: [],
};

const syncEvents = async (newEvents) => {
    const eventTransformers = Array.from(newEvents
        .reduce((map, updatedEvent) => {
            const { id } = updatedEvent;

            const nextEvent = {
                id,
                ...map.get(id),
                ...updatedEvent,
            };

            map.set(id, nextEvent);

            return map;
        }, new Map())
        .values()).map(db.transformEventInternal);

    return new Map(await Promise.all(eventTransformers));
};

export const createEventObservers = action$ =>
    action$.ofType(methods.PAGINATE_EVENTS).mergeMap(({ newState: { events } }) => {
        const allEvents = Object.keys(events);

        return eventObservers
            .assign(allEvents)
            .observe(action$)
            .switchMap(async (newEvents) => {
                const events = await syncEvents(newEvents);

                return actions.UpdateEventsAction({ events });
            });
    });

export const listenToUserEventUpdates = (action$, { getState }) =>
    action$
        .ofType(methods.LOGIN_USER_SUCCESS)
    // currently events only supported only on web
        .filter(() => process.env.WEB)
        .mergeMap(() =>
            db.listenToUserEvents(getState().auth.user.uid).map((events) => {
                if (!_.size(events)) {
                    return actions.UpdateEventsAction({ events: [] });
                }

                return actions.PaginateEventsAction({ events });
            }));

export const listenToUserEventsRemoved = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS).mergeMap(() =>
        db.listenToUserEventsRemoved(getState().auth.user.uid).map((event) => {
            if (event.id) {
                return actions.RemoveEventAction(event);
            }

            return actions.DummyAction();
        }));

export const newMessengerEventEpic = customCombineEpics(listenToUserEventUpdates, createEventObservers, listenToUserEventsRemoved);

import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const MagicMobileAsync = lazy(() => import('./magic-mobile').then(module => ({ default: module.MagicMobile })));

export const MagicMobile = () => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <MagicMobileAsync />
        </Suspense>
    );
};

import { mixpanel } from './app.cross-platform';

const disableMixpanel = true;

export const init = async () => {
    if (disableMixpanel) {
        return;
    }

    mixpanel.init(process.env.MIXPANEL_LOG_URI);
    mixpanel.track('Web app is Initialized');
};

export const assignUser = ({
    uid,
    credentials: {
        email,
        phone,
        name,
        profilePictureURL,
    },
}) => {
    if (disableMixpanel) {
        return;
    }

    mixpanel.identify(uid);

    const userProperties = {
        $email: email,
        $name: name,
        $phone: phone,
        uid,
        'Profile Picture': profilePictureURL,
    };

    mixpanel.register(userProperties);
    mixpanel.people.set(userProperties);

    mixpanel.track('session_started', {
        platform: 'web',
    });
};

export const buttonClicked = (title, fn, description) => {
    if (disableMixpanel) {
        return;
    }

    mixpanel.track('button_clicked', {
        title,
        function: fn,
        description,
    });
};

export const messageSent = (type, recipientId, conversationMembers) => {
    if (disableMixpanel) {
        return;
    }
    // TODO:FIX
    mixpanel.track('message_sent', {
        type,
        recipient: recipientId,
        members: conversationMembers,
    });
};


export const event = type => mixpanel.track(type);

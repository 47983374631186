import _ from 'underscore';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/withLatestFrom';
import { Observable } from 'rxjs';

import * as actions from '../app.actions';
import * as methods from '../app.constants';
import * as db from './messenger.firebase';
import * as services from './messenger.services';
import { transformMessages, createNotificationForNewMessages } from './messenger.helpers';
import { customCombineEpics } from '../app.helpers';

/**
 * Observers
 */
const conversationObservers = {
    length: 0,
    /**
     * Maps conversation IDs to conversation listeners
     * @param conversationsToObserve
     * @param uid is the logged in user
     * @returns {conversationObservers}
     */
    assign: function assign(conversationsToObserve, uid) {
        conversationsToObserve.forEach((conversation) => {
            this.observers.set(conversation.location, db.listenToConversation(conversation, uid));
        });

        this.length = this.observers.size;

        return this;
    },
    unassign: function unassign(conversationId) {
        this.observers.delete(conversationId);
        this.length = this.observers.size;
    },
    /**
     * Listens to the observers defined in assign()
     * @param action$
     */
    observe: function observe(action$) {
        const observers = Array.from(this.observers.values());
        return db
            .listenToConversations(observers)
            .takeUntil(action$.ofType(methods.LOGOUT_USER))
            .bufferTime(500)
            .filter(conversations => conversations.length);
    },
    observers: new Map(),
};

/**
 * Converts conversation data into populated conversation objects
 * @param newConversations
 * @param existingConversations
 * @returns {Observable<any>}
 */
const syncConversations = (newConversations, existingConversations) =>
    Observable.defer(async () => {
        const conversationTransformers = Array.from(newConversations
            .reduce((map, updatedConversation) => {
                const { id } = updatedConversation;
                const existingConversation = existingConversations.get(id) || {};
                let newMessages = transformMessages(updatedConversation.messages || {});
                let existingMessages = existingConversation.messages || [];
                newMessages = Array.isArray(newMessages) ? newMessages : [];
                existingMessages = Array.isArray(existingMessages) ? existingMessages : [];
                const messages = _.uniq([...newMessages, ...existingMessages], false, ({ id }) => id).sort(({ timestamp: a = Math.min() }, { timestamp: b = Math.min() }) => a - b);

                const nextConversation = {
                    ...existingConversation,
                    ...map.get(id),
                    ...updatedConversation,
                    messages,
                };

                map.set(id, nextConversation);

                return map;
            }, new Map())
            .values()).map(db.transformConversationInternal);

        return new Map(await Promise.all(conversationTransformers));
    });

export const listenToUserConversationUpdates = (action$, { getState }) =>
    action$.ofType(methods.LOGIN_USER_SUCCESS).mergeMap(() =>
        db
            .listenToUserConversations(getState().auth.user.uid)
            // this next line causes a bug with new groups
            // because they actually get added to conversations
            // but it was meant for performance
            // .filter(conversations => !getState().messenger.conversations.has(Object.keys(conversations)[0]))
            .map(conversations => actions.PaginateConversationsAction({ conversations, existingConversationsSize: 0 })));

export const listenToUserConversationRemove = (action$, { getState }) => action$.ofType(methods.LOGIN_USER_SUCCESS).mergeMap(() => db.listenToUserConversationsRemove(getState().auth.user.uid).map(conversations => actions.DeleteConverationAction(conversations)));

/**
 * Called either when the user object updates or when user invokes conversation pagination
 * Should accomplish the following:
 *  - Create observers to listen onto conversations
 *  - When theres a change in either the conversation or observer
 *
 * @param conversations - The list of conversations based on the user
 * @param? conversationLimit (Default: 12) - New limit to how many conversations should be listened to
 * @returns {Observable<{type}|*>}
 */
export const createConversationObservers = (action$, { getState }) =>
    action$
        .ofType(methods.LOGIN_USER_SUCCESS, methods.SYNC_USER_AND_CONVERSATIONS, methods.PAGINATE_CONVERSATIONS, methods.LOAD_FROM_CACHE)
        .filter(({ fromCache }) => !fromCache)
        .switchMap(({ type, newState: { conversations: ac, limit = 12, existingConversations = getState().messenger.conversations } = {} }) => {
            const currentStateConversations = getState().auth.user.conversations || {};

            // This is really more like new conversations
            const allConversations = ac || currentStateConversations;

            const allConversationsLength = Object.keys(currentStateConversations).length;

            // check if no conversations
            // TODO: Shouldn't call StartNewUserAction if there are contacts
            if (type === methods.LOGIN_USER_SUCCESS && _.isEmpty(allConversations)) {
                return Observable.of(actions.StartNewUserAction());
            }

            // sort all conversations
            let conversationsToObserve = Object.keys(allConversations)
                .map((key, index) => {
                    const conv = allConversations[key];
                    if (conv && !conv.location && !conv.group) {
                        conv.location = key;
                    }
                    return conv;
                })
                .filter(({ location }) => !!location);
            if (Object.keys(allConversations).length >= 2) {
                conversationsToObserve = conversationsToObserve.sort(({ lastUpdatedAt: a = 0 }, { lastUpdatedAt: b = 0 }) => b - a);
                if (type === methods.PAGINATE_CONVERSATIONS) {
                    conversationsToObserve = conversationsToObserve.slice(existingConversations.size, existingConversations.size + limit);
                } else if (type === methods.LOAD_FROM_CACHE) {
                    conversationsToObserve = conversationsToObserve.slice(0, Math.min(existingConversations.size, 12));
                } else {
                    conversationsToObserve = conversationsToObserve.slice(existingConversations.size, existingConversations.size + limit);
                }
            }

            return conversationObservers
                .assign(conversationsToObserve, getState().auth.user.uid)
                .observe(action$)
                .mergeMap((newConversations) => {
                    const existingConversations = getState().messenger.conversations;
                    return syncConversations(newConversations, existingConversations).map((conversations) => {
                        const { uid } = getState().auth.user;
                        if (process.env.WEB && !process.env.ELECTRON && !document.hasFocus()) {
                            const latestUnreadMessages = {};

                            // eslint-disable-next-line
                            Array.from(conversations.values()).forEach(({ participants, messages }) => {
                                const lastMessage = messages.length ? messages[messages.length - 1] : null;
                                if (participants && lastMessage && lastMessage.read && !lastMessage.read[uid] && lastMessage.fromId !== uid) {
                                    latestUnreadMessages[lastMessage.id] = {
                                        ...lastMessage,
                                        from: participants.find(({ id }) => id === lastMessage.fromID),
                                    };
                                }
                            });

                            createNotificationForNewMessages(latestUnreadMessages);
                        }
                        return actions.UpdateConversationsAction({
                            allConversationsLength,
                            conversations,
                        });
                    });
                });
        });

export const leaveConversation = (action$, { getState }) =>
    action$.ofType(methods.LEAVE_CONVERSATION).switchMap(({ newState: { id: conversationId } }) => {
        const {
            user: { uid },
        } = getState().auth;

        return Observable.defer(() => services.leaveConversation(uid, conversationId)).map(() => actions.LeaveConversationSuccessAction(conversationId));
    });

export const deleteConversations = (action$, { getState }) =>
    action$
        .ofType(methods.DELETE_CONVERSATION)
        .do(({ newState: { id: conversationId } }) => {
            conversationObservers.unassign(conversationId);
            db.unsubscribeFromMessageId(conversationId);
        })
        .filter(() => false);

export const newMessengerEpic = customCombineEpics(createConversationObservers, listenToUserConversationUpdates, listenToUserConversationRemove, leaveConversation, deleteConversations);

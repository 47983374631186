import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/withLatestFrom';
import * as actions from '../app.actions';
import * as methods from '../app.constants';
import { router } from '../app.cross-platform';
import { customCombineEpics, platform } from '../app.helpers';
import { DummyAction } from '../messenger/messenger.actions';

export const onInitAndroidShare = (action$, { getState }) =>
    action$.ofType(methods.INIT_SHARED_DATA_ANDROID)
        .switchMap(() => action$.ofType(methods.LOGIN_USER_SUCCESS, methods.LOAD_FROM_CACHE)
            .map(() => actions.newSharedDataAndroid(getState().app.sharedDataAndroid))
            .race(action$
                .ofType(methods.CLEAR_LOADING_INITIAL_AUTH_STATE, methods.LOGOUT_USER)
                .map(() => actions.clearSharedDataAndroid()))
            .first());

export const onNewAndroidShare = (action$, { getState }) =>
    action$.ofType(methods.NEW_SHARED_DATA_ANDROID)
        .filter(() => {
            return getState().auth.user && getState().app.sharedDataAndroid.data;
        })
        .map(() => {
            return DummyAction();
        });

export const updateRouterLocation = action$ =>
    action$.ofType(methods.UPDATE_ROUTER_LOCATION)
        .debounceTime(300)
        .do(({ newState }) => {
            if (typeof newState === 'string') {
                return router.next(newState);
            }

            const defaultRoutes = {
                web: '/',
                desktop: newState.web || '/login',
                native: 'Home',
            };

            const platformType = platform.type;

            const nextRoute = newState[platformType] || defaultRoutes[platformType];

            // handle array format [routName, params], only valid for native platform
            if (Array.isArray(nextRoute) && platformType === 'native') {
                return router.next(...nextRoute);
            }

            return router.next(nextRoute);
        })
        .map(() => actions.UpdateRouterLocationSuccessAction());

export const routerEpic = customCombineEpics(
    updateRouterLocation,
    onInitAndroidShare,
    onNewAndroidShare,
);

import axios from 'axios';
import { generateAuthTokens } from '../app.helpers';
import { serverInfo } from '../app.constants';

export const sendVerificationText = async (phone, isOnboarding = false) => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/sendVerificationCodeToPhone`,
    data: {
        phone,
        ...isOnboarding ? {} : await generateAuthTokens(),
    },
});

export const sendVerificationTextV2 = async identifier => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/sendVerificationCodeToPhone/v2`,
    data: { identifier },
});

export const verifyVerificationCode = async code => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/verifyVerificationCode`,
    data: {
        code,
        ...await generateAuthTokens(),
    },
});

export const userIdentifiers = async () => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/userIdentifiers`,
    data: {
        ...await generateAuthTokens(),
    },
});

export const addPasswordToAccount = async (password, forgotPasswordCode = null) => axios({
    method: 'post',
    url: `${await serverInfo.getRoot()}/addPassword`,
    data: {
        password,
        forgotPasswordCode,
        ...await generateAuthTokens(),
    },
});

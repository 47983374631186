import { Observable } from 'rxjs';
import { oneForOne, superviseEpics } from '@mixer/epic-supervisor';
import { messengerEpic } from './messenger/messenger.epic';
import { routerEpic } from './router/router.epic';
import { homeEpic } from './auth/auth.epic';
import { modalEpic } from './modals/modal.epic';

import { CRITICAL_ERROR } from './messenger/messenger.constants';

const options = {
    restart: oneForOne,
    onError: ({ epicName, error }, actions, state, services) => {
        // Send another action when the error occurs:
        console.error(`An error occurred in epic ${epicName}`, error);
        state.dispatch({ type: CRITICAL_ERROR, newState: error });
        // Wait a second before restarting epics:
        return Observable.of('RESTARTED');
    },
};

const epic = superviseEpics(
    options,
    /**
     * Imported epics
     */
    messengerEpic,
    routerEpic,
    modalEpic,
    homeEpic,
);

export default epic;

import React, { Component } from 'react';
import * as styles from './drag-header.scss';
import { electron } from '../../app.cross-platform';

// eslint-disable-next-line

export class DragHeader extends Component {
    componentDidMount() {
        // eslint-disable-next-line
        this.window = electron().remote.getCurrentWindow();
    }

    render() {
        return (
            <div className={styles.drag_header}>
                <div className={styles.buttons}>
                    <div
                        onClick={() => this.window.close()}
                        role="button"
                        onKeyDown={() => {}}
                        className={styles.button}
                        style={{ backgroundColor: 'rgb(253,94,88)' }}
                    />
                    <div
                        onClick={() => this.window.minimize()}
                        role="button"
                        onKeyDown={() => {}}
                        className={styles.button}
                        style={{ backgroundColor: 'rgb(255,189,60)' }}
                    />
                    <div
                        onClick={() => this.window.maximize()}
                        role="button"
                        onKeyDown={() => {}}
                        className={styles.button}
                        style={{ backgroundColor: 'rgb(54,205,74)' }}
                    />
                </div>
            </div>
        );
    }
}

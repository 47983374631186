import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const HomeContainerAsync = lazy(() => import('./home-container').then(module => ({ default: module.HomeContainer })));

export const HomeContainer = (props) => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <HomeContainerAsync {...props} />
        </Suspense>
    );
};


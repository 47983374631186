import React, { lazy, Suspense } from 'react';

import { FullScreenLoadingOverlay } from '../../shared/full-screen-loading-overlay/full-screen-loading-overlay';

const TermsAsync = lazy(() => import('./terms-of-service').then(module => ({ default: module.Terms })));

export const Terms = () => {
    return (
        <Suspense
            fallback={<FullScreenLoadingOverlay text="Loading Sonic" />}
        >
            <TermsAsync />
        </Suspense>
    );
};


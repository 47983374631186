exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-home-content-blob-content-blob__container--1Uwvf{display:flex;flex:0 0 auto;justify-content:space-between;margin:40px 0}.src-home-content-blob-content-blob__nav--2EK-f{display:flex;align-items:center}.src-home-content-blob-content-blob__nav--2EK-f a{margin:0 10px;text-decoration:none;font-weight:500}.src-home-content-blob-content-blob__blob_icon--1nFsH{background:#FFFFFF;box-shadow:1px 2px 2px 2px rgba(0,0,0,0.1);border-radius:100px;height:50px;width:50px;font-size:18px;display:flex;align-items:center;text-align:center;padding:15px}.src-home-content-blob-content-blob__blob_title--2q2G5{font-size:18px;font-weight:500;margin:20px 0 10px}.src-home-content-blob-content-blob__blob_content--39bZW{margin:10px}h2{font-size:24px}article{width:50%;min-width:400px;padding-right:50px;margin:50px 0 0 0}p{font-size:16px;line-height:1.75}\n", ""]);

// exports
exports.locals = {
	"container": "src-home-content-blob-content-blob__container--1Uwvf",
	"nav": "src-home-content-blob-content-blob__nav--2EK-f",
	"blob_icon": "src-home-content-blob-content-blob__blob_icon--1nFsH",
	"blob_title": "src-home-content-blob-content-blob__blob_title--2q2G5",
	"blob_content": "src-home-content-blob-content-blob__blob_content--39bZW"
};
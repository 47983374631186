import * as methods from '../app.constants';

export const LoginUserAction = newState => ({ type: methods.LOGIN_USER, newState });

export const LoginUserActionV2 = newState => ({ type: methods.LOGIN_USER_V2, newState });

export const ImportGoogleContactsAction = () => ({ type: methods.IMPORT_CONTACTS });

export const LoginUserSuccessAction = newState => ({ type: methods.LOGIN_USER_SUCCESS, newState });

export const LoginUserFailedAction = newState => ({ type: methods.LOGIN_USER_FAILED, newState });

export const LogoutUserAction = () => ({ type: methods.LOGOUT_USER });

export const SaveProfileAction = newState => ({ type: methods.SAVE_PROFILE, newState });

export const SaveProfileSuccessAction = newState => ({ type: methods.SAVE_PROFILE_SUCCESS, newState });

export const SaveSettingsAction = newState => ({ type: methods.SAVE_SETTINGS, newState });

export const SaveSettingsSuccessAction = newState => ({ type: methods.SAVE_SETTINGS_SUCCESS, newState });

export const LogoutUserSuccessAction = () => ({ type: methods.LOGOUT_USER_SUCCESS });

export const MergeAccountsAction = () => ({ type: methods.MERGE_ACCOUNTS });

export const MergeAccountsSuccessAction = () => ({ type: methods.MERGE_ACCOUNTS_SUCCESS });

export const MergeAccountsFailedAction = () => ({ type: methods.MERGE_ACCOUNTS_FAILED });

export const UpdateProfilePictureAction = newState => ({ type: methods.UPDATE_PROFILE_PICTURE, newState });

export const SaveProfilePictureAction = newState => ({ type: methods.SAVE_PROFILE_PICTURE, newState });
export const ProfilePictureAction = () => ({ type: methods.PROFILE_PICTURE_ACTION });
export const ProfilePictureFailureAction = () => ({ type: methods.SAVE_PROFILE_PICTURE_FAILURE });
export const SaveProfilePictureSuccessAction = newState => ({ type: methods.SAVE_PROFILE_PICTURE_SUCCESS, newState });

export const UpdateDisplayNameAction = newState => ({ type: methods.UPDATE_DISPLAY_NAME, newState });

export const UpdateHeadlineAction = newState => ({ type: methods.UPDATE_HEADLINE, newState });

export const UpdateLinkedInURLAction = newState => ({ type: methods.UPDATE_LINKEDIN_URL, newState });

export const UpdateHeadlineSuccessAction = newState => ({ type: methods.UPDATE_HEADLINE_SUCCESS, newState });

export const UpdateLinkedInURLSuccessAction = newState => ({ type: methods.UPDATE_LINKEDIN_URL_SUCCESS, newState });

export const UpdateDisplayNameSuccessAction = newState => ({ type: methods.UPDATE_DISPLAY_NAME_SUCCESS, newState });

export const UpdateUserDataAction = () => ({ type: methods.UPDATE_USER_DATA });

export const UpdateUserDataSuccessAction = newState => ({ type: methods.UPDATE_USER_DATA_SUCCESS, newState });

export const SetPreMergeDataAction = newState => ({ type: methods.SET_PRE_MERGE_DATA, newState });

export const ClearPreMergeDataAction = () => ({ type: methods.CLEAR_PRE_MERGE_DATA });

export const SendLoginLinkEmailAction = newState => ({ type: methods.SEND_LOGIN_LINK_EMAIL, newState });

export const SendLoginLinkEmailActionV2 = newState => ({ type: methods.SEND_LOGIN_LINK_EMAIL_V2, newState });

export const SendLoginLinkEmailFailAction = newState => ({ type: methods.SEND_LOGIN_LINK_EMAIL_FAIL, newState });

export const SendLoginLinkEmailSuccessAction = newState => ({ type: methods.SEND_LOGIN_LINK_EMAIL_SUCCESS, newState });

export const SendLoginLinkTextAction = newState => ({ type: methods.SEND_LOGIN_LINK_TEXT, newState });

export const SendLoginLinkTextActionV2 = newState => ({ type: methods.SEND_LOGIN_LINK_TEXT_V2, newState });

export const SendLoginLinkTextFailAction = newState => ({ type: methods.SEND_LOGIN_LINK_TEXT_FAIL, newState });

export const SendLoginLinkTextSuccessAction = newState => ({ type: methods.SEND_LOGIN_LINK_TEXT_SUCCESS, newState });

export const EditUserProfileAction = () => ({ type: methods.EDIT_USER_PROFILE });

export const EditUserProfileSuccessAction = () => ({ type: methods.EDIT_USER_PROFILE_SUCCESS });

export const ValidateLoggedInOnFirebaseAction = newState => ({ type: methods.VALIDATE_LOGGED_IN_ON_FIREBASE, newState });

export const ClearUserProfileFromLocalStorageSuccessAction = () => ({ type: methods.CLEAR_USER_PROFILE_FROM_LOCALSTORAGE_SUCCESS });

export const ClearLoadingInitialAuthStateAction = () => ({ type: methods.CLEAR_LOADING_INITIAL_AUTH_STATE });

export const UpdateMetadataAttributeValueAction = newState => ({ type: methods.UPDATE_METADATA_ATTRIBUTE_VALUE, newState });

export const ClearLoginLinkErrorAction = () => ({ type: methods.CLEAR_LOGIN_LINK_ERROR });

export const ClearEmailSentStatusAction = () => ({ type: methods.CLEAR_EMAIL_SENT_STATUS });

export const ClearTextSentStatusAction = () => ({ type: methods.CLEAR_TEXT_SENT_STATUS });

export const LoadFromCacheAction = newState => ({ type: methods.LOAD_FROM_CACHE, newState });

export const SyncUserAndConversationsAction = newState => ({ type: methods.SYNC_USER_AND_CONVERSATIONS, newState });

export const ResetOnboardingModalState = () => ({ type: methods.RESET_ONBOARDING_MODAL_STATE });

export const ChangePasswordAction = newState => ({ type: methods.CHANGE_PASSWORD, newState });

export const ChangePasswordSuccessAction = () => ({ type: methods.CHANGE_PASSWORD_SUCCESS });

export const ChangePasswordFailedAction = newState => ({ type: methods.CHANGE_PASSWORD_FAILED, newState });

export const ResetChangePasswordStateAction = () => ({ type: methods.RESET_CHANGE_PASSWORD_STATE });

export const SetNativeMagicUrl = newState => ({ type: methods.SET_NATIVE_MAGIC_URL, newState });

export const RemoveNativeMagicURL = () => ({ type: methods.REMOVE_NATIVE_MAGIC_URL });

import _ from 'underscore';

import * as methods from '../app.constants';
import { flushCache, updateAuthCache } from './auth.cache';

const { assign } = Object;

export const initialState = {
    user: null,
    isLoadingInitialAuthState: false,
    tempUser: {},
    emailSentStatus: null,
    loginLinkError: null,
    preMergeData: null,
    mergeFailed: false,
    isMergingSuccessful: false,
    isMergingAccounts: false,
    isSavingProfile: false,
    isProfileEdited: false,
    isSavingSettings: false,
    isSendingEmail: false,
    isLoggingIn: false,
    isSendingText: false,
    isEmailSent: false,
    isTextSent: false,
    magicCodeSent: false,
    magicCodeMatches: false,
    isLoggedIn: false,
    isPasswordChanging: false,
    isPasswordChanged: false,
    changePasswordFailedResponse: '',
    isProfilePictureUploading: false,
};

export function authReducer(state = initialState, action) {
    switch (action.type) {
    case methods.LOAD_FROM_CACHE: {
        if (state.isLoggedIn) {
            return state;
        }

        const { user } = action.newState.auth;

        return {
            ...state,
            user,
            isLoggedIn: true,
            loginLinkError: null,
            isLoggingIn: false,
            isLoadingInitialAuthState: false,
        };
    }

    case methods.SYNC_USER_AND_CONVERSATIONS: {
        updateAuthCache({ user: action.newState });
        return {
            ...state,
            user: action.newState,
        };
    }

    case methods.VALIDATE_LOGGED_IN_ON_FIREBASE:
        return {
            ...state,
            user: action.newState,
        };

    case methods.LOGIN_USER:
    case methods.LOGIN_USER_V2:
        return {
            ...state,
            isLoggedIn: false,
            isLoggingIn: true,
        };

    case methods.LOGIN_USER_SUCCESS: {
        const { isOnboarding: _, ...user } = action.newState;
        updateAuthCache({ user });

        return {
            ...state,
            user,
            isLoggedIn: true,
            loginLinkError: null,
            isLoggingIn: false,
            isLoadingInitialAuthState: false,
        };
    }

    case methods.LOGIN_USER_FAILED:
        return assign({}, state, {
            isLoggingIn: false,
            isLoggedIn: false,
            loginLinkError: action.newState.errorMessage,
            isLoadingInitialAuthState: false,
            errorCode: action.newState.errorCode,
            errorEmail: action.newState.errorEmail,
        });

    case methods.CLEAR_LOGIN_LINK_ERROR:
        return {
            ...state,
            loginLinkError: null,
        };

    case methods.CLEAR_LOADING_INITIAL_AUTH_STATE: {
        return {
            ...state,
            isLoadingInitialAuthState: false,
        };
    }

    case methods.UPDATE_USER_DATA_SUCCESS: {
        return {
            ...state,
            user: action.newState,
        };
    }

    case methods.LOGOUT_USER:
        flushCache();

        return {
            ...state,
            user: null,
            preMergeState: null,
            isLoggedIn: false,
            isLoadingInitialAuthState: false,
        };

    case methods.UPDATE_METADATA_ATTRIBUTE_VALUE: {
        const { name, value } = action.newState;

        const newState = { ...state };
        newState.user.meta.web[name] = value;
        return newState;
    }

    case methods.ADD_PASSWORD_TO_USER_ACCOUNT_SUCCESS: {
        const newState = { ...state };
        newState.user.meta.web.hasPassword = true;
        return newState;
    }

    case methods.SEND_LOGIN_LINK_EMAIL_V2:
    case methods.SEND_LOGIN_LINK_EMAIL:
        return assign({}, state, {
            isSendingEmail: true,
            loginLinkError: null,
            emailSentStatus: null,
        });

    case methods.CLEAR_EMAIL_SENT_STATUS:
        return {
            ...state,
            isSendingEmail: false,
            isEmailSent: false,
            emailSentStatus: null,
        };

    case methods.CLEAR_TEXT_SENT_STATUS:
        return {
            ...state,
            isSendingText: false,
            isTextSent: false,
        };

    case methods.SEND_LOGIN_LINK_EMAIL_SUCCESS:
        return assign({}, state, {
            isSendingEmail: false,
            isEmailSent: true,
            emailSentStatus: action.newState,
        });
    case methods.MAGIC_LINK_CODE_SEND_SUCCESSFUL:
        return assign({}, state, {
            magicCodeSent: true,
        });
    case methods.MAGIC_LINK_CODE_SEND_UNSUCCESSFUL:
        return assign({}, state, {
            magicCodeSent: false,
        });
    case methods.MAGIC_CODE_LINK_MATCH_SUCCESSFUL:
        return assign({}, state, {
            magicCodeSent: true,
            magicCodeMatches: true,
        });
    case methods.MAGIC_CODE_LINK_MATCH_UNSUCCESSFUL:
        return assign({}, state, {
            magicCodeSent: true,
            magicCodeMatches: false,
        });
    case methods.SEND_LOGIN_LINK_EMAIL_FAIL:
        return assign({}, state, {
            isSendingEmail: false,
            isEmailSent: false,
            loginLinkError: action.newState,
            emailSentStatus: { status: 'failed' },
        });

    case methods.SEND_LOGIN_LINK_TEXT_V2:
    case methods.SEND_LOGIN_LINK_TEXT:
        return assign({}, state, {
            isSendingText: true,
        });

    case methods.SEND_LOGIN_LINK_TEXT_SUCCESS:
        return assign({}, state, {
            isSendingText: false,
            isTextSent: true,
        });

    case methods.SEND_LOGIN_LINK_TEXT_FAIL:
        return assign({}, state, {
            isSendingText: false,
            isTextSent: false,
            loginLinkError: action.newState,
        });

    // case methods.SET_USER_IS_NEW:
    //     return assign({}, state, {
    //         user: {
    //             conversations: new Map([]),
    //         },
    //     });

    case methods.SET_PRE_MERGE_DATA:
        return assign({}, state, {
            preMergeData: action.newState,
            isMergingSuccessful: false,
            mergeFailed: false,
        });

    case methods.CLEAR_PRE_MERGE_DATA:
        return assign({}, state, {
            preMergeData: null,
            isMergingSuccessful: false,
            mergeFailed: false,
        });

    case methods.MERGE_ACCOUNTS:
        return assign({}, state, {
            isMergingAccounts: true,
        });

    case methods.MERGE_ACCOUNTS_FAILED:
        return assign({}, state, {
            mergeFailed: true,
            isMergingAccounts: false,
        });

    case methods.MERGE_ACCOUNTS_SUCCESS:
        return assign({}, state, {
            isMergingAccounts: false,
            isMergingSuccessful: true,
            preventReload: state.preMergeData.preventReload || false,
            preMergeData: {},
        });

    case methods.LEAVE_CONVERSATION_SUCCESS: {
        const newState = assign({}, state, {
            user: {
                ...state.user,
                conversations: _.omit(state.user.conversations, action.newState),
            },
        });

        updateAuthCache({ user: newState.user });

        return newState;
    }

    case methods.UPDATE_PROFILE_PICTURE:
    case methods.SAVE_PROFILE_PICTURE:
        return assign({}, state, {
            tempUser: {
                ...state.tempUser,
                profileImageURL: action.newState,
            },
            isProfileEdited: action.newState !== state.user.credentials.profileImageURL,
        });

    case methods.SAVE_PROFILE_PICTURE_SUCCESS:
        return assign({}, state, {
            isProfilePictureUploading: false,
            user: {
                ...state.user,
                credentials: {
                    ...state.user.credentials,
                    profileImageURL: action.newState,
                },
            },
        });
    case methods.SAVE_PROFILE_PICTURE_FAILURE:
        return {
            ...state,
            isProfilePictureUploading: false,
        };
    case methods.PROFILE_PICTURE_ACTION:
        return {
            ...state,
            isProfilePictureUploading: true,
        };

    case methods.UPDATE_DISPLAY_NAME:
        return assign({}, state, {
            tempUser: {
                ...state.tempUser,
                name: action.newState,
            },
            isProfileEdited: action.newState !== state.user.credentials.name,
        });


    case methods.UPDATE_LINKEDIN_URL:
        return assign({}, state, {
            tempUser: {
                ...state.tempUser,
                linkedin: action.newState,
            },
            isProfileEdited: action.newState !== state.user.credentials.linkedin,
        });


    case methods.UPDATE_HEADLINE:
        return assign({}, state, {
            tempUser: {
                ...state.tempUser,
                headline: action.newState,
            },
            isProfileEdited: action.newState !== state.user.credentials.headline,
        });

    case methods.SAVE_PROFILE:
        return assign({}, state, {
            isSavingProfile: true,
        });

    case methods.SAVE_PROFILE_SUCCESS:
        return {
            ...state,
            isSavingProfile: false,
            isProfileEdited: false,
            user: action.newState.nextUser,
            tempUser: action.newState.nextUser.credentials,
        };

    case methods.SAVE_SETTINGS:
        return assign({}, state, {
            isSavingSettings: true,
        });

    case methods.SAVE_SETTINGS_SUCCESS:
        return {
            ...state,
            isSavingSettings: false,
            user: {
                ...state.user,
                settings: action.newState.nextSettings,
            },
        };

    case methods.DISABLE_RIGHT_SIDEBAR:
        return assign({}, state, {
            isSavingProfile: false,
            isProfileEdited: false,
        });

    case methods.EDIT_USER_PROFILE:
        return assign({}, state, {
            tempUser: state.user.credentials,
        });

    case methods.RESET_CHANGE_PASSWORD_STATE:
        return assign({}, state, {
            isPasswordChanging: false,
            isPasswordChanged: false,
            changePasswordFailedResponse: '',
        });

    case methods.CHANGE_PASSWORD:
        return assign({}, state, {
            isPasswordChanging: true,
            isPasswordChanged: false,
            changePasswordFailedResponse: '',
        });

    case methods.CHANGE_PASSWORD_FAILED:
        return assign({}, state, {
            isPasswordChanging: false,
            isPasswordChanged: false,
            changePasswordFailedResponse: action.newState,
        });

    case methods.CHANGE_PASSWORD_SUCCESS:
        return assign({}, state, {
            isPasswordChanging: false,
            isPasswordChanged: true,
            changePasswordFailedResponse: '',
        });

    case methods.ENABLE_RIGHT_SIDEBAR:
        if (action.style === 'profile') {
            return {
                ...state,
                tempUser: state.user.credentials,
            };
        }

        return assign({}, state, {
            isSavingProfile: false,
            isProfileEdited: false,
        });

    case methods.PAGINATE_CONVERSATIONS: {
        if (
            !state.user
            || !action.newState
            || !action.newState.conversations
        ) {
            return state;
        }

        return {
            ...state,
            user: {
                ...state.user,
                conversations: {
                    ...(state.user.conversations || {}),
                    ...action.newState.conversations,
                },
            },
        };
    }

    case methods.SET_NATIVE_MAGIC_URL: {
        return {
            ...state,
            nativeMagicUrl: action.newState.url,
        };
    }

    case methods.REMOVE_NATIVE_MAGIC_URL: {
        return {
            ...state,
            nativeMagicUrl: null,
        };
    }

    default:
        return state;
    }
}

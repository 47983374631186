import { getCurrentURLQueryString, getConversationIdFromUrl } from '../app.helpers';
import { Sentry } from '../app.cross-platform';

export const setDefaultURL = () => {
    const { cid = 'default', 'old-path': oldPath } = getCurrentURLQueryString();
    const idParam = getConversationIdFromUrl();
    return `/u/${oldPath || idParam || cid}`;
};

export const transformContactsFromAndroid = (contacts) => {
    const transformedContacts = [];
    contacts.forEach((contact) => {
        const { displayName, emailAddresses, phoneNumbers } = contact;

        phoneNumbers.forEach(({ number }) => {
            const transformedContact = {
                name: displayName,
                source: 'android-contacts',
                phone: number,
            };
            transformedContacts.push(transformedContact);
        });

        emailAddresses.forEach(({ email }) => {
            const transformedContact = {
                name: displayName,
                source: 'android-contacts',
                email,
            };
            transformedContacts.push(transformedContact);
        });
    });
    return transformedContacts;
};

export const fetchAndroidContacts = () => {
    return new Promise((resolve, reject) => {
        if (process.env.WEB) {
            reject(new Error('Cannot fetch android contacts on web env'));
            return;
        }

        import('react-native').then((ReactNative) => {
            const { PermissionsAndroid } = ReactNative;
            return PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.READ_CONTACTS);
        })
            .then((granted) => {
                if (granted) {
                    return import('react-native-contacts');
                }
                return reject(new Error('CN1'));
            })
            .then((Contacts) => {
                if (!Contacts) return reject(new Error('CN2'));
                return Contacts.getAll((err, contacts) => {
                    if (err) {
                        return reject(err);
                    }
                    const transformedContacts = transformContactsFromAndroid(contacts);
                    return resolve(transformedContacts);
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                reject(err);
            });
    });
};
